/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type StripeConnectedAccountLinkCardPaymentsCapabilityStatus =
  (typeof StripeConnectedAccountLinkCardPaymentsCapabilityStatus)[keyof typeof StripeConnectedAccountLinkCardPaymentsCapabilityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeConnectedAccountLinkCardPaymentsCapabilityStatus = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
} as const
