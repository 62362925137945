import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Paperclip({
  width,
  height = 18,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 16"
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V9C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9V5C12 4.73478 12.1054 4.48043 12.2929 4.29289C12.4804 4.10536 12.7348 4 13 4C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V9C14 9.91925 13.8189 10.8295 13.4672 11.6788C13.1154 12.5281 12.5998 13.2997 11.9497 13.9497C11.2997 14.5998 10.5281 15.1154 9.67878 15.4672C8.8295 15.8189 7.91925 16 7 16C6.08075 16 5.1705 15.8189 4.32122 15.4672C3.47194 15.1154 2.70026 14.5998 2.05025 13.9497C1.40024 13.2997 0.884626 12.5281 0.532843 11.6788C0.18106 10.8295 -1.36979e-08 9.91925 0 9V5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0C6.32608 0 7.59785 0.526784 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4C5.26522 4 5.51957 4.10536 5.70711 4.29289C5.89464 4.48043 6 4.73478 6 5V9C6 9.26522 6.10536 9.51957 6.29289 9.70711C6.48043 9.89464 6.73478 10 7 10C7.26522 10 7.51957 9.89464 7.70711 9.70711C7.89464 9.51957 8 9.26522 8 9V5C8 4.20435 7.68393 3.44129 7.12132 2.87868C6.55871 2.31607 5.79565 2 5 2Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
