/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateStatus = (typeof EstimateStatus)[keyof typeof EstimateStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateStatus = {
  draft: 'draft',
  sent: 'sent',
  approved: 'approved',
  rejected: 'rejected',
  void: 'void',
} as const
