import {
  useCallback,
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useMemo,
} from 'react'
import Script from 'next/script'
import { ENVIRONMENT } from 'env'

declare global {
  interface Window {
    hsConversationsSettings: any
    HubSpotConversations: any
    hsConversationsOnReady: any
  }
}

type TSupportContext = {
  chatIsOpen: boolean
  setChatOpen: (open: boolean) => void
  chatUnreadMessagesCount: number | undefined
}
const SupportContext = createContext<TSupportContext | undefined>(undefined)

export const useSupportContext = () => {
  const context = useContext(SupportContext)
  if (context === undefined)
    throw new Error('SupportContext must be used within an SupportProvider')
  return context
}

type Props = { children: ReactNode }

export default function SupportProvider({ children }: Props) {
  const [chatIsOpen, setChatIsOpen] = useState(false)
  const [chatUnreadMessagesCount, setChatUnreadMessagesCount] = useState<number>()

  const setChatOpen = useCallback((open: boolean) => {
    if (open) {
      window.HubSpotConversations?.widget?.open()
      setChatIsOpen(true)
    } else {
      window.HubSpotConversations?.widget?.close()
      setChatIsOpen(false)
    }
  }, [])

  useEffect(() => {
    function onConversationsAPIReady() {
      window.HubSpotConversations.on('unreadConversationCountChanged', (p: any) => {
        setChatUnreadMessagesCount(p.unreadCount)
      })
      window.HubSpotConversations.on('widgetClosed', () => {
        setChatIsOpen(false)
      })
    }
    if (window.HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }
  }, [])

  const ctxValue = useMemo(
    () => ({
      chatIsOpen,
      setChatOpen,
      chatUnreadMessagesCount,
    }),
    [chatIsOpen, chatUnreadMessagesCount, setChatOpen],
  )

  return (
    <>
      <SupportContext.Provider value={ctxValue}>{children}</SupportContext.Provider>
      {ENVIRONMENT !== 'test' && (
        <Script async defer id="hs-script-loader" src="//js-na1.hs-scripts.com/40076178.js" />
      )}
      {!chatIsOpen && (
        <style>
          {`
      #hubspot-messages-iframe-container {
        display: none !important;
      }
      `}
        </style>
      )}
    </>
  )
}
