import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Pencil({
  width,
  height = 18,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.867 2.9803L15.0038 6.11708M13.1976 1.64965C13.6136 1.23369 14.1778 1 14.766 1C15.3543 1 15.9184 1.23369 16.3344 1.64965C16.7504 2.06561 16.9841 2.62978 16.9841 3.21804C16.9841 3.8063 16.7504 4.37047 16.3344 4.78643L4.12084 17H1.01599V13.8313L13.1976 1.64965Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
