import { useEffect, useState } from 'react'

const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}
export type ScreenSize = keyof typeof screens

function pixelToNum(px: string) {
  if (px.substring(px.length - 2, px.length) !== 'px')
    throw new Error(`Invalid pixel value (${px})`)
  const num = px.slice(0, -2)
  if (Number.isNaN(+num)) throw new Error(`Invalid pixel value (${num})`)
  return Number(num)
}

function convertPixelsToEnum(size: number): ScreenSize {
  if (size < pixelToNum(screens.md)) return 'sm'
  if (size < pixelToNum(screens.lg)) return 'md'
  if (size < pixelToNum(screens.xl)) return 'lg'
  if (size < pixelToNum(screens['2xl'])) return 'xl'
  return '2xl'
}

export function getScreenSize() {
  return convertPixelsToEnum(window.innerWidth)
}

export default function useScreenSize(): ScreenSize {
  // better to store as enum bc it will trigger fewer rerenders
  const [size, setSize] = useState(getScreenSize)
  useEffect(() => {
    const updateSize = () => setSize(getScreenSize())
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}
