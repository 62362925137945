/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type RenofiAdOfferType = (typeof RenofiAdOfferType)[keyof typeof RenofiAdOfferType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenofiAdOfferType = {
  heloc: 'heloc',
  personal_loan: 'personal_loan',
} as const
