/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type StripeConnectedAccountLinkTransfersCapabilityStatus =
  (typeof StripeConnectedAccountLinkTransfersCapabilityStatus)[keyof typeof StripeConnectedAccountLinkTransfersCapabilityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeConnectedAccountLinkTransfersCapabilityStatus = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
} as const
