import { Cb, Q } from 'cb'

export const moduleId = 'profile-settings'

export function useUrlParams() {
  return {
    //
  }
}

export function useQueries() {
  return Q.group({
    emailVerifications: Cb.useListEmailVerifications(),
    employeeCommunicationConfig: Cb.useRetrieveSingletonEmployeeCommunicationConfig(),
  })
}
