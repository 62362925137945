import { useMemo } from 'react'
import { Trans } from 'react-i18next'

type Props<T extends string> = {
  tKey: T
  components: Record<T, JSX.Element>
}

export function TransAdaptor<T extends string>({ tKey, components }: Props<T>) {
  const [numberedKey, numberedComponents] = useMemo(() => {
    let keyTmp: string = tKey
    const cTmp: JSX.Element[] = []
    Object.keys(components).forEach((k, i) => {
      keyTmp = keyTmp.replaceAll(`<${k}>`, `<${i}>`)
      keyTmp = keyTmp.replaceAll(`</${k}>`, `</${i}>`)
      cTmp.push((components as Record<string, JSX.Element>)[k])
    })
    return [keyTmp, cTmp]
  }, [tKey, components])

  return <Trans defaults={numberedKey} components={numberedComponents} />
}
