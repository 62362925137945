import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Trash({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33334 6.83333V10.8333M8.00001 6.83333V10.8333M1.33334 4.16667H12M11.3333 4.16667L10.7553 12.2613C10.7314 12.5977 10.5809 12.9125 10.3341 13.1424C10.0873 13.3722 9.76258 13.5 9.42534 13.5H3.90801C3.57077 13.5 3.24605 13.3722 2.99927 13.1424C2.75248 12.9125 2.60195 12.5977 2.57801 12.2613L2.00001 4.16667H11.3333ZM8.66668 4.16667V2.16667C8.66668 1.98986 8.59644 1.82029 8.47141 1.69526C8.34639 1.57024 8.17682 1.5 8.00001 1.5H5.33334C5.15653 1.5 4.98696 1.57024 4.86194 1.69526C4.73691 1.82029 4.66668 1.98986 4.66668 2.16667V4.16667H8.66668Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
