import { ReactNode } from 'react'
import { Modal as BaseModal } from 'antd'
import useScreenSize from '../../theme/useScreenSize'

type Props = {
  isActive: boolean
  setInactive: () => void
  children: ReactNode
  width?: number
  full?: boolean
}

export default function Modal(props: Props) {
  const { isActive, setInactive, children, width } = props
  const sz = useScreenSize()
  const full = props.full ?? sz === 'sm'

  return (
    <BaseModal
      open={isActive}
      onOk={setInactive}
      onCancel={setInactive}
      prefixCls={`v2-ant-modal${full ? '-full' : ''}`}
      rootClassName={full ? '' : 'relative z-[10040]'}
      bodyStyle={{ height: full ? '100%' : undefined }}
      closable={false}
      destroyOnClose
      width={full ? undefined : width}
      footer={null}
    >
      <div className="h-full md:h-auto">{children}</div>
    </BaseModal>
  )
}
