/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowOutboundSyncDetailsStatus =
  (typeof BillListRowOutboundSyncDetailsStatus)[keyof typeof BillListRowOutboundSyncDetailsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowOutboundSyncDetailsStatus = {
  new: 'new',
  completed: 'completed',
  failed: 'failed',
} as const
