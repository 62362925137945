/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GetRenofiAdViewOutputNoOfferReason =
  (typeof GetRenofiAdViewOutputNoOfferReason)[keyof typeof GetRenofiAdViewOutputNoOfferReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRenofiAdViewOutputNoOfferReason = {
  amount_too_low: 'amount_too_low',
  not_homeowner: 'not_homeowner',
  state_not_supported: 'state_not_supported',
} as const
