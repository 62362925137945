import { Factory } from '../types'
import { factory, FactoryProps } from './utils'

type Props<TWritable, TValid> = FactoryProps<TWritable, TWritable, TValid> & {
  initialValue: TWritable
}

export function base<TWritable, TValid>(
  props: Props<TWritable, TValid>,
): Factory<TWritable, TWritable, TWritable, TValid> {
  return factory({
    initialValue: props.initialValue,
    validate: props.validate,
    prepareWriteForStorage: (x) => x,
    prepareStoredValueForRead: (x) => x,
    setUpController: (_, x) => x,
    type: 'cell',
  })
}
