import { createContext, FC, ReactNode, useContext } from 'react'

export function ContextBuilder<T extends object>(defaultValue?: Partial<T>) {
  const Ctx = createContext<Partial<T>>(defaultValue ?? {})
  const Provider: FC<{ children: ReactNode; value: Partial<T> }> = function MProvider(props) {
    return <Ctx.Provider value={props.value}>{props.children}</Ctx.Provider>
  }

  return { Provider, useContext: () => useContext(Ctx) }
}
