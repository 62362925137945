import LinkOwnerBankForm from 'components/misc/LinkOwnerBankForm'
import BankCardView from 'components/misc/BankCardView'
import { Cb } from 'cb'
import { t } from 'content'
import { Action } from 'utils/actions'
import { ClosableLayout } from 'utils/ClosableLayout'

export default function BankAccountPage() {
  const bankAccounts = Cb.useListBankAccounts({ params: { archived: false } }).data ?? []
  const { setInactiveWithoutWarning } = ClosableLayout.useContext()

  return (
    <ClosableLayout.Body
      title={t('Activate your account')}
      pageName={t('Link your bank account')}
      pageNumber="3 / 3"
      actions={[
        Action.button(t('Finish and activate your account'), {
          qualify: () => (bankAccounts.length === 0 ? '' : true),
          onClick: setInactiveWithoutWarning,
        }),
      ]}
    >
      {bankAccounts.filter((ba) => ba.inbound_transfers_enabled).length === 0 ? (
        <div className="flex justify-center md:py-8">
          <LinkOwnerBankForm />
        </div>
      ) : (
        <div className="vflex gap-4">
          {bankAccounts.map((b, i) => (
            <BankCardView key={i} bankAccount={b} />
          ))}
        </div>
      )}
    </ClosableLayout.Body>
  )
}
