import { ReactNode, useId, useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { cn } from 'msutils/classnames'
import Spinner from 'compass-local/Spinner'
import {
  CircledCheckmark,
  Document as DocumentIcon,
  DocumentError,
  DocumentWithCheckmark,
  Download,
  ErrorIcon,
  Paperclip,
} from 'compass-local/legacy/icons'
import Typography from 'compass/data/Typography'
import Modal from 'compass-local/Modal'
import { t } from 'content'
import File from 'compass-local/File'
import { unreachable } from 'msutils/misc'
import FileSaver from 'file-saver'
import { useControlledOrUncontrolled } from 'utils/misc'

type FilePreviewModalProps = {
  name: string
  setInactive: () => void
  children: ReactNode
}

const FilePreviewModal = Modal.asModal((props: FilePreviewModalProps) => {
  return (
    <Modal.Body title={props.name} setInactive={props.setInactive}>
      {props.children}
    </Modal.Body>
  )
})

type Props = {
  name: string
  expandedName?: string
  expandable?: boolean
  downloadable?: boolean
  leftIcon?: 'file' | 'paperclip' | 'file-with-exclamation' | 'file-with-checkmark'
  loading?: boolean
  rightIcon?: 'checkmark' | 'warning' | 'error'
  disabled?: boolean
  caption?: string
  loadFileDONOTUSE?: () => Promise<File>
  fileQuery?: UseQueryResult<File | Blob>
}

export default function FilePreview({
  name,
  expandable,
  downloadable,
  expandedName,
  leftIcon,
  rightIcon,
  loading,
  disabled,
  caption,
  loadFileDONOTUSE,
  fileQuery,
}: Props) {
  const previewTitle = expandedName ?? name

  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const [previewIsOpen, setPreviewIsOpen] = useState(false)
  const id = useId()
  const fileQ = useControlledOrUncontrolled(
    fileQuery,
    useQuery({
      queryFn: loadFileDONOTUSE || (() => Promise.resolve(null)),
      queryKey: ['file-preview', id],
    }),
  )

  return (
    <div className="flex gap-5 justify-between truncate">
      <div className="flex gap-2 truncate">
        <FilePreviewModal
          name={previewTitle}
          setInactive={() => setPreviewIsOpen(false)}
          isActive={previewIsOpen}
        >
          {fileQ.status === 'error' ? (
            <div className="h-[320px] flex items-center justify-center">
              {t('Unexpected error')}
            </div>
          ) : fileQ.status === 'loading' ? (
            <div className="h-[320px] flex items-center justify-center">
              <Spinner w="w-[16px]" h="h-[16px]" />
            </div>
          ) : fileQ.status === 'success' ? (
            <File file={fileQ.data as File} title={t('File preview')} />
          ) : (
            unreachable(fileQ)
          )}
        </FilePreviewModal>
        <div className={disabled ? 'text-th-coolgrey-2' : 'text-th-brown-2'}>
          {leftIcon === 'file' ? (
            <DocumentIcon height={16} />
          ) : leftIcon === 'paperclip' ? (
            <Paperclip height={16} />
          ) : leftIcon === 'file-with-exclamation' ? (
            <DocumentError height={16} />
          ) : leftIcon === 'file-with-checkmark' ? (
            <DocumentWithCheckmark height={16} />
          ) : leftIcon === undefined ? null : (
            unreachable(leftIcon)
          )}
        </div>
        <div className="vflex gap-1 truncate">
          <div className="flex gap-2 items-center">
            <Typography
              onClick={() => {
                if (expandable) {
                  setPreviewIsOpen(true)
                }
              }}
              variant={expandable || disabled ? 'bodybold' : 'body'}
              className={cn(
                expandable
                  ? 'text-th-brown-1'
                  : disabled || loading
                  ? 'text-th-coolgrey-2'
                  : undefined,
                expandable && 'cursor-pointer',
                'truncate !inline-block',
              )}
            >
              {name}
            </Typography>
            {loading ? (
              <Spinner />
            ) : rightIcon === 'checkmark' ? (
              <CircledCheckmark height={12} className="text-th-green-success" />
            ) : rightIcon === 'error' ? (
              <ErrorIcon height={12} className="text-th-red-warning" />
            ) : null}
          </div>
          {caption && <Typography variant="caption">{caption}</Typography>}
        </div>
      </div>
      {downloadable &&
        (loadFileDONOTUSE || fileQuery) &&
        (downloadInProgress ? (
          <Spinner color="text-th-brown-2" />
        ) : (
          <Download
            className="hitbox text-th-brown-1"
            height={18}
            onClick={async () => {
              try {
                setDownloadInProgress(true)
                if (loadFileDONOTUSE) {
                  const file = await loadFileDONOTUSE()
                  FileSaver.saveAs(file, name)
                } else {
                  const file = fileQ.data
                  if (file) {
                    FileSaver.saveAs(file, name)
                  } else {
                    // TODO: handle cases where they click download too quickly
                  }
                }
              } catch (e) {
                // pass
              } finally {
                setDownloadInProgress(false)
              }
            }}
          />
        ))}
    </div>
  )
}
