/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CardTransactionStatus =
  (typeof CardTransactionStatus)[keyof typeof CardTransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardTransactionStatus = {
  declined: 'declined',
  pending: 'pending',
  settled: 'settled',
  voided: 'voided',
  rejected: 'rejected',
} as const
