import { ReactNode } from 'react'
import { TypographyUtils } from 'compass/data/Typography/utils'
import { MetricsUtils } from 'compass-local/Metrics'
import { unreachable } from 'msutils/misc'
import useScreenSize from 'compass/theme/useScreenSize'

export namespace BaseMetricUtils {
  // eslint-disable-next-line mosaic-js/unnamed-args
  export function Value(k: string, v: ReactNode, spec?: MetricsUtils.Spec) {
    return { k, v, ...spec }
  }

  export function defaultLayout(context: {
    sz: ReturnType<typeof useScreenSize>
  }): MetricsUtils.Layout {
    return context.sz === 'sm' ? 'h' : 'v'
  }

  export function getKeyTypographyVariant(variant: MetricsUtils.Variant): TypographyUtils.Variant {
    return variant === 'xsmall'
      ? 'caption'
      : variant === 'small'
      ? 'label'
      : variant === 'medium'
      ? 'label'
      : variant === 'large'
      ? 'body'
      : variant === 'preview/medium'
      ? 'bodybold'
      : variant === 'preview/large'
      ? 'bodybold'
      : unreachable(variant)
  }

  export function getValueTypographyVariant(
    variant: MetricsUtils.Variant,
    context: { sz: ReturnType<typeof useScreenSize>; bold: boolean },
  ): TypographyUtils.Variant {
    return variant === 'xsmall'
      ? 'body'
      : variant === 'small'
      ? context.bold
        ? 'bodybold'
        : 'body'
      : variant === 'medium'
      ? context.bold
        ? 'title'
        : 'subtitle'
      : variant === 'large'
      ? context.sz === 'sm'
        ? 'header'
        : 'drawerheader'
      : variant === 'preview/medium'
      ? context.sz === 'sm'
        ? 'title'
        : 'title'
      : variant === 'preview/large'
      ? context.sz === 'sm'
        ? 'drawerheader'
        : 'drawerheader'
      : unreachable(variant)
  }
}
