import { useRef, useState, useEffect } from 'react'
import { Field } from './base'
import * as Utils from './js-utils'
import { Input } from './utils'

export function useInitSchema(s: any, { initValue, config }: any): any {
  const inputStorable = Utils.initStorable(s, initValue)
  const [state, setState] = useState(inputStorable)
  const readable = Utils.initReadable(s, state, setState)
  const readableWithConfig = Utils.attachConcreteConfig(s, readable, config)
  const readableWithValidation = Utils.applyValidation(s, readableWithConfig, config)
  const fixedInitProps = useRef({ s, readable, config }).current
  useEffect(() => {
    Utils.callOnInits(fixedInitProps.s, fixedInitProps.readable, fixedInitProps.config)
  }, [fixedInitProps])
  return Utils.flattenAccessors(s, readableWithValidation)
}

export function useInputState<T extends Field<any, any, any>>(s: T): Input<T> {
  return useInitSchema(s, {})
}
