import React, { useEffect, useRef, useState } from 'react'
import NextImage from 'next/image'
import { cn } from 'msutils/classnames'

type Props = {
  file: File
  title: string
  noShadow?: boolean
  noBorder?: boolean
  clickToOpen?: boolean
  className?: string
  sizeWithHeight?: boolean
}

function Image({
  file,
  title,
  clickToOpen,
  noShadow,
  noBorder,
  sizeWithHeight = false,
  className,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(100)
  const [height, setHeight] = useState(100)

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const containerWidth = containerRef.current?.clientWidth
      const containerHeight = containerRef.current?.clientHeight
      const contentWidth = contentRef.current?.clientWidth
      const contentHeight = contentRef.current?.clientHeight

      if (contentWidth && containerWidth && contentHeight && containerHeight) {
        if (sizeWithHeight) {
          setHeight(containerHeight)
          setWidth((containerHeight / contentHeight) * contentWidth)
        } else {
          setWidth(containerWidth)
          setHeight((containerWidth / contentWidth) * contentHeight)
        }
      }
    })

    if (containerRef.current && contentRef.current) {
      observer.observe(containerRef.current)
      observer.observe(contentRef.current)

      return () => observer.disconnect()
    } else {
      return () => undefined
    }
  }, [containerRef, contentRef, sizeWithHeight])

  const url = URL.createObjectURL(file)

  return (
    <div
      className={cn('relative', className)}
      style={sizeWithHeight ? { direction: 'rtl' } : undefined}
    >
      <div
        ref={containerRef}
        className={cn(
          'relative box-content overflow-hidden',
          !noBorder && 'border',
          !noShadow && 'shadow-md',
          sizeWithHeight ? 'h-full' : 'w-full',
        )}
        style={sizeWithHeight ? { width: `${width}px` } : { height: `${height}px` }}
      >
        <div ref={contentRef} className="absolute top-0 left-0 origin-top-left">
          <NextImage src={url} alt={title} width={width} height={height} />
        </div>
      </div>
      {clickToOpen && (
        <>
          {/* eslint-disable-next-line */}
          <a className="absolute inset-0" target="_blank" href={url} rel="noreferrer" />
        </>
      )}
    </div>
  )
}

export default React.memo(Image)
