/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QBPushRequestFailureReason =
  (typeof QBPushRequestFailureReason)[keyof typeof QBPushRequestFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QBPushRequestFailureReason = {
  unknown: 'unknown',
  circuit_breaker: 'circuit_breaker',
  existing_unconfirmed_push: 'existing_unconfirmed_push',
  not_clean: 'not_clean',
  invalid_update: 'invalid_update',
  subscription_ended: 'subscription_ended',
  stale_object: 'stale_object',
  deleted_reference: 'deleted_reference',
  duplicate_number: 'duplicate_number',
  revoked_access: 'revoked_access',
  timeout: 'timeout',
  null: null,
} as const
