import { useMemo } from 'react'
import { Cb, Q } from 'cb'
import useUrlParam from 'utils/useUrlParam'
import { z } from 'zod'
import { Pagination } from 'utils/pagination'

export const moduleId = 'clients'

export function useUrlParams() {
  const q = useUrlParam('q', z.string().default(''))
  const p = useUrlParam('p', z.number().default(1))
  return useMemo(() => ({ searchState: q, pageState: p }), [q, p])
}

export function useQueries() {
  return Q.group({
    count: Cb.useListClientListRows({
      params: { page: 1, archived: false, page_size: Pagination.PageSize },
      select: (x) => x.count,
    }),
  })
}
