import { unreachable } from '../misc'

export type State = 'clean' | 'unclean' | 'touched' | 'retouched' | 'editing' | 'changed'
export type Actions<T> = {
  update: (newValue: T) => void
  focus: () => void
  blur: () => void
  tap: () => void
}

export function shouldShowError(state: State) {
  return state === 'changed' || state === 'unclean'
}

export function hasEdited(state: State) {
  return state === 'changed' || state === 'editing'
}

export function isFocused(state: State) {
  return state === 'touched' || state === 'retouched' || state === 'editing'
}

export function isClean(state: State) {
  return state === 'clean'
}

export function transition(oldState: State, action: keyof Actions<any>): State {
  switch (oldState) {
    case 'clean':
      switch (action) {
        case 'update':
          return 'changed'
        case 'focus':
          return 'touched'
        case 'tap':
          return 'unclean'
        case 'blur':
          return 'clean'
        default:
          return unreachable(action)
      }
    case 'unclean':
      switch (action) {
        case 'update':
          return 'changed'
        case 'focus':
          return 'retouched'
        case 'tap':
          return 'unclean'
        case 'blur':
          return 'unclean'
        default:
          return unreachable(action)
      }
    case 'touched':
      switch (action) {
        case 'update':
          return 'editing'
        case 'focus':
          return 'touched'
        case 'tap':
          return 'unclean'
        case 'blur':
          return 'unclean'
        default:
          return unreachable(action)
      }
    case 'retouched':
      switch (action) {
        case 'update':
          return 'editing'
        case 'focus':
          return 'retouched'
        case 'tap':
          return 'unclean'
        case 'blur':
          return 'unclean'
        default:
          return unreachable(action)
      }
    case 'editing':
      switch (action) {
        case 'update':
          return 'editing'
        case 'focus':
          return 'editing'
        case 'tap':
          return 'changed'
        case 'blur':
          return 'changed'
        default:
          return unreachable(action)
      }
    case 'changed':
      switch (action) {
        case 'update':
          return 'changed'
        case 'focus':
          return 'editing'
        case 'tap':
          return 'changed'
        case 'blur':
          return 'changed'
        default:
          return unreachable(action)
      }
    default:
      return unreachable(oldState)
  }
}
