import { FC, Fragment, ReactNode } from 'react'
import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { Plus, X } from 'compass-local/legacy/icons'
import { t } from 'content'
import { Collapsable, MSArray } from 'msutils/array'
import { LayoutSwitcher } from 'utils/components'
import { InputBaseContext } from 'compass-local/InputBase'

type InputTableProps<T, S extends string> = {
  rows: T[]
  controller: { append: () => void; remove: (i: number) => void }
  disableAppend?: boolean
  columns: Collapsable<{ id: S; name: ReactNode }[]>
  disableRemove?: (entry: T, i: number) => boolean | undefined
  components: (entry: T, i: number) => { [K in S]: ReactNode }
  MobileRow?: FC<{ entry: T }>
  overrides?: Partial<{
    append: ReactNode
  }>
}

export function InputTable<T, S extends string>(props: InputTableProps<T, S>) {
  const { controller, rows, disableAppend, disableRemove, components, MobileRow, overrides } = props
  const columns = MSArray.collapse(props.columns)

  const table = (
    <table>
      <thead>
        <tr>
          {columns.map((x, i) => (
            <th key={i} className="text-left px-1.5 first:px-3">
              <Typography variant="label">{x.name}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((entry, i) => {
          const componentsForRow = components(entry, i)
          const deletable = !disableRemove?.(entry, i)

          return (
            <InputBaseContext key={i} annotationStyle="border-only" title="force-empty">
              <tr className="border-y-[8px] border-white">
                {columns.map((column, j) => (
                  <td key={j} className="bg-th-bg-slate py-3 px-1.5 first:pl-3 last:pr-3">
                    {componentsForRow[column.id]}
                  </td>
                ))}
                {deletable && (
                  <td className="bg-th-bg-slate p-3">
                    <X
                      height={14}
                      className="text-th-text-secondary cursor-pointer hitbox"
                      onClick={() => controller.remove(i)}
                    />
                  </td>
                )}
              </tr>
            </InputBaseContext>
          )
        })}
      </tbody>
    </table>
  )

  const list = (
    <div className="vflex gap-3 -mx-5">
      {rows.map((entry, i) => {
        if (MobileRow) {
          return (
            <Fragment key={i}>
              <MobileRow entry={entry} />
            </Fragment>
          )
        } else {
          const componentsForRow = components(entry, i)
          const deletable = !disableRemove?.(entry, i)

          return (
            <div key={i} className="bg-th-bg-slate vflex gap-5 p-5">
              {deletable && (
                <div className="flex justify-end">
                  <X
                    height={14}
                    className="text-th-text-secondary cursor-pointer hitbox"
                    onClick={() => controller.remove(i)}
                  />
                </div>
              )}
              {columns.map((column, j) => (
                <Fragment key={j}>{componentsForRow[column.id]}</Fragment>
              ))}
            </div>
          )
        }
      })}
    </div>
  )

  return (
    <div className="vflex gap-2 md:gap-1">
      <LayoutSwitcher sm={list} lg={table} />
      <div className="flex justify-end">
        {overrides?.append ?? (
          <LinkButtonDONOTUSE
            onClick={controller.append}
            icon={<Plus height={16} />}
            disabled={disableAppend}
          >
            {t('Add item')}
          </LinkButtonDONOTUSE>
        )}
      </div>
    </div>
  )
}
