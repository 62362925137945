/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceTypeMilestoneInvoiceType =
  (typeof InvoiceTypeMilestoneInvoiceType)[keyof typeof InvoiceTypeMilestoneInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTypeMilestoneInvoiceType = {
  milestone: 'milestone',
} as const
