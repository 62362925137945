import { Cb } from 'cb'
import Typography from 'compass/data/Typography'
import Alert from 'compass-local/Alert'
import Button from 'compass/data/Button'
import { t } from 'content'
import { F } from 'msutils'
import TextInput from 'compass-local/TextInput'
import { theme2 } from 'theme2'
import { Mutator } from 'utils/form-input'
import { MicrodepositCodeVerificationInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  submit: Mutator<Cb.MicrodepositAttempt>
}

export default function MicrodepositCodeVerificationInput(props: Props) {
  const { state, submit } = props

  return (
    <div className="vflex md:max-w-[480px] gap-2">
      {submit.error && <Alert>{submit.error.message}</Alert>}
      <Typography variant="bodybold">{t('Microdeposit verification')}</Typography>
      <Typography variant="label" className="text-th-text-secondary">
        {t('Enter the 6-digit code from your bank statement to verify your account.')}
      </Typography>
      <form className="vflex gap-4" onSubmit={(e) => e.preventDefault()}>
        <TextInput {...F.props(state.code)} placeholder={t('SMXXXX')} maxLength={6} />

        <Button
          theme={theme2.ButtonThemeLight}
          onClick={() => submit.mutateAsync()}
          submit
          isLoading={submit.isLoading}
        >
          {t('Verify')}
        </Button>
      </form>
    </div>
  )
}
