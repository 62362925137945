import BigNumber from 'bignumber.js'
import { Cb } from 'cb'
import { t } from 'content'
import { Module } from 'modules/routes'
import { MSDate, Zero } from 'msutils'
import { unreachable } from 'msutils/misc'
import { formatCurrencyDONOTUSE } from 'utils/currency'

export namespace BalanceUtils {
  export type TransactionStatus = 'new' | 'pending' | 'failed' | 'complete'

  export function getTransactionStatus(tx: Cb.BeamBalanceTransaction) {
    if (tx.finalized) {
      switch (tx.state) {
        case 'new':
        case 'pending':
          return 'pending'
        case 'failed':
          return 'failed'
        case 'complete':
          return 'complete'
        default:
          return unreachable(tx)
      }
    } else {
      return 'new'
    }
  }

  export function getYieldPaymentTotal(context: { yieldPayments: Cb.YieldPayment[] }) {
    return BigNumber.sum(Zero, ...context.yieldPayments.map((x) => BigNumber(x.amount)))
  }

  export function getSignedTransactionAmount(tx: Cb.BeamBalanceTransaction) {
    switch (tx.direction) {
      case 'debit':
        return BigNumber(tx.amount).negated()
      case 'credit':
        return BigNumber(tx.amount)
      default:
        return unreachable(tx)
    }
  }

  export function getAccountBalance(account: Cb.TransactionAccountTypeInternalBankAccount) {
    return account.internal_bank_account_details.balance
  }

  export function getAccountAvailableBalance(
    account: Cb.TransactionAccountTypeInternalBankAccount,
  ) {
    return account.internal_bank_account_details.available_balance
  }

  export function getNetPendingBalance(account: Cb.TransactionAccountTypeInternalBankAccount) {
    return account.internal_bank_account_details.net_pending_amount
  }

  export function getNetScheduledBalance(account: Cb.TransactionAccountTypeInternalBankAccount) {
    return account.internal_bank_account_details.net_pending_amount
  }

  export function getYieldToDate(account: Cb.TransactionAccountTypeInternalBankAccount) {
    return account.internal_bank_account_details.net_pending_amount
  }

  export function getTransactionLedgerDate(txn: Cb.BeamBalanceTransaction) {
    switch (txn.direction) {
      case 'credit':
        return txn.completed_date ?? txn.estimated_completed_date
      case 'debit':
        return txn.initiated_date ?? txn.estimated_initiated_date
      default:
        return unreachable(txn)
    }
  }

  export function getStatementDownloadName(month: MSDate.Date) {
    return `beam-statement-${MSDate.format(month, { form: 'isoMonth' })}`
  }

  export function getLedgerItemTitle(tx: Cb.BeamBalanceTransaction) {
    switch (tx.type) {
      case 'yield_payment':
        return t('Beam Balance yield')
      case 'cash_back_payment':
        return t('Beam Card cash back')
      default:
        return tx.counterparty_name
    }
  }

  export function getLedgerItemSubtitle(tx: Cb.BeamBalanceTransaction) {
    switch (tx.type) {
      case 'yield_payment':
      case 'cash_back_payment':
        return null
      default:
        return tx.statement_descriptor
          ? t('{{ X1 }} • {{ X2 }}', {
              X1: tx.statement_descriptor,
              X2: tx.counterparty_account_name ?? t('External account'),
            })
          : null
    }
  }

  export function getLedgerCategorization(tx: Cb.BeamBalanceTransaction) {
    switch (tx.type) {
      case 'invoice_payment':
        return tx.direction === 'credit'
          ? t('Invoice #{{ X }}', { X: tx.invoice_payment_details.invoice_number })
          : t('Bill #{{ X }}', { X: tx.invoice_payment_details.invoice_number })
      case 'transfer':
        return t('Account transfer')
      case 'external_transfer':
        return t('Account transfer')
      case 'yield_payment':
        return t('Yield payment')
      case 'cash_back_payment':
        return t('Cash back')
      case 'credit_card_bill_payment':
        return t('Credit card payment')
      case 'expense_payment':
        return t('Expense • {{ X }}', { X: tx.expense_payment_details.payee_name })
      case 'expense_card_repayment':
        return t('Beam Card repayment')
      case 'external':
        return '--'
      default:
        return unreachable(tx)
    }
  }

  export function getLedgerCategorizationSubtitle(tx: Cb.BeamBalanceTransaction) {
    switch (tx.type) {
      case 'invoice_payment':
        return tx.invoice_payment_details.project_name
      case 'external_transfer':
        return tx.external_transfer_details.external_bank_account_name
      case 'yield_payment':
        return MSDate.formatRange(
          tx.yield_payment_details.start_date,
          tx.yield_payment_details.end_date,
        )
      case 'cash_back_payment':
        return t('For repayment of {{ X }}', {
          X: formatCurrencyDONOTUSE(tx.cash_back_payment_details.repayment_amount),
        })
      case 'credit_card_bill_payment':
        return tx.credit_card_bill_payment_details.credit_card_name
      case 'expense_payment':
        return tx.expense_payment_details.project_name
      case 'expense_card_repayment':
      case 'transfer':
      case 'external':
        return null
      default:
        return unreachable(tx)
    }
  }

  export function getLedgerCategorizationHref(tx: Cb.BeamBalanceTransaction) {
    switch (tx.type) {
      case 'invoice_payment':
        return tx.direction === 'credit'
          ? Module(`/invoices/${tx.invoice_payment_details.invoice_id}`).href
          : Module(`/bills/${tx.invoice_payment_details.invoice_id}`).href
      case 'expense_payment':
        return Module(`/expenses/${tx.expense_payment_details.expense_id}`).href
      case 'external_transfer':
      case 'yield_payment':
      case 'cash_back_payment':
      case 'credit_card_bill_payment':
      case 'expense_card_repayment':
      case 'transfer':
      case 'external':
        return null
      default:
        return unreachable(tx)
    }
  }

  export function hasSufficientInternalBalance(
    accounts: Cb.TransactionAccount[],
    amount: BigNumber,
  ) {
    const internalAccount = accounts
      .flatMap((x) => (x.type === 'internal_bank_account' ? x : []))
      .at(0)

    return (
      !!internalAccount &&
      amount.isLessThanOrEqualTo(
        BigNumber(internalAccount.internal_bank_account_details.available_balance),
      )
    )
  }
}
