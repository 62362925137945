import { ReactNode, useEffect, useState } from 'react'

type Props = {
  delayMilliseconds: number
  children: ReactNode
}

export default function Delayed({ children, delayMilliseconds }: Props) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delayMilliseconds)
    return () => clearTimeout(timer)
  }, [delayMilliseconds])

  return show ? <>{children}</> : null
}
