/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentTypeLicenseType =
  (typeof ComplianceDocumentTypeLicenseType)[keyof typeof ComplianceDocumentTypeLicenseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentTypeLicenseType = {
  license: 'license',
} as const
