import { Fragment, useState } from 'react'
import Typography from 'compass/data/Typography'
import Divider from 'compass-local/Divider'
import { devLike, ENVIRONMENT } from 'env'
import { unreachable } from 'msutils/misc'
import Button from 'compass/data/Button'
import { Refresh } from 'compass-local/legacy/icons'
import { theme2 } from 'theme2'
import { defineModule } from '../utils'

let statements: any[] = []

// eslint-disable-next-line
function debugLog(statement: any, type: 'error' | 'warning' | 'info' = 'info') {
  statements = statements.concat(statement)

  if (!devLike(ENVIRONMENT)) {
    return
  }

  switch (type) {
    case 'info':
      // eslint-disable-next-line
      console.log(statement)
      break
    case 'warning':
      // eslint-disable-next-line
      console.warn(statement)
      break
    case 'error':
      // eslint-disable-next-line
      console.error(statement)
      break
    default:
      unreachable(type)
  }
}

function asStatement(x: any) {
  if (typeof x === 'object') return JSON.stringify(x)
  return String(x)
}

function ConsoleModule() {
  const [key, setKey] = useState(0)

  return (
    <div className="vflex gap-3" key={key}>
      <div className="w-fit">
        <Button
          icon={<Refresh />}
          theme={theme2.ButtonThemeTextOrange}
          onClick={() => setKey((k) => k + 1)}
        />
      </div>
      {statements.map((x, i) => (
        <Fragment key={i}>
          {i !== 0 && <Divider />}
          <Typography variant="label">{asStatement(x)}</Typography>
        </Fragment>
      ))}
    </div>
  )
}

export default defineModule({
  id: 'console',
  component: <ConsoleModule />,
})
