import Typography from 'compass/data/Typography'
import { TypographyUtils } from 'compass/data/Typography/utils'
import { cn } from 'msutils/classnames'
import BaseMetric from 'compass-local/BaseMetric'
import { OptionProp } from './utils'

type Props<TValue> = OptionProp<TValue> & {
  getTitle: (value: TValue) => string
  titleVariant: TypographyUtils.Variant
}

export function OptionLabel<TValue>(props: Props<TValue>) {
  const inner = (
    <div className="vflex gap-1 py-0.5">
      <div className="flex gap-1 items-center">
        <Typography variant={props.titleVariant}>
          {props.title ?? props.getTitle(props.value)}
        </Typography>
        {props.badge}
      </div>
      {props.label && (
        <Typography variant="caption" className="text-th-text-secondary">
          {props.label}
        </Typography>
      )}
    </div>
  )
  return props.metric ? (
    <div className={cn('flex justify-between py-0.5', props.label && 'items-center')}>
      {inner}
      <BaseMetric k={props.metric.k} v={props.metric.v} align="right" variant="xsmall" />
    </div>
  ) : (
    inner
  )
}
