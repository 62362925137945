/* eslint-disable */
function empty<T>(x: T) {
  return x === null || x === undefined || (typeof x === 'string' && !x.trim())
}

export function required<T>(message?: string) {
  return (x: T | null) => {
    if (!x || empty(x)) throw new Error(message ?? 'Required')
    return x
  }
}

export function nonempty(message?: string) {
  return (x: string) => required<string>(message)(x || null)
}

export function first<T>(message?: string) {
  return (x: T[]) => required<T>(message)(x.at(0) || null)
}

export function truthy(message?: string) {
  return (x: boolean) => {
    if (!x) throw new Error(message ?? 'Required')
    return x
  }
}

export function atLeastOneElement<T>(message?: string) {
  return (x: T[]) => {
    if (!x.at(0)) throw new Error(message ?? 'Required')
    return x
  }
}

export function numeric<T>(message?: string) {
  return (x: T) => {
    if (empty(x)) return x
    if (Number.isNaN(+x)) throw new Error(message ?? 'Must be a number')
    return x
  }
}

export function length(len: number, trim: boolean, message?: string) {
  return (x: string) => {
    if (empty(x)) return x
    const value = trim ? x.trim() : x
    if (value.length !== len) throw new Error(message ?? `Must be ${len} characters.`)
    return x
  }
}

export function minimumLength(len: number, trim = true) {
  return (x: string) => {
    if (empty(x)) return x
    const value = trim ? x.trim() : x
    if (value.length < len) throw new Error(`Must be at least ${len} characters.`)
    return x
  }
}

type Validator<T> = (val: T) => void
export function validateAll<T>(...validators: Validator<T>[]) {
  return (val: T) => {
    validators.forEach((v) => v(val))
    return val
  }
}
