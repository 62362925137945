import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Devtools from 'devtools'
import { useCurrentRoute } from 'utils/router'
import { AuthGate } from './auth'
import { UnauthedGate, Utm } from './component-utils'
import GlobalBrowserMetadata from './global/BrowserMetadata'
import AppContext from './user/AppContext'
import { UiBaseForUserType } from './user/UiBaseForUserType'
import { UserIdentifier } from './user/component-utils'
import * as RootUtils from './utils'
import Prefetches from './Prefetches'

const GlobalProvider = dynamic(() => import('./global/Provider'), { ssr: false })

export default function Root2(props: AppProps) {
  const route = useCurrentRoute()

  return (
    <GlobalProvider>
      <Devtools.Unauthed />
      <GlobalBrowserMetadata />
      <Utm />
      {RootUtils.isUnauthedRoute(route) ? (
        <UnauthedGate>
          <props.Component {...props.pageProps} />
        </UnauthedGate>
      ) : RootUtils.isRedirectRoute(route) ? (
        <props.Component {...props.pageProps} />
      ) : (
        <AuthGate>
          <AppContext>
            <Devtools.Authed />
            <UserIdentifier />
            <Prefetches />
            <UiBaseForUserType>
              <props.Component {...props.pageProps} />
            </UiBaseForUserType>
          </AppContext>
        </AuthGate>
      )}
    </GlobalProvider>
  )
}
