/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimatePatchAddress2Type =
  (typeof EstimatePatchAddress2Type)[keyof typeof EstimatePatchAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimatePatchAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
