import { useState } from 'react'
import { Action } from 'utils/actions'
import { Cb } from 'cb'
import Typography from 'compass/data/Typography'
import Checkbox from 'compass-local/Checkbox'
import Link2 from 'compass-local/Link2'
import { StaticSelectInput } from 'compass-local/SelectInput'
import TabGroup2, { TabGroupUtils } from 'compass-local/TabGroup'
import TextInput from 'compass-local/TextInput'
import RichAddressInput from 'components/inputs/RichAddressInput'
import { t, Trans } from 'content'
import { F } from 'msutils'
import { useBusinessContext } from 'root/user'
import { useFormState } from 'utils/form-input'
import { ClosableLayout } from 'utils/ClosableLayout'
import { OnboardAccountForm2Utils as Utils } from '../../utils'
import { BusinessPageUtils as PageUtils } from './utils'

export default function BusinessPage() {
  const { push } = Utils.pageManager.useContext()
  const termsOfServices =
    Cb.useListTermsOfServices({
      select: ({ results }) =>
        results.reduce((p, c) => ({ ...p, [c.label]: c }), {}) as {
          [key: string]: Cb.TermsOfService
        },
    }).data ?? {}
  const { business } = useBusinessContext()
  const { state, useValidatedAction } = useFormState(PageUtils.schema, {
    initValue: {
      legalName: business.name,
    },
  })

  const createBusinessProfile = useValidatedAction(
    PageUtils.useCreateBusinessProfile({ termsOfServices }),
    { disableSetInactiveOnSuccess: true },
  )
  const [selectedTaxIdTab, setSelectedTaxIdTab] = useState<'ssn' | 'ein'>('ein')
  const allowSSN = state.businessType.value === 'sole_proprietorship'

  return (
    <ClosableLayout.Body
      title={t('Activate your account')}
      pageName={t('Add business information')}
      pageNumber="1 / 3"
      error={createBusinessProfile.error?.message}
      warnOnClose={state._controller.hasChanged}
      actions={[
        Action.next(t('Create owner profile(s)'), {
          onClick: () => createBusinessProfile.mutateAsync(),
          onSuccess: () => push('owners'),
        }),
      ]}
    >
      <TextInput
        type="name"
        title={t('Legal business name')}
        subtitle={t(
          'This must exactly match what is on your IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and punctuation.',
        )}
        {...F.props(state.legalName)}
      />
      <TextInput type="name" title={t('Business DBA')} optional {...F.props(state.dbaName)} />
      <StaticSelectInput
        title={t('Business type')}
        options={PageUtils.BusinessTypes}
        disableSortByTitle
        {...F.props(state.businessType)}
      />
      <RichAddressInput state={state.address} title={t('Business address')} />
      <TextInput
        title={t('Business URL')}
        subtitle={t("Don't have a website? A Yelp, Facebook, or Thumbtack page works too.")}
        placeholder={t('www.business-name.com')}
        {...F.props(state.url)}
      />
      {allowSSN ? (
        <div className="vflex gap-2">
          <Typography variant="label">
            {t('Please choose one of the following tax identifiers')}
          </Typography>
          <TabGroup2
            value={selectedTaxIdTab}
            update={setSelectedTaxIdTab}
            type="inline"
            options={[TabGroupUtils.option('ein', t('EIN')), TabGroupUtils.option('ssn', t('SSN'))]}
            ui={{
              ein: (
                <TextInput
                  key="ein"
                  {...PageUtils.EinInputProps}
                  title={t('Business EIN')}
                  {...F.props(state.ein)}
                />
              ),
              ssn: (
                <TextInput
                  key="ssn"
                  {...PageUtils.SsnInputProps}
                  title={t('Representative SSN')}
                  {...F.props(state.ssn)}
                />
              ),
            }}
          />
        </div>
      ) : (
        <TextInput {...PageUtils.EinInputProps} title={t('Business EIN')} {...F.props(state.ein)} />
      )}
      <TextInput type="phone" {...F.props(state.phone)} />
      <div className="vflex gap-2">
        <Checkbox
          title={
            <Trans
              tKey="I agree to the <X>Stripe Services Agreement</X>"
              X={<Link2 href="https://stripe.com/legal/ssa" external stopPropagation />}
            />
          }
          {...F.props(state.treasuryTerms)}
        />
        <Checkbox
          title={
            <Trans
              tKey="I agree to the <X>Stripe Connected Account Agreement</X>"
              X={<Link2 href="https://stripe.com/legal/connect-account" external stopPropagation />}
            />
          }
          {...F.props(state.connectTerms)}
        />
      </div>
    </ClosableLayout.Body>
  )
}
