import { useEffect } from 'react'
import { useCurrentPath, useNavigate } from 'utils/router'
import { onlyWhenNotInIFrame } from './utils'

type Props = {
  target: string
}

export const RedirectToTarget = onlyWhenNotInIFrame(({ target }: Props) => {
  const navigate = useNavigate()
  const path = useCurrentPath()

  useEffect(() => {
    const pushTo = `${target}${path}`
    navigate(pushTo)
  }, [navigate, path, target])

  return null
})
