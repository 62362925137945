export function toBe<S>(otherVal: S): <T>(val: T) => val is S extends T ? S : never {
  return <T>(val: T): val is S extends T ? S : never => (val as any) === otherVal
}

export function notToBe<S>(otherVal: S): <T>(val: T) => val is S extends T ? Exclude<T, S> : never {
  return <T>(val: T): val is S extends T ? Exclude<T, S> : never => (val as any) === otherVal
}

export function present(): <T>(val: T) => val is NonNullable<T> {
  return <T>(val: T): val is NonNullable<T> => val !== null && val !== undefined
}

export function truthy(): <T>(val: T) => val is Exclude<T, false | 0 | '' | null | undefined> {
  return <T>(val: T): val is Exclude<T, false | 0 | '' | null | undefined> => !!val
}
