/** @type {import('tailwindcss').Config} */

require('tailwindcss/colors')

module.exports = {
  content: ['./src/**/*.{tsx, jsx, ts}', '../../packages/compass/**/*.{ts,tsx}'],
  safelist: [
    {
      pattern: /text-(xs|sm|base|md|lg|xl)/,
      variants: ['hover', 'focus'],
    },
  ],
  theme: {
    fontFamily: {
      sans: ['Inter', 'system-ui', 'sans-serif'],
      mono: ['SFMono-Regular', 'monospace'],
      cursive: ['Homemade Apple'],
    },
    fontSize: {
      xxs: ['10px', '15px'],
      xs: ['11px', '17px'],
      sm: ['12px', '18px'],
      base: ['14px', '17px'],
      md: ['16px', '24px'],
      lg: ['20px', '30px'],
      xl: ['28px', '42px'],
    },
    spacing: {
      0: '0px',
      DEFAULT: '1px',
      0.5: '2px',
      0.75: '3px',
      1: '4px',
      1.5: '6px',
      2: '8px',
      2.5: '10px',
      3: '12px',
      3.5: '14px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      8: '32px',
      9: '36px',
      10: '40px',
      12: '48px',
      15: '60px',
    },
    borderRadius: {
      DEFAULT: '2px',
      1: '1px',
      2: '2px',
      4: '4px',
      8: '8px',
      16: '16px',
      full: '9999px',
    },
    borderWidth: {
      0: '0px',
      DEFAULT: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
    },
    extend: {
      boxShadow: {
        th: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
        reverse: '0px -2px 4px 2px rgb(221 221 221 / 0.3)',
        DEFAULT: '0px 2px 4px 2px rgb(221 221 221 / 0.5)',
        container: '0 1px 6px 0 rgba(0, 0, 0, 0.1)',
        large: '0 2px 4px 0 rgba(0, 0, 0, 0.22)',
        large2: '0 3px 5px 0 rgba(0, 0, 0, 0.6)',
      },
      colors: {
        th: {
          bg: {
            'beam-black': '#051027',
            'dark-active': '#152038',
            slate: '#F9FAFB',
            white: '#FFFFFF',
            toast: '#1A1D22',
            'white-transparent': 'rgba(255, 255, 255, 0.9)',
            'black-transparent': 'rgba(0, 0, 0, 0.7)',
          },
          text: {
            DEFAULT: '#353A4A',
            primary: '#353A4A',
            focus: '#0B111E',
            disabled: '#909296',
            hint: '#ADAFB4',
            secondary: '#6D7180',
            darkmode: 'rgb(221 225 233 / 0.7)', // #DDE1E9
          },
          coolgrey: {
            dark: '#6F6F78',
            1: '#9CA0A7',
            2: 'rgb(156 163 175 / 0.5)', // #9CA3AF
            2.5: '#E8EBF0',
            3: '#F2F4F8',
            dark3: '#E7EAEF',
          },
          warmgrey: {
            dark: '#AAA6A4',
            1: 'rgb(170 166 164 / 0.5)', // #AAA6A4
            2: 'rgb(208 203 201 / 0.5)', // #D0CBC9
            3: 'rgb(229 224 223 / 0.5)', // #E5E0DF
          },
          brown: {
            1: '#633A23',
            2: '#726562',
          },
          gold: {
            1: '#A8800A',
            2: '#BA9422',
            3: '#F9E3A1',
          },
          orange: {
            dark1: '#C44501',
            beam: '#F87029',
            light1: '#FF9158',
            light2: '#FEE2C8',
          },
          blue: {
            2: '#0046D1',
            light0: '#3E89C2',
            light1: '#BFE8FF',
          },
          green: {
            dark1: '#008932',
            dark2: '#2EBE63',
            success: '#008932',
            light1: '#B6FFB5',
          },
          red: {
            warning: '#BD0002',
            light1: '#FDCFCF',
            dark1: '#E46D6D',
          },
          yellow: {
            light1: '#FEF9C8',
            dark1: '#CF9907',
            dark2: '#E4BC6D',
          },
        },
        notification: '#EF4444',
      },
    },
  },
  // eslint-disable-next-line global-require
  plugins: [require('@tailwindcss/forms')],
}
