import { useQuery } from '@tanstack/react-query'
import { Cb } from 'cb'
import { Smarty } from 'lib/smarty'
import { F } from 'msutils'
import { unreachable } from 'msutils/misc'
import { State, Address, formatAddress } from 'utils/address'
import { branch } from 'utils/misc'
import { timeout } from 'utils/timeout'

export namespace RichAddressInputUtils {
  export const schema = F.Group({
    spec: {
      line1: F.Text({ errorKey: 'rich_details.line_1', required: true }),
      line2: F.Text({ errorKey: 'rich_details.line_2' }),
      city: F.Text({ errorKey: 'rich_details.city', required: true }),
      zip: F.Text({ errorKey: 'rich_details.zip_code', required: true }),
      state: F.Choice<keyof typeof State>({ errorKey: 'rich_details.state' }).required(),
    },
  })

  export function toApi(valids: ReturnType<typeof schema.validate>) {
    return branch('rich', {
      raw_details: null,
      rich_details: {
        line_1: valids.line1,
        line_2: valids.line2,
        city: valids.city,
        zip_code: valids.zip,
        state: valids.state,
      },
    })
  }

  export function fromApi(
    address: Cb.AddressTypeRichRichDetails | Address | null,
  ): typeof schema.initValue {
    return address === null
      ? {}
      : 'line_1' in address
      ? {
          line1: address.line_1,
          line2: address.line_2,
          city: address.city,
          zip: address.zip_code,
          state: address.state,
        }
      : address
  }

  export function format(state: F.Input<typeof schema>) {
    if (state.city.value && state.line1.value && state.state.value && state.zip.value) {
      return formatAddress({
        line1: state.line1.value,
        line2: state.line2.value,
        city: state.city.value,
        state: state.state.value,
        zip: state.zip.value,
      })
    } else {
      return '--'
    }
  }

  export function formatApi(value: Cb.Address | null, settings?: { multiline: boolean }) {
    if (value === null) return null

    switch (value.type) {
      case 'raw':
        return value.raw_details.raw_address
      case 'rich':
        return formatAddress(
          {
            line1: value.rich_details.line_1,
            line2: value.rich_details.line_2,
            city: value.rich_details.city,
            zip: value.rich_details.zip_code,
            state: value.rich_details.state,
          },
          settings,
        )
      default:
        return unreachable(value)
    }
  }

  export function searchAddress(pre: string): Promise<Address[]> {
    if (pre.length <= 2) return Promise.resolve([])
    return Promise.race([Smarty.predict(pre), timeout(2000, []) as Promise<Address[]>])
  }

  export function useSearchAddress(value: string) {
    return useQuery({ queryFn: () => searchAddress(value), queryKey: ['addr-input-v3', value] })
  }

  export function useExactMatch(value: string) {
    return useQuery({
      queryFn: async () => {
        const res = await searchAddress(value)
        return res.length === 1 ? res[0] : null
      },
      queryKey: ['addr-input-v2-exact', value],
    })
  }
}
