import { Cb } from 'cb'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { F, MSDate } from 'msutils'
import { useBusinessContext } from 'root/user'

export namespace OwnersPageUtils {
  export const schema = F.Group({
    spec: {
      owners: F.List({
        spec: F.Group({
          spec: {
            firstName: F.Text({ required: true }),
            lastName: F.Text({ required: true }),
            email: F.Email({ required: true }),
            address: RichAddressInputUtils.schema,
            ssn: F.Ssn({ required: true }),
            phone: F.Phone({ required: true }),
            birthDate: F.Date({ required: true }),
            title: F.Text({ required: true }),
            identityVerificationFile: F.Files({ required: true }),
            ownershipPercentage: F.Text({ required: true }),
          },
        }),
      }),
    },
  })

  export const SsnInputProps = {
    type: 'custom',
    cleave: { numericOnly: true, delimiter: '-', blocks: [3, 2, 4] },
    inputMode: 'numeric',
    inputType: 'password',
    placeholder: '123-45-6789',
  } as const

  export function useCreateOwnerProfiles() {
    const { business } = useBusinessContext()
    const createEmployeeProfile = Cb.useCreateEmployeeProfileHook()

    return (validOwners: F.OutputShape<typeof schema>) => {
      return Promise.all(
        validOwners.owners.map((valids, i) =>
          createEmployeeProfile({
            is_representative: i === 0,
            business_id: business.id,
            line_1: valids.address.line1,
            line_2: valids.address.line2 || null,
            city: valids.address.city,
            state: valids.address.state,
            zip_code: valids.address.zip,
            ssn: valids.ssn,
            dob: MSDate.iso(valids.birthDate),
            title: valids.title,
            identity_verification_file_id: valids.identityVerificationFile[0].uploadId,
            phone: valids.phone,
            ownership_multiplier: valids.ownershipPercentage,
            first_name: valids.firstName,
            last_name: valids.lastName,
            email: valids.email,
          }),
        ),
      )
    }
  }
}
