/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceTypeProgressPaymentState =
  (typeof InvoiceTypeProgressPaymentState)[keyof typeof InvoiceTypeProgressPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTypeProgressPaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
