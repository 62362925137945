import { FC, ReactNode } from 'react'
import { Modal as BaseModal } from 'antd'
import useScreenSize from 'compass/theme/useScreenSize'
import Body from './components/Body'

type OpenerProps = {
  isActive: boolean
  setInactive: () => void
}

type Props = {
  size?: 'small' | 'large'
  blur?: boolean
}
type Children = { children: ReactNode }

function Modal({
  isActive,
  setInactive,
  size = 'small',
  blur,
  children,
}: OpenerProps & Props & Children) {
  const sz = useScreenSize()
  return (
    <>
      <BaseModal
        open={isActive}
        onOk={setInactive}
        onCancel={setInactive}
        rootClassName="relative z-[10040]"
        closable={false}
        destroyOnClose
        footer={null}
        centered={sz === 'sm' ? undefined : true}
        className={size === 'small' || sz === 'sm' ? 'min-w-[320px]' : 'min-w-[720px]'}
      >
        {children}
      </BaseModal>
      {blur && (
        <style>
          {`
          .ant-modal-mask {
            background: rgba(0,0,0,0.5) !important;
            backdrop-filter: blur(5px);
          }
`}
        </style>
      )}
      {sz === 'sm' && (
        <style>
          {`
          .ant-modal, .ant-modal-content {
            height: 100vh;
            width: 100vw;
            margin: 0;
            top: 0;
          }
          .ant-modal-body {
            height: calc(100vh - 40px);
          }
        `}
        </style>
      )}
    </>
  )
}

function asModal<T>(Component: FC<T>, settings?: { blur?: boolean }): FC<OpenerProps & Props & T> {
  return function Inner(props: OpenerProps & T & Props) {
    return (
      <Modal {...props} blur={settings?.blur}>
        <Component {...props} />
      </Modal>
    )
  }
}

export default { Body, asModal }
