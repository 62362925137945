type ErrorConfig = Partial<{
  name: string
  wrappedError: Error
  level: 'warning' | 'error'
}>

export class Error2 extends Error {
  wrappedError: Error | null

  context: object = {}

  level: 'warning' | 'error'

  constructor(message: string, config?: ErrorConfig) {
    super(message)
    if (config?.name) {
      this.name = config.name
    }
    this.wrappedError = config?.wrappedError ?? null
    if (config?.wrappedError) {
      this.stack = config.wrappedError.stack
    }
    this.level = config?.level ?? 'error'
  }

  updateContext(values: object) {
    this.context = { ...this.context, ...values }
  }

  fromBoundary(s: string) {
    this.name = `[${s}] ${this.name}`
    return this
  }
}
