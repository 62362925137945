/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BankAccountMicrodepositRequirementType =
  (typeof BankAccountMicrodepositRequirementType)[keyof typeof BankAccountMicrodepositRequirementType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankAccountMicrodepositRequirementType = {
  code: 'code',
  amounts: 'amounts',
} as const
