import { useEffect, useRef } from 'react'
import { Error2 } from './error'

let error = (e: Error2) => {
  // eslint-disable-next-line
  console.error(`[MSUtils (default handler)]: ${e.message}`)
}

export function setError(errorFn: (e: Error2) => void) {
  error = errorFn
}

export function getErrorFn() {
  return error
}

export function standardizeError(e: Error) {
  if (e instanceof Error2) {
    return e
  } else {
    return new Error2('Unhandled error', { wrappedError: e })
  }
}

export class AlreadyHandled extends Error {
  name = 'Handled error'

  alreadyHandled = true
}

export function useErrorHandling(handleError: (e: Error2) => void) {
  const handleErrorStable = useRef(handleError).current

  useEffect(() => {
    setError(handleErrorStable)
    const errorHandler = (ev: any) => {
      ev.stopPropagation()
      if (ev.error.message !== 'A network error occurred.') {
        if (!(ev.error instanceof AlreadyHandled)) {
          handleErrorStable(standardizeError(ev.error))
        }
      }
    }
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [handleErrorStable])
}
