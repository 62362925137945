import { ReactNode, createContext, useContext, useState, useMemo } from 'react'
import { Setter } from 'utils/types/Setter'

type DrawerContextType = {
  setTopDrawerWidth: Setter<number | null>
  topDrawerWidth: number | null
}

const Ctx = createContext<DrawerContextType | undefined>(undefined)

export function useDrawerContext() {
  const ctx = useContext(Ctx)
  if (ctx === undefined) throw new Error('Expected a Ctx')
  return ctx
}

type DrawerContextProps = {
  children: ReactNode
}

export function DrawerContext({ children }: DrawerContextProps) {
  const [topDrawerWidth, setTopDrawerWidth] = useState<number | null>(null)
  const contextValue = useMemo(() => ({ topDrawerWidth, setTopDrawerWidth }), [topDrawerWidth])

  return <Ctx.Provider value={contextValue}>{children}</Ctx.Provider>
}

type RootProps = {
  rootId?: string
}

export function DrawerRoot({ rootId = 'mos-drawer-root' }: RootProps) {
  return <div id={rootId} className="absolute inset-0 pointer-events-none overflow-hidden" />
}
