import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function ArrowTopRight({
  width,
  height = 13,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.66663 2.49996H2.99996C2.26358 2.49996 1.66663 3.09691 1.66663 3.83329V10.5C1.66663 11.2363 2.26358 11.8333 2.99996 11.8333H9.66663C10.403 11.8333 11 11.2363 11 10.5V7.83329M8.33329 1.16663H12.3333M12.3333 1.16663V5.16663M12.3333 1.16663L5.66663 7.83329"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
