/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QBPushRequestSkipReason =
  (typeof QBPushRequestSkipReason)[keyof typeof QBPushRequestSkipReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QBPushRequestSkipReason = {
  no_bank_ledger_account: 'no_bank_ledger_account',
  line_items_missing_cost_code: 'line_items_missing_cost_code',
  expense_card_ledger_account_not_linked: 'expense_card_ledger_account_not_linked',
  no_payment_source: 'no_payment_source',
  project_push_block: 'project_push_block',
  instance_push_block: 'instance_push_block',
  parent_not_linked: 'parent_not_linked',
  not_wanted: 'not_wanted',
  null: null,
} as const
