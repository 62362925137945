import { createContext, useContext } from 'react'
import { Colorset } from 'compass-local/utils/colorset'

export type TContext = {
  topDrawerWidth?: number | null
  setTopDrawerWidth?: (newWidth: number | null) => void
  uploadFile?: (file: File) => Promise<string>
  primaryButtonColors?: Colorset
  secondaryButtonColors?: Colorset
  primaryMenuButtonColors?: Colorset
  primaryLinkColors?: Colorset
  shouldHardNavigate?: () => boolean
}

export const Context = createContext<TContext>({})

export function useCompassContext() {
  return useContext(Context)
}
