import { BadgeUtils } from 'compass/data/Badge/utils'
import { ButtonUtils } from 'compass/data/Button/utils'
import { InputUtils } from 'compass/input/utils'

export const InputBaseThin = {
  name: 'thin',
  padding: 'px-2 py-2',
  height: 17,
}

export const BannerGold = {
  primary: 'bg-th-gold-3',
  secondary: 'bg-th-gold-3',
  text: 'text-th-gold-1',
}

export const BannerWarning = {
  primary: 'bg-th-yellow-light1',
  secondary: 'text-th-orange-light1',
  text: 'text-th-text',
}

export const BannerRejection = {
  primary: 'bg-th-orange-light2',
  secondary: 'text-th-red-warning',
  text: 'text-th-text',
}

export const ButtonThemeDefault: ButtonUtils.Theme = {
  bgColor: 'bg-th-orange-beam',
  textColor: 'text-th-bg-white',
  iconColor: 'text-th-bg-white',
  borderColor: 'outline-th-orange-dark1',
  borderWidth: 1,
  disabledBgColor: 'bg-th-orange-beam',
  disabledTextColor: 'text-th-bg-white',
  disabledIconColor: 'text-th-bg-white',
  disabledBorderColor: 'outline-th-orange-dark1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-orange-dark1',
  hoverTextColor: 'hover:text-th-bg-white',
  hoverIconColor: 'hover:text-th-bg-white',
  hoverBorderColor: 'hover:outline-th-orange-dark1',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeLight: ButtonUtils.Theme = {
  bgColor: 'bg-th-bg-white',
  textColor: 'text-th-orange-dark1',
  iconColor: 'text-th-orange-dark1',
  borderColor: 'outline-th-orange-light1',
  borderWidth: 2,
  disabledBgColor: 'bg-th-coolgrey-3',
  disabledTextColor: 'text-th-orange-dark1',
  disabledIconColor: 'text-th-orange-dark1',
  disabledBorderColor: 'outline-th-orange-light1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-bg-white',
  hoverTextColor: 'hover:text-th-orange-dark1',
  hoverIconColor: 'hover:text-th-orange-dark1',
  hoverBorderColor: 'hover:outline-th-orange-light1',
  hoverShadow: 'hover:shadow-md',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeTextOrange: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-orange-dark1',
  iconColor: 'text-th-orange-dark1',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-orange-dark1',
  disabledIconColor: 'text-th-orange-dark1',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-orange-dark1',
  hoverIconColor: 'hover:text-th-orange-dark1',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'small',
  variant: 'secondary',
}

export const ButtonThemeTextDark: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const ButtonThemeTextDarkBordered: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-brown-2',
  borderWidth: 2,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-brown-2',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-brown-2',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const ButtonThemeTextDarkSmall: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-brown-1',
  iconColor: 'text-th-brown-2',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-brown-1',
  disabledIconColor: 'text-th-brown-2',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-brown-1',
  hoverIconColor: 'hover:text-th-brown-2',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'small',
  variant: 'secondary',
}

export const ButtonThemeGold: ButtonUtils.Theme = {
  bgColor: 'bg-th-gold-2',
  textColor: 'text-white',
  iconColor: 'text-white',
  borderColor: 'outline-th-gold-1',
  borderWidth: 1,
  disabledBgColor: 'bg-th-gold-2',
  disabledTextColor: 'text-white',
  disabledIconColor: 'text-white',
  disabledBorderColor: 'outline-th-gold-1',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-th-gold-1',
  hoverTextColor: 'hover:text-white',
  hoverIconColor: 'hover:text-white',
  hoverBorderColor: 'hover:outline-th-gold-1',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'primary',
}

export const ButtonThemeTextRed: ButtonUtils.Theme = {
  bgColor: 'bg-transparent',
  textColor: 'text-th-red-warning',
  iconColor: 'text-th-red-warning',
  borderColor: 'outline-transparent',
  borderWidth: 0,
  disabledBgColor: 'bg-transparent',
  disabledTextColor: 'text-th-red-warning',
  disabledIconColor: 'text-th-red-warning',
  disabledBorderColor: 'outline-transparent',
  disabledOpacity: 'opacity-70',
  hoverBgColor: 'hover:bg-transparent',
  hoverTextColor: 'hover:text-th-red-warning',
  hoverIconColor: 'hover:text-th-red-warning',
  hoverBorderColor: 'hover:outline-transparent',
  hoverShadow: 'hover:shadow-none',
  size: 'large',
  variant: 'secondary',
}

export const BadgeThemeLightGray: BadgeUtils.Theme = {
  bgColor: 'bg-th-coolgrey-3',
  textColor: 'text-th-coolgrey-1',
}

export const BadgeThemeDarkGray: BadgeUtils.Theme = {
  bgColor: 'bg-th-coolgrey-1',
  textColor: 'text-white',
}

export const BadgeThemeLightGreen: BadgeUtils.Theme = {
  bgColor: 'bg-th-green-light1',
  textColor: 'text-th-green-dark1',
}

export const BadgeThemeDarkGreen: BadgeUtils.Theme = {
  bgColor: 'bg-th-green-dark1',
  textColor: 'text-white',
}

export const BadgeThemeLightBlue: BadgeUtils.Theme = {
  bgColor: 'bg-th-blue-light1',
  textColor: 'text-th-blue-2',
}

export const BadgeThemeLightYellow: BadgeUtils.Theme = {
  bgColor: 'bg-th-yellow-light1',
  textColor: 'text-th-orange-dark1',
}

export const BadgeThemeLightOrange: BadgeUtils.Theme = {
  bgColor: 'bg-th-orange-light2',
  textColor: 'text-th-red-warning',
}

export const BadgeThemeLightRed: BadgeUtils.Theme = {
  bgColor: 'bg-th-red-light1',
  textColor: 'text-th-red-warning',
}

export const InputThemeNone: InputUtils.Theme = {
  fixedHeight: 40,
  textColor: 'text-th-text',
  bgColor: 'bg-white',
  borderColor: 'outline-th-warmgrey-1',
  borderWidth: 0,
  disabledTextColor: 'text-th-text-disabled',
  disabledBorderColor: 'outline-th-warmgrey-1',
  disabledBgColor: 'bg-th-coolgrey-3',
  focusedBorderColor: 'outline-th-warmgrey-1',
  focusedBorderWidth: 0,
  focusedShadow: 'shadow-none',
  hoverBorderColor: 'hover:outline-th-warmgrey-1',
  hoverBorderWidth: 0,
  annotationStyle: 'none',
}

export const InputThemeThin: InputUtils.Theme = {
  fixedHeight: 39,
  textColor: 'text-th-text',
  bgColor: 'bg-white',
  borderColor: 'outline-th-warmgrey-1',
  borderWidth: 0,
  disabledTextColor: 'text-th-text-disabled',
  disabledBorderColor: 'outline-th-warmgrey-1',
  disabledBgColor: 'bg-th-coolgrey-3',
  focusedBorderColor: 'outline-th-warmgrey-1',
  focusedBorderWidth: 3,
  focusedShadow: 'shadow-none',
  hoverBorderColor: 'hover:outline-th-warmgrey-1',
  hoverBorderWidth: 1,
  annotationStyle: 'border-only',
}
