import { Cb, Q } from 'cb'
import { MSForm } from 'utils/form'
import { definePageSpec, pageContext } from 'utils/form-input/multi-page-component'

export namespace OnboardAccountForm2Utils {
  export const fc = MSForm.Controller({
    useQueries: () =>
      Q.group({
        employeeProfile: Cb.useListEmployeeProfiles({ select: Q.opt }),
        businessProfile: Cb.useListEmployeeProfiles({ select: Q.opt }),
      }),
  })

  export const pageSpec = {
    business: pageContext(),
    owners: pageContext(),
    'bank-account': pageContext(),
  }
  export const pageManager = definePageSpec(pageSpec, { id: 'business' })
}
