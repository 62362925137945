import { Cb, Q } from 'cb'
import { unreachable } from 'msutils/misc'
import { ClosableLayout } from 'utils/ClosableLayout'
import { OnboardAccountForm2Utils as Utils } from '../utils'
import BankAccountPage from './BankAccountPage'
import BusinessPage from './BusinessPage'
import OwnersPage from './OwnersPage'

const OnboardingInput = Utils.pageManager.wrap(() => {
  const { page } = Utils.pageManager.useContext()

  switch (page.id) {
    case 'business':
      return <BusinessPage />
    case 'owners':
      return <OwnersPage />
    case 'bank-account':
      return <BankAccountPage />
    default:
      return unreachable(page)
  }
})

const fc = ClosableLayout.Controller((sz) => (sz === 'sm' ? 'drawer' : 'fsmodal'), {
  useQueries: () =>
    Q.group({
      employeeProfileOpt: Cb.useListEmployeeProfiles({ select: Q.opt }),
      businessProfileOpt: Cb.useListBusinessProfiles({ select: Q.opt }),
    }),
})

export default fc.Form(() => {
  const { employeeProfileOpt, businessProfileOpt } = fc.useContext()
  return (
    <OnboardingInput
      initialPage={{
        id: employeeProfileOpt ? 'bank-account' : businessProfileOpt ? 'owners' : 'business',
      }}
    />
  )
})
