import { FC, useCallback } from 'react'
import { MSError } from 'msutils/error'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from '../ErrorPage'

type ConvertErrorProps = {
  error: any
  name: string
}
function ConvertError({ error, name }: ConvertErrorProps) {
  const merror =
    error instanceof MSError
      ? error
      : new MSError({ report: error?.message, type: `[${name}] unknown` })

  return <ErrorPage error={merror} />
}

export default function withBoundary<T extends object>(Component: FC<T>, name: string) {
  return function Inner(props: T) {
    const fn = useCallback(
      ({ error }: { error: any }) => <ConvertError error={error} name={name} />,
      [],
    )

    return (
      <ErrorBoundary fallbackRender={fn}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
