/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceTypeFreeFormInvoiceType =
  (typeof InvoiceTypeFreeFormInvoiceType)[keyof typeof InvoiceTypeFreeFormInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTypeFreeFormInvoiceType = {
  free_form: 'free_form',
} as const
