import { Cell, CellField } from './base'
import { FieldError } from './utils'

type Choice<T extends {}, V> = CellField<T | null, V> & {
  required: () => Choice<T, NonNullable<V>>
}

type ChoiceProps<T, TReq extends boolean> = {
  required?: TReq
  initValue?: T
  errorKey?: string
}

function ChoiceInner<T extends {}, TReq extends boolean>(
  props?: ChoiceProps<T, TReq>,
): Choice<T, TReq extends true ? T : T | null> {
  const validate: any = (x: T | null) => {
    if (props?.required && x === null) {
      throw new FieldError('Required')
    } else {
      return x
    }
  }
  return {
    ...Cell({
      initValue: props?.initValue ?? null,
      validate,
      errorKey: props?.errorKey,
    }),
    required: () => ChoiceInner({ ...props, required: true }),
  }
}

export function Choice<T extends {}>(props?: Omit<ChoiceProps<T, false>, 'required'>) {
  return ChoiceInner(props)
}
