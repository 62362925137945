import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Cb, setAuthToken } from 'cb'
import { Module } from 'modules/routes'
import { useAnalyticsContext } from 'root/global'
import useNotFirstRender from 'utils/useNotFirstRender'
import { Link2Utils } from 'compass-local/Link2'
import { useCurrentPath } from 'utils/router'
import * as RootUtils from '../utils'

type TAuthContext = {
  authToken: string
  authType: 'impersonator' | 'default'
  logout: () => void
}

const AuthContext = createContext<TAuthContext | undefined>(undefined)

type AuthProviderProps = TAuthContext & {
  children: ReactNode
}

function AuthProvider(props: AuthProviderProps) {
  return <AuthContext.Provider value={props}>{props.children}</AuthContext.Provider>
}

export function useAuthContext() {
  const ctx = useContext(AuthContext)
  if (ctx === undefined) throw new Error('Auth context must be used within an Auth provider')
  return ctx
}

type AuthGateProps = {
  children: ReactNode
}

export function AuthGate({ children }: AuthGateProps) {
  const [auth, setAuth] = RootUtils.useAuth()
  const { deIdentify } = useAnalyticsContext()
  const asPath = useCurrentPath()
  const replaceRoute = Link2Utils.useReplace()
  const qc = useQueryClient()
  const createLogout = Cb.useCreateLogoutV2Hook()
  const notFirstRender = useNotFirstRender()

  useEffect(() => {
    if (auth) setAuthToken(auth.header)
  }, [auth])

  const logout = useCallback(() => {
    if (auth) {
      createLogout({})
      setAuthToken('')
      setAuth(null, 'local')
      qc.clear()
      deIdentify()
    }
  }, [auth, createLogout, deIdentify, qc, setAuth])

  useEffect(() => {
    if (!auth) {
      replaceRoute(Module('/login').href)
      RootUtils.setDeepLink(asPath || null)
    }
  }, [auth, replaceRoute, asPath])

  if (!auth) {
    return null
  } else if (!notFirstRender) {
    return null
  } else {
    return (
      <AuthProvider authToken={auth.header} authType={auth.type ?? 'default'} logout={logout}>
        {children}
      </AuthProvider>
    )
  }
}
