import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function CircledCheckmark({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12.5C7.5913 12.5 9.11742 11.8679 10.2426 10.7426C11.3679 9.61742 12 8.0913 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5C4.4087 0.5 2.88258 1.13214 1.75736 2.25736C0.632141 3.38258 0 4.9087 0 6.5C0 8.0913 0.632141 9.61742 1.75736 10.7426C2.88258 11.8679 4.4087 12.5 6 12.5ZM8.78025 5.53025C8.91687 5.3888 8.99246 5.19935 8.99076 5.0027C8.98905 4.80605 8.91017 4.61794 8.77111 4.47889C8.63206 4.33983 8.44395 4.26095 8.2473 4.25924C8.05065 4.25754 7.8612 4.33313 7.71975 4.46975L5.25 6.9395L4.28025 5.96975C4.1388 5.83313 3.94935 5.75754 3.7527 5.75924C3.55605 5.76095 3.36794 5.83983 3.22889 5.97889C3.08983 6.11794 3.01095 6.30605 3.00924 6.5027C3.00754 6.69935 3.08313 6.8888 3.21975 7.03025L4.71975 8.53025C4.8604 8.67085 5.05113 8.74984 5.25 8.74984C5.44887 8.74984 5.6396 8.67085 5.78025 8.53025L8.78025 5.53025Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
