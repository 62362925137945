import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { Trans } from 'content'
import { useSupportContext } from 'root/global'

type Props = {
  tKey: `${string}<X>${string}</X>${string}`
}

export default function SupportLink({ tKey }: Props) {
  const { setChatOpen } = useSupportContext()
  return <Trans tKey={tKey} X={<LinkButtonDONOTUSE onClick={() => setChatOpen(true)} />} />
}
