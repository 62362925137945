import { Cb, Q } from 'cb'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { useBusinessContext } from 'root/user'

export const moduleId = 'business-settings'

export function useUrlParams() {
  return {
    //
  }
}

export function useQueries() {
  const { business } = useBusinessContext()
  const businessLogoFileMetadataQ = Cb.useListBusinessLogoEmbeddedFiles({ select: Q.opt })
  Cb.useBusinessLogoEmbeddedFileDownload(businessLogoFileMetadataQ.data?.id as string, {
    enabled: !!businessLogoFileMetadataQ.data,
  })

  return Q.group({
    businessLogoFileMetadata: businessLogoFileMetadataQ,
    externalCommunicationConfig: Cb.useRetrieveSingletonExternalCommunicationConfig(),
    generalProjectConfig: Cb.useRetrieveSingletonGeneralProjectConfig(),
    addressExactMatch: RichAddressInputUtils.useExactMatch(
      business.address_2?.raw_details?.raw_address ?? '',
    ),
  })
}
