import Icon from 'compass/data/Icon'
import Typography from 'compass/data/Typography'
import useScreenSize from 'compass/theme/useScreenSize'
import { cn } from 'msutils/classnames'
import { Action } from 'utils/actions'

type NamedIcon = typeof Icon extends (props: { name: infer X }) => any ? X : never

type Theme = {
  bgColor: `bg-${string}`
  textColor: `text-${string}`
  descriptionColor: `text-${string}`
  iconColor: `text-${string}`
  borderWidth: number
  borderColor: `outline-${string}`
  padding: number
  radius: number
  mobileRadius: number
}

const themes: Record<string, Theme> = {
  yellow: {
    bgColor: 'bg-th-yellow-light1',
    textColor: 'text-th-text',
    descriptionColor: 'text-th-text',
    iconColor: 'text-th-orange-light1',
    borderWidth: 0,
    borderColor: 'outline-black',
    padding: 16,
    radius: 4,
    mobileRadius: 0,
  },
  orange: {
    bgColor: 'bg-th-orange-light2',
    textColor: 'text-th-text',
    descriptionColor: 'text-th-text',
    iconColor: 'text-th-orange-light1',
    borderWidth: 0,
    borderColor: 'outline-black',
    padding: 20,
    radius: 4,
    mobileRadius: 0,
  },
  white: {
    bgColor: 'bg-th-bg-white',
    textColor: 'text-th-text',
    descriptionColor: 'text-th-coolgrey-1',
    iconColor: 'text-th-gold-1',
    borderWidth: 0,
    borderColor: 'outline-black',
    padding: 20,
    radius: 4,
    mobileRadius: 0,
  },
  gold: {
    bgColor: 'bg-th-gold-3',
    textColor: 'text-th-gold-1',
    descriptionColor: 'text-th-gold-1',
    iconColor: 'text-th-gold-2',
    borderWidth: 0,
    borderColor: 'outline-black',
    padding: 20,
    radius: 4,
    mobileRadius: 0,
  },
  'gold-thin': {
    bgColor: 'bg-th-gold-3',
    textColor: 'text-th-gold-1',
    descriptionColor: 'text-th-gold-1',
    iconColor: 'text-th-gold-2',
    borderWidth: 0,
    borderColor: 'outline-black',
    padding: 12,
    radius: 4,
    mobileRadius: 0,
  },
}

type Props = {
  theme: keyof typeof themes
  title: string
  icon?: NamedIcon
  description?: string
  actions?: Action.Config[]
  dismiss?: () => void
}

export function Banner({ theme: themeName, title, icon, description, actions, dismiss }: Props) {
  const sz = useScreenSize()
  const theme = themes[themeName]

  return (
    <div
      style={{
        padding: `${theme.padding}px`,
        borderRadius: sz === 'sm' ? theme.mobileRadius : theme.radius,
      }}
      className={cn(
        'flex flex-col md:flex-row gap-5 md:gap-8 md:justify-between md:items-center',
        theme.bgColor,
        theme.textColor,
      )}
    >
      <div className="flex gap-4 w-full md:w-auto justify-between md:justify-auto">
        <div className="vflex gap-1">
          <div className="flex gap-2 items-center">
            {icon && <Icon name={icon} height={sz === 'sm' ? 16 : 18} color={theme.iconColor} />}
            <Typography variant="bodybold">{title}</Typography>
          </div>
          {description && (
            <Typography variant="label" className={theme.descriptionColor}>
              {description}
            </Typography>
          )}
        </div>
        {!!dismiss && sz === 'sm' && (
          <Icon name="x" height={10} color="text-th-brown-2" onClick={dismiss} cursor="pointer" />
        )}
      </div>
      {(Action.visibleCount(actions) > 0 || (!!dismiss && sz !== 'sm')) && (
        <div className="flex flex-col md:flex-row gap-3 h-fit md:items-center">
          {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
          {sz !== 'sm' && !!dismiss && (
            <Icon name="x" height={10} color="text-th-brown-2" onClick={dismiss} cursor="pointer" />
          )}
        </div>
      )}
    </div>
  )
}
