import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { cn } from 'msutils/classnames'
import { isOldSafari } from 'navigator'
import { t } from 'content'
import Typography from 'compass/data/Typography'

function DummyViewer() {
  return (
    <div className="grow bg-th-bg-white h-[400px]">
      <Typography className="block p-2">
        {t('Please upgrade your browser in order to view PDFs.')}
      </Typography>
    </div>
  )
}

const PDFViewer =
  typeof navigator !== 'undefined' && isOldSafari()
    ? DummyViewer
    : dynamic(() => import('./components/PDFViewer'))

const FULLSCREEN = 'absolute -inset-[0.5px] border-[12px] border-white'

type Props = {
  file: File
  clickToOpen?: boolean
  className?: string
}

function PDF({ file, clickToOpen, className }: Props) {
  useEffect(() => {
    if (isOldSafari()) {
      // eslint-disable-next-line
      console.error('Outdated version of Safari', (navigator as any).sayswho)
    }
  })

  return (
    <div className={cn('relative', className)}>
      <PDFViewer file={file} />
      {clickToOpen && (
        <>
          {/* eslint-disable-next-line */}
          <a
            className={FULLSCREEN}
            target="_blank"
            href={URL.createObjectURL(file)}
            rel="noreferrer"
          />
        </>
      )}
    </div>
  )
}

export default React.memo(PDF)
