import { SpinnerCircularFixed } from 'spinners-react'
import { unreachable } from 'msutils/misc'

type Size = 'normal' | 'medium' | 'large'

type Props = {
  size?: Size
}

const getSizeFromSizeName = (sizeName: Size) => {
  switch (sizeName) {
    case 'normal':
      return 12
    case 'medium':
      return 18
    case 'large':
      return 28
    default:
      return unreachable(sizeName)
  }
}

const getThicknessFromSizeName = (sizeName: Size) => {
  switch (sizeName) {
    case 'normal':
      return 120
    case 'medium':
      return 180
    case 'large':
      return 200
    default:
      return unreachable(sizeName)
  }
}

export default function Spinner({ size = 'normal' }: Props) {
  return (
    <SpinnerCircularFixed
      style={{
        height: `${getSizeFromSizeName(size)}px`,
        width: `${getSizeFromSizeName(size)}px`,
      }}
      thickness={getThicknessFromSizeName(size)}
      speed={120}
      color="currentcolor"
      secondaryColor="transparent"
    />
  )
}
