import { useMemo, useState } from 'react'

export function useHistoryState<T>(initialValue: T) {
  const [state, setState] = useState([initialValue])
  return useMemo(
    () => ({
      state: state[state.length - 1],
      push: (newValue: T) => setState([...state, newValue]),
      replace: (newValue: T) => {
        if (state.length === 0) {
          setState([newValue])
        } else {
          setState([...state.slice(0, -1), newValue])
        }
      },
      back: state.length > 1 ? () => setState(state.slice(0, state.length - 1)) : undefined,
    }),
    [state],
  )
}
