type FormatPercentageOptions = {
  fractionDigits: number
}

export function formatPercentage(
  value: string | number,
  options?: Partial<FormatPercentageOptions>,
) {
  const fractionDigits = options?.fractionDigits ?? 0

  return `${(Number(value) * 100).toFixed(fractionDigits)}%`
}
