import { ReactNode } from 'react'
import { Info } from 'compass-local/legacy/icons'
import { Tooltip as BaseTooltip } from 'antd'

type Props = {
  message: ReactNode
  inactive?: boolean
  children?: ReactNode
}

export default function Tooltip(props: Props) {
  const children = props.children ?? (
    <span>
      <Info height={16} className="text-th-coolgrey-1 cursor-hover" />
    </span>
  )
  const { message, inactive } = props

  if (inactive) {
    return <>{props.children}</>
  } else {
    return (
      <>
        <BaseTooltip
          title={<div className="p-2 whitespace-pre-wrap">{message}</div>}
          style={{ fontWeight: 400, maxWidth: 360 }}
          mouseEnterDelay={0.04}
          mouseLeaveDelay={0.04}
          zIndex={10003}
        >
          {children}
        </BaseTooltip>
        <style>{`
.ant-tooltip {
  max-width: 360px;
}
          `}</style>
      </>
    )
  }
}
