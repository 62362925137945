import { Fragment, useState } from 'react'
import Typography from 'compass/data/Typography'
import Divider from 'compass-local/Divider'
import { MSArray } from 'msutils'
import { theme2 } from 'theme2'
import { t } from 'content'
import { useFormState } from 'utils/form-input'
import { useBusinessContext } from 'root/user'
import Button from 'compass/data/Button'
import Icon from 'compass/data/Icon'
import { Action } from 'utils/actions'
import { ClosableLayout } from 'utils/ClosableLayout'
import { OnboardAccountForm2Utils as Utils } from '../../utils'
import { OwnersPageUtils as PageUtils } from './utils'
import OwnerInputCard from './OwnerInputCard'

export default function OwnersPage() {
  const { push } = Utils.pageManager.useContext()
  const { employee } = useBusinessContext()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [firstName, lastName] = employee.full_name.split(' ', 2)
  const { state, useValidatedAction } = useFormState(PageUtils.schema, {
    initValue: {
      owners: [
        {
          firstName,
          lastName,
          email: employee.email ?? undefined,
        },
      ],
    },
  })
  const createOwnerProfiles = useValidatedAction(PageUtils.useCreateOwnerProfiles(), {
    disableSetInactiveOnSuccess: true,
  })

  const totalOwnershipPercentage = MSArray.sum(state.owners.map((x) => x.ownershipPercentage.value))

  return (
    <ClosableLayout.Body
      error={createOwnerProfiles.error?.message}
      warnOnClose={state._controller.hasChanged}
      pageName={t('Create owner profile(s)')}
      pageNumber="2 / 3"
      title={t('Activate your account')}
      actions={[
        Action.next(t('Link your bank account'), {
          isLoading: createOwnerProfiles.isLoading,
          qualify: () =>
            totalOwnershipPercentage < 0.5
              ? t('You must specify owners that own a combined 50% or more of the business.')
              : true,
          onClick: () => {
            if (totalOwnershipPercentage > 0.75) {
              createOwnerProfiles.mutateAsync(undefined, { onSuccess: () => push('bank-account') })
            } else {
              setShowConfirmation(true)
            }
          },
        }),
      ]}
      customOverlay={
        showConfirmation && (
          <div className="vflex gap-3 w-min m-auto">
            <Typography variant="bodybold">
              {t(
                'Do you confirm that there are no other individuals with ownership of 25% or more in the business?',
              )}
            </Typography>
            <div className="flex flex-col md:flex-row gap-2 items-center">
              <Button theme={theme2.ButtonThemeLight} onClick={() => setShowConfirmation(false)}>
                {t('No, I want to add more owners')}
              </Button>
              <Action.Mount
                {...Action.mutation(t('Yes, I confirm'), {
                  mutate: () =>
                    createOwnerProfiles.mutateAsync(undefined, {
                      onSuccess: () => push('bank-account'),
                      onError: () => setShowConfirmation(false),
                    }),
                })}
              />
            </div>
          </div>
        )
      }
    >
      <div className="vflex gap-2">
        <Typography variant="subtitle">{t('Owner(s) information')}</Typography>
        <Typography className="text-th-text-secondary">
          {t(
            'Please add every individual that owns more than 25% of the business. Use their full legal name that matches their government-issued ID.',
          )}
        </Typography>
      </div>
      {state.owners.map((ownerInput, i) => (
        <Fragment key={i}>
          {i !== 0 && <Divider />}
          <OwnerInputCard
            number={i + 1}
            state={ownerInput}
            totalOwnershipPercentage={totalOwnershipPercentage}
            deleteOwner={state.owners.length > 1 ? () => state.owners._controller.remove(i) : null}
          />
        </Fragment>
      ))}
      {totalOwnershipPercentage < 1 && (
        <div className="flex justify-end">
          <div className="min-w">
            <Button
              theme={theme2.ButtonThemeLight}
              icon={<Icon name="plus" />}
              onClick={() => state.owners._controller.append()}
            >
              {t('Add another owner')}
            </Button>
          </div>
        </div>
      )}
    </ClosableLayout.Body>
  )
}
