import { Cb } from 'cb'
import { useBusinessContext } from 'root/user'
import { MSInput } from 'msutils'
import { useAction } from 'actions'

export namespace GtmUtils {
  type OnboardingState = 'not-started-onboarding' | 'started-onboarding' | 'completed-onboarding'
  export function getOnboardingState(
    business: Cb.Business,
    internalAccount: Cb.TransactionAccountTypeInternalBankAccount | null,
  ): OnboardingState {
    if (!business.has_onboarding_intent) {
      return 'not-started-onboarding'
    } else if (internalAccount) {
      return 'completed-onboarding'
    } else {
      return 'started-onboarding'
    }
  }

  export function useActivateAccount() {
    const { business } = useBusinessContext()
    const updateBusiness = Cb.usePartialUpdateBusinessHook()
    const inputs = MSInput.useGroupInputState({})
    return useAction({
      trigger: () => updateBusiness(business.id, { has_onboarding_intent: true }),
      inputs,
    })
  }
}
