import { FC, ReactNode } from 'react'

type Children = { children?: ReactNode }
type ClientProps<T extends Children> = T & { passthrough?: boolean }

export default function withPassthrough<T extends Children>(Component: FC<T>): FC<ClientProps<T>> {
  return function Inner(props: ClientProps<T>) {
    return <>{props.passthrough ? props.children : <Component {...props} />}</>
  }
}
