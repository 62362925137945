import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Saw({ width, height = 31, onClick, href, tabIndex, className }: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.51302 5.44426H3.18467L3.49438 7.78202C3.55378 8.236 3.96956 8.55842 4.42354 8.49905L5.71336 8.30812L6.07823 11.0956C6.14185 11.5496 6.56191 11.872 7.01164 11.8126L8.39478 11.609L8.74691 14.3116C8.80632 14.7613 9.22634 15.088 9.68032 15.0244L11.3096 14.791L11.6532 17.4046C11.7126 17.8586 12.1284 18.1768 12.5824 18.1174L14.2201 17.884L14.5765 20.6121C14.6062 20.8327 14.7207 21.0279 14.8947 21.1637C15.0729 21.2952 15.2893 21.3546 15.5099 21.3249L17.4021 21.0788L18.1031 22.0668L17.2324 23.2002C16.7827 23.7899 16.8973 24.63 17.4828 25.0797L21.5346 28.1897C21.6067 28.2448 21.7001 28.2788 21.7891 28.2788C21.8188 28.2788 21.8443 28.2745 21.874 28.2703L24.7081 27.7102C24.827 27.6848 24.933 27.6126 24.9924 27.5066L28.0217 22.2965C29.1037 20.4339 28.6285 18.0367 26.9186 16.7257L25.0136 15.262C24.4763 14.8492 23.7281 14.9133 23.2605 15.3817L4.68659 0.883296C4.43204 0.68812 4.11383 0.60326 3.79138 0.645703C3.47317 0.68812 3.18891 0.853594 2.98949 1.10815L0.787499 3.97625C0.575361 4.25629 0.541438 4.62116 0.694171 4.93512C0.851151 5.24908 1.16086 5.44426 1.51302 5.44426ZM24.3899 18.1089C24.7379 18.0622 25.0943 18.164 25.3615 18.3932L25.5864 18.5883C26.4053 19.2884 26.5792 20.4933 25.9895 21.397L23.7111 24.8846C23.4735 25.2537 23.0832 25.4998 22.6504 25.5634L22.5062 25.5804C22.4468 25.5888 22.3916 25.5931 22.3365 25.5931C22.048 25.5931 21.7722 25.4913 21.5515 25.3046C20.6818 24.5621 20.4993 23.2808 21.1273 22.3219L23.5329 18.652C23.7281 18.3592 24.0378 18.1598 24.3899 18.1089Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
