import { useMemo } from 'react'
import useFormProps from 'utils/useFormProps'

type WarnOnCloseProps = { setInactive: () => void; warnOnClose: boolean }
type WarnOnCloseResponse = {
  warnOrSetInactive: () => void
  cancel: () => void
  showWarning: boolean
}

export function useWarnOnClose({
  setInactive,
  warnOnClose,
}: WarnOnCloseProps): WarnOnCloseResponse {
  const warningOverlayProps = useFormProps()
  const warnOrSetInactive = warnOnClose ? warningOverlayProps.setActive : setInactive

  return useMemo(
    () => ({
      warnOrSetInactive,
      showWarning: warningOverlayProps.isActive,
      cancel: warningOverlayProps.setInactive,
    }),
    [warnOrSetInactive, warningOverlayProps],
  )
}
