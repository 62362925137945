/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ChangeOrderPatchApprovalState =
  (typeof ChangeOrderPatchApprovalState)[keyof typeof ChangeOrderPatchApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeOrderPatchApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
} as const
