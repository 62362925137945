import { useEffect } from 'react'
import { CompassTypes } from 'compass-local'
import { Link2Utils } from './utils'

type Props = {
  href: CompassTypes['href']
  noReplace?: boolean
}

export default function Redirect({ href, noReplace }: Props) {
  const push = Link2Utils.usePush()
  const replace = Link2Utils.useReplace()

  useEffect(() => {
    if (window.location.href !== href) {
      if (!noReplace) replace(href)
      else push(href)
    }
  }, [href, noReplace, push, replace])
  return null
}
