import { Cell, CellField } from './base'
import { Branded, EmptyString, FieldError } from './utils'

type PhoneString = Branded<string, 'phone'>
type PhoneField<V> = CellField<string, V>

type PhoneProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validatePhone(s: string) {
  if (Number.isNaN(s) || s.length !== 10) {
    throw new Error('Phone number must be 10 numeric characters')
  }
  if (s[0] === '1') {
    throw new Error('Phone number must not start with 1')
  }
  if (s[0] === '0') {
    throw new Error('Phone number must not start with 0')
  }
  return s
}

export function Phone<TReq extends boolean = false>(
  props?: PhoneProps<TReq>,
): PhoneField<TReq extends true ? PhoneString : PhoneString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError('Required')
      } else {
        return validatePhone(x)
      }
    } else if (!x) {
      return ''
    } else {
      return validatePhone(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? '',
    validate,
    errorKey: props?.errorKey,
  })
}
