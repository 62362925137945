/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentTypeCardMethod =
  (typeof InvoicePaymentTypeCardMethod)[keyof typeof InvoicePaymentTypeCardMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentTypeCardMethod = {
  card: 'card',
} as const
