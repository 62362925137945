import { ReactNode } from 'react'
import Annotation from 'compass-local/legacy/Annotation'

type Props = {
  show: boolean
  children: ReactNode
}

export default function ErrorMessage({ show, children }: Props) {
  return <Annotation show={show} message={children ?? ''} color="red" />
}
