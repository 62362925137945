import { useState } from 'react'
import { Action } from 'utils/actions'
import Typography from 'compass/data/Typography'
import { Cb, Q } from 'cb'
import { useSupportContext } from 'root/global'
import { ConnectComponentsProvider } from '@stripe/react-connect-js'
import { loadConnectAndInitialize, StripeConnectInstance } from '@stripe/connect-js'
import { t } from 'content'
import { STRIPE_API_KEY } from 'env'
import useFormProps from 'utils/useFormProps'
import { X } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import OnboardAccountForm3 from 'components/forms/OnboardAccountForm3'
import { Module } from 'modules/routes'
import { useOnChange } from 'msutils'
import { ExpenseCardUtils } from 'features/expense-card/utils'

function useQueries(awaitingStripeResponse: boolean) {
  return Q.group({
    stripeConnectedAccountLink: Cb.useListStripeConnectedAccountLinks({
      select: Q.opt,
      refetchInterval: awaitingStripeResponse ? 5000 : undefined,
    }),
    bankAccounts: Cb.useListBankAccounts({
      params: { archived: false },
      select: Q.filter((x) => x.inbound_transfers_enabled),
    }),
    payerEnabled: Cb.useWhoAmI({ select: (data) => data.payer_enabled }),
    onboardingSurvey: Cb.useListOnboardingSurveys({ select: Q.opt }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
  })
}

type BannerProps = {
  text: string
  action?: Action.Config
  closeAction?: () => void
}

function Banner({ text, action, closeAction }: BannerProps) {
  const sz = useScreenSize()

  return (
    <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-center md:-mb-2">
      <div className="w-full max-w-[1280px] flex justify-between items-center gap-3 text-th-brown-1">
        <Typography variant={sz === 'sm' ? 'caption' : 'body'}>{text}</Typography>
        <div className="flex gap-5 md:gap-10 items-center">
          {action && <Action.Mount {...action} />}
          {closeAction && (
            <X height={12} thickness={2.2} className="cursor-pointer" onClick={closeAction} />
          )}
        </div>
      </div>
    </div>
  )
}

export default function ActivateAccountBanner() {
  const [awaitingStripeResponse, setAwaitingStripeResponse] = useState(false)
  const [submissionVersion, setSubmissionVersion] = useState<string | null>(null)
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(
    null,
  )
  const queryset = useQueries(awaitingStripeResponse)
  const onboardingFormProps = useFormProps()
  const updateOnboardingSurvey = Cb.usePartialUpdateOnboardingSurveyHook()
  const { setChatOpen } = useSupportContext()

  // Initializing Stripe is very brittle. If fetching the client secret fails
  // when it's initialized (as is the case before the business has a Stripe
  // account), it won't recover. If it's initialized more than once, it will
  // also start acting weirdly. We must load it exactly once and only once
  // the Stripe account has been created.
  useOnChange([queryset], () => {
    if (stripeConnectInstance) return
    if (queryset.status !== 'success') return
    const { stripeConnectedAccountLink } = queryset.queries
    if (!stripeConnectedAccountLink) return

    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey: STRIPE_API_KEY,
        fetchClientSecret: async () => {
          const res = await Cb.generateStripeOnboardingClientSecret({})
          return res.client_secret
        },
        appearance: {
          variables: {
            borderRadius: '4px',
            spacingUnit: '12px',
            colorPrimary: '#F87128',
            fontSizeBase: '16px',
            fontFamily: 'Inter, system-ui, sans-serif',
            buttonPrimaryColorText: '#FFFFFF',
          },
        },
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap' }],
      }),
    )
  })

  // There are a few situations where we want to more frequently poll the BE for updates:
  // 1. No connect account exists. We're waiting for the BE to create one.
  // 2. We have just submitted the form to Stripe and are waiting for the BE to
  //    get an update from Stripe.
  // 3. The BE is aware that Stripe is reviewing their information and we're
  //    waiting for additional updates from Stripe.
  useOnChange([queryset], () => {
    if (queryset.status !== 'success') return
    const { stripeConnectedAccountLink } = queryset.queries

    if (
      stripeConnectedAccountLink === null ||
      submissionVersion === stripeConnectedAccountLink.updated_at ||
      stripeConnectedAccountLink.has_requirements_pending_verification
    ) {
      setAwaitingStripeResponse(true)
    } else {
      setAwaitingStripeResponse(false)
    }
  })

  if (queryset.status !== 'success') return null

  const { payerEnabled, stripeConnectedAccountLink, bankAccounts, onboardingSurvey, cardProgram } =
    queryset.queries

  // Fail loudly to avoid possibly getting into situations where we're polling
  // the BE aggressively waiting for a Stripe account to be created.
  if (!payerEnabled) throw new Error('Activation banner mounted for user without onboarding intent')

  const beamCardAvailable = stripeConnectedAccountLink?.business_state
    ? ExpenseCardUtils.availableInState(stripeConnectedAccountLink.business_state)
    : true
  const wantsBeamCard = onboardingSurvey?.wants_beam_card ?? false
  const beamCardAppCompleted =
    cardProgram &&
    (cardProgram.status === 'approved' ||
      cardProgram.status === 'processing' ||
      cardProgram.status === 'declined' ||
      cardProgram.status === 'pending' ||
      cardProgram.status === 'closed' ||
      cardProgram.status === 'unavailable_in_state')

  return (
    <>
      {stripeConnectedAccountLink && stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <OnboardAccountForm3
            {...onboardingFormProps}
            onSuccess={() => {
              setSubmissionVersion(stripeConnectedAccountLink.updated_at)
              onboardingFormProps.setInactive()
            }}
          />
        </ConnectComponentsProvider>
      )}
      {stripeConnectedAccountLink === null ? null : submissionVersion ===
        stripeConnectedAccountLink.updated_at ? (
        <Banner
          text={t(
            "Business verification is in progress. If it's taking longer than 5 minutes, contact support.",
          )}
          action={Action.button(t('Contact support'), {
            onClick: () => setChatOpen(true),
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
        />
      ) : !stripeConnectedAccountLink.details_submitted ? (
        <Banner
          text={
            wantsBeamCard
              ? t('Validate your business information before applying for the card.')
              : t('Activate your account to send and receive payments.')
          }
          action={Action.button(wantsBeamCard ? t('Validate business') : t('Activate account'), {
            onClick: onboardingFormProps.setActive,
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
        />
      ) : stripeConnectedAccountLink.has_requirements_pending_verification ? (
        <Banner
          text={t(
            "Business verification is in progress. If it's taking longer than 5 minutes, contact support.",
          )}
          action={Action.button(t('Contact support'), {
            onClick: () => setChatOpen(true),
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
        />
      ) : stripeConnectedAccountLink.has_requirements_past_due ||
        stripeConnectedAccountLink.has_requirements_currently_due ? (
        <Banner
          text={t('Additional information is required to verify your business.')}
          action={Action.button(t('Provide additional information'), {
            onClick: onboardingFormProps.setActive,
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
        />
      ) : bankAccounts.length === 0 ? (
        <Banner
          text={t('Link your bank account to activate payments.')}
          action={Action.href(t('Link bank account'), {
            href: Module('/settings/bank-account').href,
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
        />
      ) : beamCardAvailable && wantsBeamCard && !beamCardAppCompleted ? (
        <Banner
          text={t("You're ready to apply for the Beam Card.")}
          action={Action.href(t('Start card application'), {
            href: Module('/expense-cards').href,
            theme: 'text-small',
            endIcon: ['arrow', '90'],
          })}
          closeAction={() => {
            if (onboardingSurvey) {
              updateOnboardingSurvey(onboardingSurvey.id, { wants_beam_card: false })
            }
          }}
        />
      ) : null}
    </>
  )
}
