import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Edit({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.586 1.58598C10.7705 1.39496 10.9912 1.24259 11.2352 1.13778C11.4792 1.03296 11.7416 0.977786 12.0072 0.975478C12.2728 0.973171 12.5361 1.02377 12.7819 1.12434C13.0277 1.2249 13.251 1.3734 13.4388 1.56119C13.6266 1.74897 13.7751 1.97228 13.8756 2.21807C13.9762 2.46386 14.0268 2.72722 14.0245 2.99278C14.0222 3.25834 13.967 3.52078 13.8622 3.76479C13.7574 4.0088 13.605 4.22949 13.414 4.41398L12.621 5.20698L9.793 2.37898L10.586 1.58598ZM8.379 3.79298L0 12.172V15H2.828L11.208 6.62098L8.378 3.79298H8.379Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
