import { useState } from 'react'
import { cn } from 'msutils/classnames'
import Cleave from 'cleave.js/react'
import { ErrorIcon } from 'compass-local/legacy/icons'
import InputBase, { InputBaseUtils, useInputBaseContext } from 'compass-local/InputBase'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { omitCoalesce } from 'utils/misc'
import * as TextInputUtils from '../utils'

type Props = InputProps<string> &
  InputBaseUtils.ExternalProps &
  TextInputUtils.InputTypeProps & {
    autoselectOnFocus?: boolean
    placeholder?: string
    hidden?: boolean
    autoCapitalize?: boolean
    maxLength?: number
    enableAutocomplete?: boolean
    autofocus?: boolean
    align?: 'right' | 'left'
  }

export default function TextInput(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    error,
    willUpdate,
    didUpdate,
    disabled,
    hidden,
    maxLength,
    enableAutocomplete,
    autofocus,
  } = getInputProps(props)
  const ctx = useInputBaseContext()
  const align = props.align ?? ctx?.align
  const autoselectOnFocus = ctx?.autoselectOnFocus?.(props.type ?? 'raw') ?? props.autoselectOnFocus

  const [isFocused, setIsFocused] = useState(false)
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const typeProps = TextInputUtils.getInputTypeProps(props)
  const placeholder = props.placeholder ?? typeProps?.placeholder
  const autoCapitalize = props.autoCapitalize ?? typeProps?.autoCapitalize
  const title = omitCoalesce(props.title, typeProps?.title)

  const toDisplay = typeProps?.toDisplay ?? ((x: string) => x)
  const fromDisplay = typeProps?.fromDisplay ?? ((x: string) => x)

  const updateInLifecycle = async (newValue: string) => {
    // Note: if I await unconditionally, the `update` loses some context that tracks the cursor
    // position in the input
    const processed = fromDisplay(newValue)
    if (willUpdate) await willUpdate(value, processed)
    update?.(processed)
    didUpdate?.(value, processed)
  }

  if (hidden) return null
  return (
    <InputBase
      {...props}
      title={title}
      cursorType="cursor-text"
      isFocused={document.activeElement === inputRef || isFocused}
      setFocus={() => inputRef?.focus()}
      {...(error &&
        !isFocused && {
          annotation: {
            active: true,
            message: error,
            color: 'red',
            icon: <ErrorIcon height={12} />,
          },
        })}
    >
      <div className="h-[17px] vflex items-center">
        {typeProps?.cleave ? (
          <Cleave
            htmlRef={setInputRef}
            tabIndex={0}
            className={cn('placeholder:text-th-text-hint', align === 'right' && 'text-right')}
            style={TextInputUtils.propsOverride}
            value={toDisplay(value ?? '')}
            onPaste={(e) => {
              e.preventDefault()
              e.clipboardData.items[0]?.getAsString((x) => updateInLifecycle(x))
            }}
            onChange={(e) => updateInLifecycle(e.target.rawValue)}
            onKeyDown={(e) => typeProps.onKeyDown?.(e, value, update)}
            onSelect={(e) => typeProps.onSelect?.(e, value)}
            onFocus={(e) => {
              if (autoselectOnFocus) {
                e.target.selectionStart = 0
                e.target.selectionEnd = e.target.value.length
              }
              typeProps.onFocus?.(e)
              setIsFocused(true)
              focus?.()
            }}
            onBlur={(e) => {
              typeProps.onBlur?.(e)
              setIsFocused(false)
              blur?.()
            }}
            onBeforeInput={(e) => typeProps?.onBeforeInput?.(e)}
            placeholder={placeholder}
            autoCapitalize={autoCapitalize ? 'on' : 'off'}
            disabled={disabled}
            inputMode={typeProps?.inputMode}
            maxLength={maxLength}
            type={typeProps.inputType}
            autoComplete={enableAutocomplete ? 'on' : 'off'}
            autoFocus={autofocus}
            options={typeProps.cleave}
          />
        ) : (
          <input
            ref={setInputRef}
            tabIndex={0}
            className={cn('placeholder:text-th-text-hint', align === 'right' && 'text-right')}
            style={TextInputUtils.propsOverride}
            value={toDisplay(value ?? '')}
            onChange={(e) => updateInLifecycle(e.target.value)}
            onKeyDown={(e) => typeProps?.onKeyDown?.(e, value, update)}
            onSelect={(e) => typeProps?.onSelect?.(e, value)}
            onFocus={(e) => {
              if (autoselectOnFocus) {
                e.target.selectionStart = 0
                e.target.selectionEnd = e.target.value.length
              }
              typeProps?.onFocus?.(e)
              setIsFocused(true)
              focus?.()
            }}
            onBlur={(e) => {
              typeProps?.onBlur?.(e)
              setIsFocused(false)
              blur?.()
            }}
            placeholder={placeholder}
            autoCapitalize={autoCapitalize ? 'on' : 'off'}
            inputMode={typeProps?.inputMode}
            maxLength={maxLength}
            type={typeProps?.inputType}
            autoComplete={enableAutocomplete ? 'on' : 'off'}
            autoFocus={autofocus}
            disabled={disabled}
          />
        )}
      </div>
    </InputBase>
  )
}
