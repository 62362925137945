import Spinner from 'compass-local/Spinner'
import PDF from 'compass-local/legacy/PDF'
import Image from 'compass-local/legacy/Image'

type Props = {
  file: File | null | undefined
  title: string
  clickToOpen?: boolean
  className?: string
}

export default function File({ file, title, clickToOpen, className }: Props) {
  switch (file?.type) {
    case null:
    case undefined:
      return (
        <div className="w-full h-[50vh] flex items-center justify-center shadow-md border bg-white">
          <Spinner size="large" fill className="text-th-text-secondary" />
        </div>
      )
    case 'application/pdf':
      return <PDF file={file} clickToOpen={clickToOpen} className={className} />
    case 'image/png':
    case 'image/jpeg':
      return <Image file={file} title={title} clickToOpen={clickToOpen} className={className} />
    default:
      throw new Error(`Cannot render unsupported file type: ${file?.type}`)
  }
}
