import { InputHTMLAttributes, useCallback, useMemo, useRef, useState } from 'react'
import { DatePicker } from 'antd'
import { ErrorIcon } from 'compass-local/legacy/icons'
import InputBase, { InputBaseUtils } from 'compass-local/InputBase'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { t } from 'content'
import { MSDate } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import Cleave from 'cleave.js/react'
import { consoleError } from 'utils/console'
import * as DateInput2Utils from '../utils'

const dateRegex = /\d\d\/\d\d\/\d\d\d\d/
const dateCleave = {
  numericOnly: true,
  blocks: [2, 2, 4],
  delimiter: '/',
}

type Props = InputProps<MSDate.Date | null> &
  InputBaseUtils.ExternalProps & {
    placeholder?: string | MSDate.Date
    pickerDefault?: MSDate.Date
    hidden?: boolean
    min?: string | MSDate.Date
    max?: string | MSDate.Date
    disableWeekends?: boolean
  }

export default function DateInput(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    error,
    hidden,
    min,
    max,
    disableWeekends,
    willUpdate,
    didUpdate,
    disabled,
    title = t('Date'),
    placeholder,
    pickerDefault,
    base,
  } = getInputProps(props)
  const inputRef = useRef<DateInput2Utils.DateInputRef & any>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const sz = useScreenSize()

  const minDate = useMemo(() => (typeof min === 'string' ? MSDate.date(min) : min), [min])
  const maxDate = useMemo(() => (typeof max === 'string' ? MSDate.date(max) : max), [max])
  const shouldDisableDate = useCallback(
    (dateWithTime: MSDate.Date) => {
      const date = MSDate.removeTime(dateWithTime)
      if (minDate && date < minDate) return true
      if (maxDate && date > maxDate) return true
      if (disableWeekends && (date.day() === 0 || date.day() === 6)) return true
      return false
    },
    [minDate, maxDate, disableWeekends],
  )
  const [isFocused, setIsFocused] = useState(false)

  const updateInLifecycle = async (newValue: MSDate.Date | null) => {
    if (willUpdate) await willUpdate(value, newValue)
    update?.(newValue)
    didUpdate?.(value, newValue)
  }

  const DateTextInput = useCallback(
    (props2: InputHTMLAttributes<HTMLInputElement>) => {
      const onChange = (ev: any) => {
        props2.onChange?.(ev)
        if (dateRegex.test(ev.target.value)) {
          try {
            const date = MSDate.date(ev.target.value)
            if (date.isValid()) update?.(date)
          } catch (e: any) {
            consoleError(`Invalid date: ${e.message}`)
          }
        }
      }

      return (
        <Cleave
          value={props2.value}
          onBlur={props2.onBlur}
          onFocus={props2.onFocus}
          onMouseDown={props2.onMouseDown}
          onKeyDown={props2.onKeyDown}
          title={props2.title}
          onChange={onChange}
          options={dateCleave}
        />
      )
    },
    [update],
  )

  const padding = base?.name === 'thin' ? 6 : 12

  if (hidden) return null
  return (
    <InputBase
      {...props}
      title={title}
      cursorType="cursor-text"
      isFocused={!!containerRef?.contains(document.activeElement) || isFocused}
      setFocus={() => inputRef.current?.focus()}
      {...(error &&
        !isFocused && {
          annotation: {
            active: true,
            message: error,
            color: 'red',
            icon: <ErrorIcon height={12} />,
          },
        })}
      base={{ padding: 'p-0' }}
    >
      <div ref={setContainerRef}>
        <DatePicker
          value={value}
          onChange={updateInLifecycle}
          defaultValue={pickerDefault}
          defaultPickerValue={pickerDefault}
          popupClassName="z-[10001]"
          ref={inputRef}
          onFocus={() => {
            focus?.()
            setIsFocused(true)
          }}
          onBlur={() => {
            blur?.()
            setIsFocused(false)
          }}
          onKeyDown={(e) => {
            const input = containerRef?.getElementsByTagName('input').item(0)
            if (input?.value.length === 1 && e.key === 'Backspace') {
              // i.e., if it's about to become length === 0
              updateInLifecycle(null)
            }
          }}
          changeOnBlur
          format="MM/DD/YYYY"
          inputRender={DateTextInput}
          bordered={false}
          disabledDate={shouldDisableDate}
          disabled={disabled}
          inputReadOnly={sz === 'sm'}
          placeholder={
            placeholder
              ? typeof placeholder === 'string'
                ? placeholder
                : MSDate.format(placeholder, { form: 'short' })
              : undefined
          }
          style={{
            border: 0,
            width: '100%',
            fontWeight: 500,
          }}
        />
        <style>
          {`
          .ant-picker {
            padding: 0;
          }
          .ant-picker .ant-picker-input {
            padding: 0 ${padding}px 0 0;
          }
          .ant-picker .ant-picker-input > input {
            padding: ${padding}px ${padding}px;
          }
          .ant-picker .ant-picker-input .ant-picker-clear {
            right: ${padding}px;
          }
          `}
        </style>
      </div>
    </InputBase>
  )
}
