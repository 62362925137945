/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoiceListRowsOrdering =
  (typeof ListInvoiceListRowsOrdering)[keyof typeof ListInvoiceListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoiceListRowsOrdering = {
  due_date: 'due_date',
  '-due_date': '-due_date',
  date: 'date',
  '-date': '-date',
  payer: 'payer',
  '-payer': '-payer',
  payment_amount: 'payment_amount',
  '-payment_amount': '-payment_amount',
  project_name: 'project_name',
  '-project_name': '-project_name',
} as const
