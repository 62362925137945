import { ReactNode } from 'react'
import { Trans, t } from 'content'
import Typography from 'compass/data/Typography'
import FileInput from 'compass-local/FileInput'
import Card from 'compass/layout/Card'
import { X } from 'compass-local/legacy/icons'
import DateInput from 'compass-local/DateInput'
import TextInput from 'compass-local/TextInput'
import { F, MSDate } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { formatPercentage } from 'utils/percentage'
import RichAddressInput from 'components/inputs/RichAddressInput'
import Tooltip from 'compass-local/Tooltip'
import { OwnersPageUtils as Utils } from './utils'

function AllowedDocumentTooltip({ children }: { children?: ReactNode }) {
  return (
    <Tooltip
      message={
        <div className="vflex gap-2">
          {t(
            'Must be a color image of the front of the document. Any of the following documents are supported:',
          )}
          <ul className="list-disc list-inside pl-2">
            <li>{t('Passport')}</li>
            <li>{t('Passport card')}</li>
            <li>{t('Driver license')}</li>
            <li>{t('State issued ID card')}</li>
            <li>{t('Resident permit ID / U.S. Green Card')}</li>
            <li>{t('Border crossing card')}</li>
            <li>{t('Child ID card')}</li>
            <li>{t('NYC card')}</li>
            <li>{t('U.S. visa card')}</li>
          </ul>
        </div>
      }
    >
      <div className="text-th-text font-medium cursor-pointer">{children}</div>
    </Tooltip>
  )
}

const MaxAge = 120
const MinAge = 18

type Props = {
  state: F.Input<typeof Utils.schema>['owners'][number]
  totalOwnershipPercentage: number
  deleteOwner: null | (() => void)
  number: number
}

export default function OwnerInputCard({ state, deleteOwner, totalOwnershipPercentage }: Props) {
  const sz = useScreenSize()

  return (
    <Card
      variant={sz === 'sm' ? 'white' : undefined}
      className="vflex gap-3"
      disablePadding={sz === 'sm'}
    >
      <div className="flex justify-end">
        {deleteOwner && (
          <X height={16} onClick={deleteOwner} className="text-th-coolgrey-1 cursor-pointer" />
        )}
      </div>
      <TextInput
        type="name"
        title={t('First name')}
        subtitle={t('Use the legal name that matches government-issued IDs.')}
        {...F.props(state.firstName)}
      />
      <TextInput type="name" title={t('Last name')} {...F.props(state.lastName)} />
      <TextInput type="email" {...F.props(state.email)} />
      <TextInput
        {...F.props(state.ownershipPercentage)}
        {...(totalOwnershipPercentage > 1 && {
          error: t('Ownership percentages add up to {{ X }}', {
            X: formatPercentage(totalOwnershipPercentage),
          }),
        })}
        type="percent"
        maximum={1}
        title={t('What percentage of the business does this person own?')}
        placeholder={t('100%')}
      />
      <RichAddressInput state={state.address} title={t('Home address')} />
      <DateInput
        title={t('Date of birth')}
        placeholder="08/25/1980"
        pickerDefault={MSDate.date('08/25/1980')}
        min={MSDate.date().subtract(MaxAge, 'year')}
        max={MSDate.date().subtract(MinAge, 'year')}
        {...F.props(state.birthDate)}
      />
      <TextInput title={t('SSN')} {...Utils.SsnInputProps} {...F.props(state.ssn)} />
      <TextInput title={t('Job title')} placeholder={t('Owner')} {...F.props(state.title)} />
      <TextInput type="phone" {...F.props(state.phone)} />
      <FileInput
        settings={{ maxFiles: 1 }}
        required
        title={
          <div className="vflex gap-2">
            <Typography variant="label">{t('Upload an identity verification document')}</Typography>
            <Typography variant="label" className="flex gap-1 text-th-text-secondary">
              <Trans
                tKey="This can be a driver's license, passport, or other government-issued ID. <X>See full list</X>"
                X={<AllowedDocumentTooltip />}
              />
            </Typography>
          </div>
        }
        {...F.props(state.identityVerificationFile)}
      />
    </Card>
  )
}
