/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePermissionApprovalActionsApproveNotAllowedReason =
  (typeof InvoicePermissionApprovalActionsApproveNotAllowedReason)[keyof typeof InvoicePermissionApprovalActionsApproveNotAllowedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePermissionApprovalActionsApproveNotAllowedReason = {
  not_payer: 'not_payer',
  not_payee: 'not_payee',
  not_creator: 'not_creator',
  employee_role_not_allowed: 'employee_role_not_allowed',
  blocked_by_approval_rule: 'blocked_by_approval_rule',
  is_markup_source: 'is_markup_source',
  payment_exists: 'payment_exists',
  another_invoice_with_same_contract_item_exists: 'another_invoice_with_same_contract_item_exists',
  has_retainage: 'has_retainage',
  not_approved: 'not_approved',
  not_pending: 'not_pending',
  not_rejected: 'not_rejected',
  null: null,
} as const
