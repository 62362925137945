import { ReactNode, useState } from 'react'
import Image from 'next/image'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Module, RawRoute } from 'modules/routes'
import { useEffectOnce } from 'utils/useEffectOnce'
import Link2, { Link2Utils } from 'compass-local/Link2'
import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { t, Trans } from 'content'
import EmailVerificationGraphic from 'assets/EmailVerificationGraphic.svg'
import { Cb } from 'cb'
import Modal from 'compass-local/Modal'
import { ENVIRONMENT } from 'env'
import { useMutation } from '@tanstack/react-query'
import { MSDate } from 'msutils'
import { useCurrentPath, useQueryParams } from 'utils/router'
import { Action } from 'utils/actions'
import SupportLink from 'components/misc/SupportLink'
import * as RootUtils from './utils'
import { useBusinessContext } from './user'

type UnauthedGateProps = {
  children: ReactNode
}

export function UnauthedGate({ children }: UnauthedGateProps) {
  const [auth] = RootUtils.useAuth()
  const push = Link2Utils.usePush()

  useEffectOnce(() => {
    const deepLink = RootUtils.getDeepLink()
    if (auth) push(deepLink ? RawRoute(deepLink) : Module('/').href)
  })

  if (auth) return null
  return <>{children}</>
}

type WaitForLaunchdarklyProps = {
  children: ReactNode
}

// eslint-disable-next-line
function WaitForLaunchdarkly({ children }: WaitForLaunchdarklyProps) {
  const ldClient = useLDClient()
  const [forceUnblock, setForceUnblock] = useState(false)
  useEffectOnce(() => {
    setTimeout(() => setForceUnblock(true), 200)
  })

  if (!forceUnblock && (!ldClient || ldClient.getContext?.()?.anonymous)) {
    return null
  }

  return <>{children}</>
}

export function Footer() {
  return (
    <div className="flex justify-center pb-4">
      <Typography variant="label" className="text-slate-400 text-center">
        <Trans
          tKey="© Beam Finance Inc. · <X1>Privacy</X1> · <X2>Terms</X2>"
          X1={<Link2 href="https://www.trybeam.com/privacy-policy" external ui="lightgrey" />}
          X2={<Link2 href="https://www.trybeam.com/terms-of-use" external ui="lightgrey" />}
        />
      </Typography>
    </div>
  )
}

export function Utm() {
  const { utm_content: utmContent } = useQueryParams()
  const trackEmailLinkClicked = Cb.useTrackEmailLinkClickedHook()

  useEffectOnce(() => {
    if (utmContent !== undefined) {
      trackEmailLinkClicked({
        tracking_token: utmContent as string,
        clicked_at: MSDate.iso(MSDate.date()),
      })
    }
  })

  return <></>
}

const EmailVerificationModal = Modal.asModal(
  () => {
    const { user, logout } = useBusinessContext()
    const createEmailVerificationAttempt = Cb.useCreateEmailVerificationAttemptHook()
    const mutation = useMutation({
      mutationFn: () => createEmailVerificationAttempt({ email: user.email ?? '' }),
    })

    return (
      <Modal.Body title="" setInactive={() => undefined} noHeader>
        <div className="vflex grow justify-center">
          <div className="vflex items-center justify-center gap-10">
            <Image src={EmailVerificationGraphic} alt="Email" />
            <div className="vflex gap-5 items-center">
              <Typography variant="drawerheader">{t('Please confirm your email')}</Typography>
              <Typography className="text-center">
                {t(
                  'Thanks for signing up. Please take a second to confirm your email address to finish account setup.',
                )}
              </Typography>
            </div>
            <div className="vflex gap-5 items-center">
              <Action.Mount
                {...Action.mutation(t('Resend email'), { mutate: mutation.mutateAsync })}
              />
              <Typography>{t('To: {{ X }}', { X: user.email ?? '--' })}</Typography>
              <Typography className="text-th-text-secondary text-center">
                <Trans
                  tKey="Wrong email address? <X>Sign out</X> and create another account."
                  X={<LinkButtonDONOTUSE onClick={logout} />}
                />
              </Typography>
            </div>
            <Typography className="text-th-text-secondary text-center">
              <SupportLink tKey="Didn't receive an email? Please check your spam folder or <X>contact support</X>." />
            </Typography>
          </div>
        </div>
      </Modal.Body>
    )
  },
  { blur: true },
)

export function EmailVerificationBlocker() {
  const { user, emailVerifications } = useBusinessContext()
  const path = useCurrentPath()

  if (ENVIRONMENT !== 'test' && path !== '/verify-email') {
    return (
      <EmailVerificationModal
        isActive={emailVerifications.length === 0 && user.email !== null}
        setInactive={() => undefined}
      />
    )
  }
  return null
}
