import { useAction } from 'actions'
import { Cb } from 'cb'
import { MSInput } from 'msutils'
import { LinkOwnerBankResult } from '../useLinkOwnerBank'

export default function useConfirmBankSetup() {
  const patchSetupIntent = Cb.usePartialUpdateSetupIntentHook()

  return useAction({
    trigger: async ({ stripe, stripePaymentMethodId, intent }: LinkOwnerBankResult) => {
      await stripe.confirmUsBankAccountSetup(intent.client_secret, {
        payment_method: stripePaymentMethodId,
      })

      return patchSetupIntent(intent.id, { payment_method_stripe_id: stripePaymentMethodId })
    },
    inputs: MSInput.useGroupInputState({}),
  })
}
