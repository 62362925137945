export namespace Pagination {
  export type Paginator = {
    page: number
    next: () => void
    previous: () => void
    first: () => void
  }

  export const PageSize = 25

  export function decrement(n: number) {
    return n - 1
  }

  export function increment(n: number) {
    return n + 1
  }
}
