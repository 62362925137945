/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBillListRowsOrdering =
  (typeof ListBillListRowsOrdering)[keyof typeof ListBillListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBillListRowsOrdering = {
  payee: 'payee',
  '-payee': '-payee',
  date: 'date',
  '-date': '-date',
  due_date: 'due_date',
  '-due_date': '-due_date',
  payment_amount: 'payment_amount',
  '-payment_amount': '-payment_amount',
  project_name: 'project_name',
  '-project_name': '-project_name',
} as const
