import { useEffect } from 'react'
import { setBaseUrl, setAuthToken } from './generator-utils'

type InitializeProps = {
  baseUrl: string
}
export function Initialize({ baseUrl }: InitializeProps) {
  useEffect(() => {
    setBaseUrl(baseUrl)
  }, [baseUrl])

  return <></>
}

type AuthenticateProps = {
  authToken: string
}
export function Authenticate({ authToken }: AuthenticateProps) {
  useEffect(() => {
    setAuthToken(authToken)
  }, [authToken])

  return <></>
}
