import { FC } from 'react'

function isInIFrame() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function onlyWhenNotInIFrame<T extends object>(Component: FC<T>) {
  return function Inner(props: T) {
    if (isInIFrame()) {
      return null
    } else {
      return <Component {...props} />
    }
  }
}
