import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { CBTestUtils } from 'cb'
import Divider from 'compass-local/Divider'
import { CB_BASE_URL } from 'env'
import BaseMetric from 'compass-local/BaseMetric'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import Codeblock from '../../components/Codeblock'
import { defineModule } from '../../utils'
import Fixtures from './Fixtures'

function CinderblockModule() {
  const qc = useQueryClient()
  useEffect(() => {
    CBTestUtils.init(CB_BASE_URL)
  }, [])

  return (
    <div className="vflex gap-3">
      <BaseMetric layout="h" k="BASE_URL" v={<Codeblock>{CB_BASE_URL}</Codeblock>} />
      <div className="w-fit">
        <Button theme={theme2.ButtonThemeTextOrange} onClick={() => qc.invalidateQueries([])}>
          Invalidate all queries
        </Button>
      </div>
      <Divider />
      <Fixtures />
    </div>
  )
}

export default defineModule({
  id: 'cinderblock',
  component: <CinderblockModule />,
})
