/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AccountTokenStatus = (typeof AccountTokenStatus)[keyof typeof AccountTokenStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountTokenStatus = {
  disabled: 'disabled',
  unconfirmed: 'unconfirmed',
  active: 'active',
} as const
