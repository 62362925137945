import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'
import Typography from 'compass/data/Typography'

type BadgeVariant = 'orange' | 'grey' | 'light-orange'

type Props = {
  variant: BadgeVariant
  children: ReactNode
}

const getClassesFromVariant = (v: BadgeVariant | undefined) => {
  switch (v) {
    case undefined:
    case 'grey':
      return 'text-white bg-th-coolgrey-1'
    case 'orange':
      return 'text-white bg-th-orange-dark1'
    case 'light-orange':
      return 'text-th-red-warning bg-th-orange-light2'
    default:
      return unreachable(v)
  }
}

export default function Badge({ variant, children }: Partial<Props>) {
  return (
    <div
      className={cn(
        'min-w-[18px] w-fit flex justify-center p-1 rounded-4 h-fit',
        getClassesFromVariant(variant),
      )}
    >
      <Typography variant="pill" className="flex gap-1 items-center truncate">
        {children}
      </Typography>
    </div>
  )
}
