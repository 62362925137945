import { useEffect, useRef } from 'react'
import type { AppProps } from 'next/app'
import 'styles/globals.css'
import 'content'
import { STORAGE_SOURCE_ORIGIN, STORAGE_TARGET_ORIGIN, REDIRECT_TO_TARGET } from 'env'
import { RedirectToTarget } from 'utils/shared-storage/Source'
import { MSDate, MSError2 } from 'msutils'
import { SentryClient } from 'lib/sentry'
import 'navigator'
import 'core-js/actual/array/at'
import Root2 from 'root'
import { unreachable } from 'msutils/misc'

const AssumeStaleAfterHours = 48

export default function App(props: AppProps) {
  const mountedAtTime = useRef(MSDate.date()).current
  MSError2.useErrorHandling((e) => {
    switch (e.level) {
      case 'error':
        SentryClient.report(e, 'error')
        break
      case 'warning':
        SentryClient.report(e, 'warning')
        break
      default:
        unreachable(e.level)
    }
  })

  useEffect(() => {
    const t = setInterval(() => {
      const currentTime = MSDate.date()
      const isStale = mountedAtTime.add(AssumeStaleAfterHours, 'hours') < currentTime

      if (isStale) {
        // eslint-disable-next-line
        console.warn('Forcing an app refresh to avoid stale client')
        window.location.reload()
      }
    }, 30000)

    return () => {
      clearInterval(t)
    }
  }, [mountedAtTime])

  return (
    <>
      {typeof window !== 'undefined' &&
        window.location.origin === STORAGE_SOURCE_ORIGIN &&
        STORAGE_TARGET_ORIGIN &&
        REDIRECT_TO_TARGET && <RedirectToTarget target={STORAGE_TARGET_ORIGIN} />}
      <Root2 {...props} />
    </>
  )
}
