import { guard, present } from '../error2'

export function format(s: string) {
  const cleaned = s.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (guard(match, present(), `Unparseable phone ${s}`)) {
    const [, match1, match2, match3] = match
    return `(${match1}) ${match2}-${match3}`
  } else {
    return s
  }
}
