/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceActionRequestType =
  (typeof InvoiceActionRequestType)[keyof typeof InvoiceActionRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceActionRequestType = {
  update: 'update',
  approve: 'approve',
} as const
