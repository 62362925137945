/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeMilestonePaymentState =
  (typeof BillTypeMilestonePaymentState)[keyof typeof BillTypeMilestonePaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeMilestonePaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
