import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Chat2({
  width,
  height = 16,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg asdf asdf asdf"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 7.72827C16 11.5943 12.4183 14.7283 8 14.7283C6.50836 14.7283 5.11208 14.3711 3.91677 13.7491L0 14.7283L1.3383 11.6056C0.492801 10.4956 0 9.16228 0 7.72827C0 3.86228 3.58172 0.728271 8 0.728271C12.4183 0.728271 16 3.86228 16 7.72827ZM5 6.72827H3V8.72827H5V6.72827ZM13 6.72827H11V8.72827H13V6.72827ZM7 6.72827H9V8.72827H7V6.72827Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
