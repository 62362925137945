import { Fragment } from 'react'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'
import Link from 'compass-local/legacy/Link'
import Typography from 'compass/data/Typography'
import { CompassTypes } from 'compass-local'
import Link2 from 'compass-local/Link2'

export type BreadcrumbSegment =
  | {
      type: 'link'
      name: string
      path?: string
      path2?: CompassTypes['href']
      truncate?: boolean
    }
  | {
      type: 'label'
      name: string
      truncate?: boolean
    }

type Props = {
  segments: BreadcrumbSegment[]
}

export default function Breadcrumb({ segments }: Props) {
  return (
    <div className="flex gap-1 items-center">
      {segments.map((s, i) => (
        <Fragment key={i}>
          {s.type === 'link' ? (
            s.path2 ? (
              <Link2 href={s.path2}>
                <Typography className="text-th-brown-2 hover:opacity-90">{s.name}</Typography>
              </Link2>
            ) : (
              <Link
                variant="unstyled"
                href={s.path ?? '#'}
                className={cn(s.truncate && 'truncate')}
              >
                <Typography className="text-th-brown-2 hover:opacity-90">{s.name}</Typography>
              </Link>
            )
          ) : s.type === 'label' ? (
            <Typography
              className={cn('text-th-text-secondary cursor-default', s.truncate && 'truncate')}
            >
              {s.name}
            </Typography>
          ) : (
            unreachable(s)
          )}
          {s.type === 'link' && <Typography className="text-th-brown-2">/</Typography>}
        </Fragment>
      ))}
    </div>
  )
}
