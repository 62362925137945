import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Icon({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" transform="translate(0.5 0.603882)" />
        <circle cx="6" cy="4.10388" r="1.5" fill="currentColor" />
        <circle cx="11" cy="4.10388" r="1.5" fill="currentColor" />
        <circle cx="6" cy="8.10388" r="1.5" fill="currentColor" />
        <circle cx="11" cy="8.10388" r="1.5" fill="currentColor" />
        <circle cx="6" cy="12.1039" r="1.5" fill="currentColor" />
        <circle cx="11" cy="12.1039" r="1.5" fill="currentColor" />
      </svg>
    </IconBase>
  )
}
