/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type StripeConnectedAccountLinkTreasuryCapabilityStatus =
  (typeof StripeConnectedAccountLinkTreasuryCapabilityStatus)[keyof typeof StripeConnectedAccountLinkTreasuryCapabilityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeConnectedAccountLinkTreasuryCapabilityStatus = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
} as const
