import Typography from 'compass/data/Typography'
import { t } from 'content'
import { Module } from 'modules/routes'
import { Action } from 'utils/actions'

type Props = {
  close: () => void
}

export default function UploadCOIDocument({ close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>{t("Upload compliance documents to meet your GC's requirements.")}</Typography>
      <Action.Mount
        {...Action.href(t('Upload document'), {
          theme: 'orange-text',
          href: Module('/settings/compliance').href,
          onClick: close,
        })}
      />
    </div>
  )
}
