import { Cb } from 'cb'
import { t } from 'content'
import { Module } from 'modules/routes'
import Typography from 'compass/data/Typography'
import { Action } from 'utils/actions'

type Props = {
  details: NonNullable<Cb.TaskScheduleBillPaymentDetails>
  close: () => void
}

export default function ScheduleBillPayment({ details, close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>
        {details.has_lien_waiver
          ? t(
              '{{ X1 }} has signed the conditional lien waiver for Bill #{{ X2 }}. Schedule payment for the bill now.',
              {
                X1: details.payee_name,
                X2: details.invoice_number,
              },
            )
          : t('Schedule payment for Bill #{{ X }} now.', { X: details.invoice_number })}
      </Typography>
      <Action.Mount
        {...Action.href(t('Schedule payment'), {
          theme: 'orange-text',
          href: Module(`/bills/${details.invoice_id}`).href,
          onClick: close,
        })}
      />
    </div>
  )
}
