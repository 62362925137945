import { useMemo } from 'react'
import { fromBase64, toBase64 } from 'msutils/misc'
import { useQueryState } from 'next-usequerystate'
import { z } from 'zod'

// Note: need other random zod props in order to get value-defaulting working properly
export default function useUrlParam<K extends string, S, T extends z.ZodTypeDef, Q>(
  key: K,
  schema: z.ZodDefault<z.Schema<S, T, Q>>,
): [S, (newValue: S) => void, K] {
  const s = useQueryState(key, {
    parse: (val) => {
      try {
        const decoded = JSON.parse(fromBase64(val))
        return schema.parse(decoded)
      } catch (e: any) {
        return null
      }
    },
    serialize: (x) => toBase64(JSON.stringify(x)),
    defaultValue: schema.parse(undefined),
  })

  return useMemo(() => {
    return [...s, key] as [S, (newValue: S) => void, K]
  }, [s, key])
}
