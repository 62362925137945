import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import * as H from './generated/hooks2'
import * as Types from './generated/models'

type ListRetainageValueSetsProps =
  | {
      scheduleOfValuesItemId: string
      workCompleted: string
    }[]
  | null

export type RetainageValueSet = Types.GetRetainageValuesViewOutput & {
  schedule_of_values_item_id: string
}

type ListRetainageValueSetsResult = RetainageValueSet[]

export function useListRetainageValueSets(inputs: ListRetainageValueSetsProps) {
  const getRetainageValues = H.useGetRetainageValuesHook()

  return useQuery(
    ['pseudo', 'retainage_value_sets', inputs],
    (): Promise<ListRetainageValueSetsResult> => {
      if (inputs === null) throw new Error()
      return Promise.all(
        inputs.map(async (input) => {
          const response = await getRetainageValues({
            schedule_of_values_item_id: input.scheduleOfValuesItemId,
            work_completed: input.workCompleted || '0',
          })

          return { schedule_of_values_item_id: input.scheduleOfValuesItemId, ...response }
        }),
      )
    },
    { enabled: inputs !== null },
  )
}

export function useListRetainageValueSetsHook() {
  const getRetainageValues = H.useGetRetainageValuesHook()

  return (inputs: ListRetainageValueSetsProps): Promise<ListRetainageValueSetsResult> => {
    if (inputs === null) throw new Error()
    return Promise.all(
      inputs.map(async (input) => {
        const response = await getRetainageValues({
          schedule_of_values_item_id: input.scheduleOfValuesItemId,
          work_completed: input.workCompleted || '0',
        })

        return { schedule_of_values_item_id: input.scheduleOfValuesItemId, ...response }
      }),
    )
  }
}

export function useUploadFileHook() {
  const createFileUpload = H.useCreateFileUploadHook()
  const createFile = H.useCreateFileHook()

  return async (f: File) => {
    const uploadIntent = await createFileUpload({
      original_file_name: f.name,
      file_type: f.type,
    })

    const uploadToS3 = axios.post(
      uploadIntent.upload_url,
      // Note: S3 cares about what order the fields in the body are in...
      { ...uploadIntent.fields, file: f },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )

    const createdFileP = createFile({ file_upload_id: uploadIntent.id })
    const [, createdFile] = await Promise.all([uploadToS3, createdFileP])
    return createdFile.id
  }
}
