import { MSObject } from '../../object'
import * as Interaction from '../interaction'
import {
  BaseInputStateController,
  Factory,
  StorableInputState,
  UseStateResponse,
  ValidationResult,
} from '../types'
import { collapseLayer } from '../utils'

export type FactoryProps<TWritable, TValid, FValid> = {
  initialValue?: TWritable
  validate: (value: TValid) => FValid
}

function performValidationSafely<TStorable, TValid>(
  value: TStorable,
  validate: (x: TStorable) => TValid,
): ValidationResult<TValid> {
  try {
    return { isValid: true, validValue: validate(value), error: null }
  } catch (e: any) {
    const message = e && typeof e.message === 'string' ? e.message : 'Unknown input error'
    return { isValid: false, validValue: null, error: message }
  }
}

type Props<TWritable, TStorable, TReadable, TValid, C extends object> = {
  initialValue: TWritable
  mergeParentAndChildInitialValues?: (
    parentInitialValue: TWritable,
    childInitialValue?: TWritable,
  ) => TWritable
  validate: (writable: TStorable) => TValid
  prepareWriteForStorage: (writable: TWritable) => TStorable
  prepareStoredValueForRead: (
    storable: TStorable,
    stateProps: UseStateResponse<StorableInputState<TWritable, TStorable>>,
  ) => TReadable
  setUpController: (
    stateProps: UseStateResponse<StorableInputState<TWritable, TStorable>>,
    baseController: BaseInputStateController<TWritable, TStorable>,
  ) => BaseInputStateController<TWritable, TStorable> & C
  overrideStatus?: (storable: {
    value: TReadable
    __type: string
    status: Interaction.State
  }) => Interaction.State
  type: string
}

export function factory<TWritable, TStorable, TReadable, TValid, C extends object>({
  validate,
  initialValue,
  mergeParentAndChildInitialValues,
  prepareWriteForStorage,
  prepareStoredValueForRead,
  setUpController,
  overrideStatus,
  type,
}: Props<TWritable, TStorable, TReadable, TValid, C>): Factory<
  TWritable,
  TStorable,
  TReadable,
  TValid,
  C
> {
  const init = (initValue?: TWritable): StorableInputState<TWritable, TStorable> => {
    const mergedInitialValue =
      mergeParentAndChildInitialValues?.(initialValue, initValue) ?? initValue ?? initialValue
    return {
      value: prepareWriteForStorage(mergedInitialValue),
      initialValue: mergedInitialValue,
      status: 'clean',
    }
  }

  return {
    init,
    attemptValidation: validate,
    configure: (conf) =>
      factory({
        validate,
        initialValue: conf?.initialValue ?? initialValue,
        mergeParentAndChildInitialValues,
        prepareStoredValueForRead,
        prepareWriteForStorage,
        setUpController,
        type,
      }),
    build: ([state, setState]) => {
      const prepareUpdate = (newValue: TWritable) => ({
        ...state,
        value: prepareWriteForStorage(newValue),
        status: Interaction.transition(state.status, 'update'),
      })

      const prepareSet = (newState: StorableInputState<TWritable, TStorable>) => ({
        ...newState,
        value: newState.value,
      })

      const prepareReset = () => init(state.initialValue)
      const prepareTap = () => ({ ...state, status: Interaction.transition(state.status, 'tap') })

      const readable = prepareStoredValueForRead(state.value, [state, setState])
      return collapseLayer({
        state: {
          ...state,
          ...performValidationSafely(state.value, validate),
          ...(overrideStatus && {
            status: overrideStatus({ value: readable, __type: type, status: state.status }),
          }),
          value: readable,
        },
        controller: setUpController([state, setState], {
          update: (newValue) => setState(() => prepareUpdate(newValue)),
          set: (newState) => setState(() => prepareSet(newState)),
          reset: () => setState(prepareReset),
          focus: () =>
            setState((oldState) => ({
              ...oldState,
              status: Interaction.transition(state.status, 'focus'),
            })),
          blur: () =>
            setState((oldState) => ({
              ...oldState,
              status: Interaction.transition(state.status, 'blur'),
            })),
          tap: () => setState(prepareTap),
          prepareTap,
          __type: type,
        }),
      })
    },
  }
}

export function containsWithStatus(state: any, status: Interaction.State): boolean {
  if (state.__type === 'group') {
    return Object.values(MSObject.map(state.value, (j: any) => containsWithStatus(j, status))).some(
      (x) => x,
    )
  } else if (state.__type === 'list') {
    return state.value.some((x: any) => containsWithStatus(x, status))
  } else {
    return state.status === status
  }
}
