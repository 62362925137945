import { MSString, ToCamel as ToCamelString, ToSnake as ToSnakeString } from '../string/MSString'

export type ToCamel<T extends object> = {
  [K in keyof T as K extends string ? ToCamelString<K> : K]: T[K]
}

export type ToSnake<T extends object> = {
  [K in keyof T as K extends string ? ToSnakeString<K> : K]: T[K]
}

export type ExpandOneLayer<T> = T extends infer O ? { [K in keyof O]: O[K] } : never
export function expand<T>(val: T): ExpandOneLayer<T> {
  return val as ExpandOneLayer<T>
}

export class MSObject<T extends object> {
  private obj: T

  private constructor(obj: T) {
    this.obj = obj
  }

  private map<R extends Record<keyof T, any>>(
    map: <K extends keyof T>(item: T[K], k: K) => R[K],
  ): R {
    const result = {} as R
    // eslint-disable-next-line
    for (const key in this.obj) {
      if (Object.prototype.hasOwnProperty.call(this.obj, key)) {
        const mappedValue = map(this.obj[key], key)
        result[key] = mappedValue
      }
    }
    return result
  }

  private toCamel(): ToCamel<T> {
    const result = {} as any
    // eslint-disable-next-line
    for (const key in this.obj) {
      if (Object.prototype.hasOwnProperty.call(this.obj, key)) {
        result[MSString.toCamel(key)] = this.obj[key]
      }
    }
    return result
  }

  private toSnake(): ToSnake<T> {
    const result = {} as any
    // eslint-disable-next-line
    for (const key in this.obj) {
      if (Object.prototype.hasOwnProperty.call(this.obj, key)) {
        result[MSString.toSnake(key)] = this.obj[key]
      }
    }
    return result
  }

  static map<T extends object, R extends Record<keyof T, any>>(
    obj: T,
    map: <K extends keyof T>(item: T[K], k: K) => R[K],
  ): R {
    return new MSObject(obj).map(map)
  }

  static toCamel<T extends object>(obj: T): ToCamel<T> {
    return new MSObject(obj).toCamel()
  }

  static toSnake<T extends object>(obj: T): ToSnake<T> {
    return new MSObject(obj).toSnake()
  }

  static omit<T extends object, K extends (keyof T)[]>(
    obj: T,
    keys: K,
  ): ExpandOneLayer<Omit<T, K[number]>> {
    const result = {} as any
    // eslint-disable-next-line
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (!keys.includes(key)) {
          result[key] = obj[key]
        }
      }
    }
    return result
  }
}
