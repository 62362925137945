/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BusinessCreateAddress2Type =
  (typeof BusinessCreateAddress2Type)[keyof typeof BusinessCreateAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessCreateAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
