import { Cell, CellField } from './base'

type UnconfirmedFileUpload = {
  file: File
  uploadId: null
  isLoading: boolean
}

type ConfirmedFileUpload = {
  file: File | string
  uploadId: string
  isLoading: false
}

type FileMetadata = UnconfirmedFileUpload | ConfirmedFileUpload

type Files = CellField<FileMetadata[], ConfirmedFileUpload[]>

type FilesFieldProps = {
  required?: boolean
  initValue?: FileMetadata[]
}

export function toFilesWritable(fs: { original_file_name: string; file_id: string }[]) {
  return fs.map((f) => ({
    file: f.original_file_name,
    uploadId: f.file_id,
    isLoading: false as const,
  }))
}

export function Files(props?: FilesFieldProps): Files {
  return Cell({
    initValue: props?.initValue ?? [],
    validate: (val) => {
      if (props?.required) {
        if (val.length === 0) {
          throw new Error('Required')
        }
      }
      return val.flatMap((x) => (x.uploadId ? x : []))
    },
  })
}
