import { ReactNode } from 'react'
import { t } from 'content'
import useScreenSize from 'compass/theme/useScreenSize'
import { Action } from 'utils/actions'
import { Drawer } from './Form'

type ComponentOrDrawerProps = {
  title: string
  isActive: boolean
  setInactive: () => void
  children: ReactNode
}

export function ComponentOrDrawer({
  isActive,
  setInactive,
  title,
  children,
}: ComponentOrDrawerProps) {
  const sz = useScreenSize()

  if (sz === 'sm') {
    return (
      <Drawer
        isActive={isActive}
        setInactive={setInactive}
        title={title}
        actions={[Action.button(t('Close'), { theme: 'text', onClick: setInactive })]}
      >
        {children}
      </Drawer>
    )
  } else {
    return <>{children}</>
  }
}
