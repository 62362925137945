import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function HollowFlag({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 35.5V28.8333M5 28.8333V8.83333C5 6.99238 6.49238 5.5 8.33333 5.5H19.1667L20.8333 7.16667H35L30 17.1667L35 27.1667H20.8333L19.1667 25.5H8.33333C6.49238 25.5 5 26.9924 5 28.8333ZM20 6.33333V15.5"
          stroke="#6F6F78"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
