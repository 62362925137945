import { F } from 'msutils'

export namespace MicrodepositAmountsVerificationInputUtils {
  export const schema = F.Group({
    spec: {
      amountOne: F.Text({ required: true }),
      amountTwo: F.Text({ required: true }),
    },
  })
}
