import { FC, ReactNode, useState } from 'react'
import ComponentSwitcher from 'compass-local/legacy/ComponentSwitcher'
import BaseDrawer from 'compass-local/Drawer'
import { Modal } from 'compass-local/FullScreenModal'
import { InputBaseContext } from 'compass-local/InputBase'
import { useWarnOnClose } from './utils'
import { FormUI, FormConfig, FormProvider } from './openerContext'
import { FormBody } from './Body'

type FormProps = {
  isActive: boolean
  setInactive: () => void
  ui: FormUI
  children: ReactNode
}

function FormComponent({ isActive, setInactive, ui, children }: FormProps) {
  const [formConfig, setFormConfig] = useState<Partial<FormConfig> | null>(null)
  const formConfigWithDefaults = { size: 'small' as const, warnOnClose: false, ...formConfig }

  const warnOnCloseProps = useWarnOnClose({
    setInactive,
    warnOnClose: formConfigWithDefaults.warnOnClose,
  })

  const providerProps = {
    handle: setFormConfig,
    warnOrSetInactive: warnOnCloseProps.warnOrSetInactive,
    setInactiveWithoutWarning: setInactive,
    ui,
  }

  return (
    <FormProvider {...providerProps}>
      <ComponentSwitcher
        arg={ui}
        drawer={
          <BaseDrawer
            isActive={isActive}
            setInactive={setInactive}
            size={formConfigWithDefaults.size}
            warnOnCloseProps={warnOnCloseProps}
          >
            <InputBaseContext align="left">{children}</InputBaseContext>
          </BaseDrawer>
        }
        modal={
          <Modal isActive={isActive} setInactive={setInactive} warnOnCloseProps={warnOnCloseProps}>
            <InputBaseContext align="left">{children}</InputBaseContext>
          </Modal>
        }
      />
    </FormProvider>
  )
}

export function Form<T extends object>(Component: FC<T>, useUi: () => FormUI = () => 'drawer') {
  return function InnerF(props: T & Omit<FormProps, 'ui' | 'children'> & { ui?: FormUI }) {
    const ui = useUi()
    return (
      <FormComponent {...props} ui={props.ui ?? ui}>
        <Component {...props} />
      </FormComponent>
    )
  }
}

export const Drawer = Form(FormBody)
