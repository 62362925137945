import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function DocumentWithPlus({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9445 17H3.05561C2.58411 17 2.13193 16.8127 1.79853 16.4793C1.46513 16.1459 1.27783 15.6937 1.27783 15.2222V2.77778C1.27783 2.30628 1.46513 1.8541 1.79853 1.5207C2.13193 1.1873 2.58411 1 3.05561 1H8.02094C8.25667 1.00005 8.48273 1.09373 8.64939 1.26044L13.4618 6.07289C13.6285 6.23955 13.7222 6.4656 13.7223 6.70133V15.2222C13.7223 15.6937 13.535 16.1459 13.2016 16.4793C12.8682 16.8127 12.416 17 11.9445 17Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.27783 6V9M7.27783 9V12M7.27783 9H10.2778M7.27783 9H4.27783"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
