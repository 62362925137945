import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Flag({
  width,
  height = 10,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.800049 2.60005C0.800049 1.60594 1.60594 0.800049 2.60005 0.800049H8.60005C8.82731 0.800049 9.03507 0.92845 9.13671 1.13172C9.23834 1.33499 9.21641 1.57824 9.08005 1.76005L7.55005 3.80005L9.08005 5.84005C9.21641 6.02186 9.23834 6.26511 9.13671 6.46838C9.03507 6.67165 8.82731 6.80005 8.60005 6.80005H2.60005C2.26868 6.80005 2.00005 7.06868 2.00005 7.40005V9.20005C2.00005 9.53142 1.73142 9.80005 1.40005 9.80005C1.06868 9.80005 0.800049 9.53142 0.800049 9.20005V2.60005Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
