import Card from 'compass/layout/Card'
import { Bank } from 'compass-local/legacy/icons'
import { Cb } from 'cb'
import { t } from 'content'

type Props = {
  bankAccount: Cb.BankAccount
}

export default function BankCardView({ bankAccount }: Props) {
  return (
    <Card variant="white" className="inline-block w-full md:w-fit md:min-w-[320px]">
      <div className="flex justify-start gap-4">
        <Bank height={28} thickness={1.5} className="mt-2" />
        <div className="vflex gap-2 align-left grow">
          <div className="vflex">
            <span className="font-medium">{t('Account number')}</span>
            <span className="text-gray-400">{`••••${bankAccount.account_number_last_4}`}</span>
          </div>
          <div className="vflex">
            <span className="font-medium">{t('Routing number')}</span>
            <span className="text-gray-400">{bankAccount.routing_number}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
