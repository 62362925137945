/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceIntentCreateType =
  (typeof InvoiceIntentCreateType)[keyof typeof InvoiceIntentCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceIntentCreateType = {
  invoice: 'invoice',
  bill: 'bill',
} as const
