/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CardTransactionListRowStatus =
  (typeof CardTransactionListRowStatus)[keyof typeof CardTransactionListRowStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardTransactionListRowStatus = {
  declined: 'declined',
  pending: 'pending',
  settled: 'settled',
  voided: 'voided',
  rejected: 'rejected',
} as const
