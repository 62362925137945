import BigNumber from 'bignumber.js'

type FormatCurrencyOptions = {
  decimalStrategy: 'always2' | 'round'
  zeroPlaceholder: boolean
  placeholderNoCurrency: boolean
  sign: boolean
}

/** Deprecated - use Format.currency instead */
export function formatCurrencyDONOTUSE(
  value: number | BigNumber | string | null,
  options?: Partial<FormatCurrencyOptions>,
) {
  if (value === null) {
    return '$ --'
  }

  if (options?.zeroPlaceholder && Number(value) === 0) {
    if (options?.placeholderNoCurrency) {
      return '--'
    }
    return '$ --'
  }

  const decimalStrategy = options?.decimalStrategy ?? 'always2'

  const decimals = decimalStrategy === 'always2' ? 2 : 0
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })

    const formatted = formatter.format(Number(value))
    if (Number(value) > 0 && options?.sign) {
      return `+${formatted}`
    } else {
      return formatted
    }
  } catch (e: any) {
    // TODO: log Intl errors
    const num = Number(value)
    return `${num < 0 ? '-' : ''}$${Math.abs(num).toFixed(decimals)}`
  }
}
