import { FC } from 'react'
import { Qualified } from './types'

export type QualifiedResult<T> =
  | { type: 'qualified'; ctx: T }
  | { type: 'disabled'; ctx: null; message: string | JSX.Element }
  | { type: 'hidden' }

export function performQualify<TQualifyCtx>(
  q: () => Qualified<TQualifyCtx>,
): QualifiedResult<TQualifyCtx> {
  const qual = q()

  if (qual === null || qual === false || qual === undefined) {
    return { type: 'hidden' }
  } else if (typeof qual === 'string') {
    return { type: 'disabled', ctx: null, message: qual }
  } else if (Array.isArray(qual)) {
    return { type: 'disabled', ctx: null, message: qual[0] }
  } else {
    return { type: 'qualified', ctx: qual }
  }
}

export const noOpMount: FC<object> = () => null
