/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePatchApprovalState =
  (typeof InvoicePatchApprovalState)[keyof typeof InvoicePatchApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePatchApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  void: 'void',
} as const
