/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentCreateMethod =
  (typeof InvoicePaymentCreateMethod)[keyof typeof InvoicePaymentCreateMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentCreateMethod = {
  card: 'card',
  bank_account: 'bank_account',
  external: 'external',
} as const
