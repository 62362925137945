import { ReactNode, useCallback, useRef, useState } from 'react'
import Typography from 'compass/data/Typography'
import { AutoComplete } from 'antd'
import { ErrorIcon, Plus } from 'compass-local/legacy/icons'
import Divider from 'compass-local/Divider'
import InputBase, { InputBaseContext } from 'compass-local/InputBase'
import { SelectInputUtils, StaticSelectInput } from 'compass-local/SelectInput'
import useHtmlId from 'compass-local/utils/useHtmlId'
import { t } from 'content'
import { Address, formatAddress, State } from 'utils/address'
import { theme } from 'styles/theme'
import TextInput from 'compass-local/TextInput'
import { includesIgnoreCase } from 'utils/string'
import Annotation from 'compass-local/InputBase/Component/Annotation'
import { F } from 'msutils'
import { shouldShowError } from 'msutils/field/interaction'
import { RichAddressInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  didSelect?: (newValue: Address) => void
  disabled?: boolean
  disableExpandOnBlur?: boolean
  title?: string | null
  optional?: boolean
}

export default function RichAddressInput(props: Props) {
  const { state, disabled, didSelect, optional } = props
  const hideErrors = !!optional && !state._controller.hasChanged

  const title = props.title ?? t('Address')
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [cursorOnFooter, setCursorOnFooter] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [expanded, setExpanded] = useState(!!state.city.value || !!state.state.value)
  const inputRef = useRef<SelectInputUtils.SelectRef | null>(null)
  const id = useHtmlId()
  const predictions = Utils.useSearchAddress(state.line1.value)

  const buildMenu = useCallback(
    (baseMenu: ReactNode) => {
      return (
        <>
          <div className="w-min-w test-select-menu">{baseMenu}</div>
          {!expanded && (
            <div
              className="vflex gap-1 cursor-default"
              onMouseEnter={() => setCursorOnFooter(true)}
              onMouseLeave={() => setCursorOnFooter(false)}
            >
              <Divider />
              <div
                className="flex gap-3 items-center py-3 px-3 hover:bg-th-orange-light2 rounded-2 cursor-pointer"
                onClick={() => {
                  setExpanded(true)
                  inputRef.current?.blur()
                }}
              >
                <Plus height={14} />
                <Typography>{t('Enter address manually')}</Typography>
              </div>
            </div>
          )}
        </>
      )
    },
    [expanded],
  )

  if (state.line1.hidden) return null

  const autocomplete = (
    <InputBase
      title={title}
      cursorType="cursor-text"
      isFocused={!!containerRef?.contains(document.activeElement) ?? isFocused}
      setFocus={() => inputRef.current?.focus()}
      optional={optional}
      disabled={state.line1.disabled}
      annotationStyle={expanded ? 'border-only' : undefined}
      {...(state._controller.hasErrorWithin &&
        shouldShowError(state.line1.status) &&
        !hideErrors &&
        !isFocused && {
          annotation: {
            active: true,
            message: state.line1.error || state.city.error || state.zip.error || t('Error'),
            color: 'red',
            icon: <ErrorIcon height={12} />,
          },
        })}
    >
      <div id={id} ref={setContainerRef} className="-my-[7.5px] -mx-3 text-th-text">
        <AutoComplete
          className="w-full"
          disabled={state.line1.disabled}
          value={state.line1.value}
          onChange={state.line1.update}
          ref={inputRef}
          onFocus={() => {
            setIsFocused(true)
            state.line1.focus()
          }}
          onBlur={() => {
            if (state.line1.value.length > 0) {
              setExpanded(true)
            }
            setIsFocused(false)
            state.line1.blur()
          }}
          onSelect={(_, opt) => {
            state._controller.update(opt.metadata)
            didSelect?.(opt.metadata)
            inputRef.current?.blur()
            setExpanded(true)
          }}
          options={(predictions.data ?? []).map((x) => ({
            value: formatAddress(x),
            metadata: x,
            label: <Typography variant="bodybold">{formatAddress(x)}</Typography>,
            className: cursorOnFooter && '!bg-transparent',
          }))}
          dropdownRender={buildMenu}
          placeholder={t('Look up address')}
          popupClassName="w-min z-[10002]"
          notFoundContent={
            state.line1.value.length > 2 ? (
              <div className="w-full flex justify-center">
                <Typography className="py-2.5 text-th-text-disabled">{t('No results')}</Typography>
              </div>
            ) : undefined
          }
        />
      </div>
      <style>{`
#${id} .ant-select-selection-placeholder {
color: ${(theme?.colors as any)?.th?.text?.hint} !important;
font-weight: 500;
opacity: 1;
}

#${id} .ant-select-selector {
border: none !important;
box-shadow: none !important;
font-weight: inherit !important;
${disabled ? '' : 'color: inherit !important;'}
background: transparent !important;
}

#${id} .ant-select {
font-weight: inherit !important;
color: inherit !important;
}

#${id} .ant-select-selection-item {
font-weight: inherit !important;
color: inherit !important;
}

#${id} .ant-select-selection-search-input {
font-size: ${(theme?.fontSize as any)?.base?.at(0)};
}
      `}</style>
    </InputBase>
  )

  if (!expanded) {
    return autocomplete
  } else {
    return (
      <div className="vflex gap-2">
        <InputBaseContext base={{ rounded: '' }} annotationStyle="border-only">
          <div className="vflex">
            {autocomplete}
            <div className="-mt-[1px]">
              <TextInput
                {...F.props(state.line2)}
                placeholder={t('Apt/Suite/Unit #')}
                {...(hideErrors && { error: null })}
              />
            </div>
            <div className="flex -mt-[1px]">
              <div className="basis-[50%]">
                <TextInput
                  {...F.props(state.city)}
                  placeholder={t('City')}
                  {...(hideErrors && { error: null })}
                />
              </div>
              <div className="-ml-[1px] basis-[25%]">
                <StaticSelectInput
                  {...F.props(state.state)}
                  {...(hideErrors && { error: null })}
                  placeholder={t('State')}
                  enableSearch
                  filter={(search, key) => includesIgnoreCase(key, search)}
                  options={Object.entries(State).map(([, v]) => ({ id: v, title: v }))}
                />
              </div>
              <div className="-ml-[1px] grow basis-[25%]">
                <TextInput
                  {...F.props(state.zip)}
                  {...(hideErrors && { error: null })}
                  type="numeric"
                  maxLength={5}
                  placeholder={t('Zip')}
                />
              </div>
            </div>
          </div>
        </InputBaseContext>
        <Annotation
          active={state._controller.hasChanged && state._controller.hasErrorWithin}
          message={
            state.line1.error ||
            state.line2.error ||
            state.city.error ||
            state.zip.error ||
            state.state.error ||
            null
          }
          icon={<ErrorIcon height={12} />}
          color="red"
        />
      </div>
    )
  }
}
