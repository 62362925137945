/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceListRowOutboundSyncDetailsLatestFailureReason =
  (typeof InvoiceListRowOutboundSyncDetailsLatestFailureReason)[keyof typeof InvoiceListRowOutboundSyncDetailsLatestFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceListRowOutboundSyncDetailsLatestFailureReason = {
  line_items_missing_cost_code: 'line_items_missing_cost_code',
  null: null,
} as const
