import { loadStripe, Stripe } from '@stripe/stripe-js'
import { STRIPE_API_KEY } from 'env'
import { useBusinessContext } from 'root/user'
import { Cb } from 'cb'
import { useAction } from 'actions'
import { MSInput } from 'msutils'

export type LinkOwnerBankResult = {
  stripe: Stripe
  stripePaymentMethodId: string
  account: { accountLastFour: string; routingNumber: string }
  intent: Cb.SetupIntent
}
type Props = { businessId: string }

export default function useLinkOwnerBank({ businessId }: Props) {
  const { user } = useBusinessContext()

  const createSetupIntent = Cb.useCreateSetupIntentHook()
  const patchSetupIntent = Cb.usePartialUpdateSetupIntentHook()

  return useAction({
    trigger: async (): Promise<LinkOwnerBankResult | null> => {
      const intent = await createSetupIntent({
        payment_method_type: 'bank_account',
        stripe_account_owner_business_id: businessId,
        payment_method_owner_business_id: businessId,
        payment_method_owner_client_id: null,
        verification_method: 'automatic',
      })

      try {
        // We have to load Stripe separately instead of using the StripeContext
        // since we're acting as a connect account.
        const stripe = await loadStripe(STRIPE_API_KEY, {
          stripeAccount: intent.account_stripe_id,
        })
        if (!stripe) throw new Error('Error initializing Stripe (initialization error)')

        const { setupIntent: stripeIntent } = await stripe.collectBankAccountForSetup({
          clientSecret: intent.client_secret,
          params: {
            payment_method_type: 'us_bank_account',
            payment_method_data: {
              billing_details: {
                name: user.full_name,
              },
            },
          },
          expand: ['payment_method'],
        })
        if (!stripeIntent) {
          throw new Error('Missing SetupIntent in response from stripe')
        }
        if (!stripeIntent.payment_method || typeof stripeIntent.payment_method === 'string') {
          return null
        }

        if (stripeIntent.status === 'requires_confirmation') {
          // Note: Stripe's typing is messed up
          const account = stripeIntent.payment_method.us_bank_account as any

          if (!account || !account.last4) {
            throw new Error('Missing bank account details for confirmation')
          }

          return {
            stripe,
            stripePaymentMethodId: stripeIntent.payment_method.id,
            account: {
              accountLastFour: account.last4,
              routingNumber: account.routing_number,
            },
            intent,
          }
        } else {
          throw new Error('Unexpected result from Stripe (missing confirmation)')
        }
      } catch (e) {
        patchSetupIntent(intent.id, { abandoned: true })
        throw e
      }
    },
    inputs: MSInput.useGroupInputState({}),
  })
}
