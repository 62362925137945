import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function ShieldOutline({
  width,
  height = 8,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.00016 13.3334C6.00016 13.3334 10.6668 11.0001 10.6668 7.50008V3.41675L6.00016 1.66675L1.3335 3.41675V7.50008C1.3335 11.0001 6.00016 13.3334 6.00016 13.3334Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
