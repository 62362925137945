/* eslint-disable mosaic-js/no-coalesce-true */
import { ReactNode } from 'react'
import { InputBaseUtils } from 'compass-local/InputBase'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import SelectInput from './Component'

type Option =
  | string
  | {
      id: string
      title?: string
      label?: ReactNode
      hidden?: boolean
    }

type OptionKey<O extends Option> = O extends string ? O : O extends { id: string } ? O['id'] : never

type Props<TOptions extends readonly Option[]> = InputProps<OptionKey<TOptions[number]> | null> &
  InputBaseUtils.ExternalProps & {
    options: TOptions
    enableSearch?: boolean
    placeholder?: string
    disableSortByTitle?: boolean
    filter?: (search: string, key: OptionKey<TOptions[number]>) => boolean
  }

export default function StaticSelectInput<TOptions extends readonly Option[]>(
  props: Props<TOptions>,
) {
  const { options, enableSearch, filter } = getInputProps(props)
  const fullOptions = options.flatMap((j) => {
    if (typeof j === 'string') {
      return {
        value: j as OptionKey<TOptions[number]>,
        title: j,
        label: null,
      }
    } else if (!j.hidden) {
      return {
        title: j.title ?? j.id,
        label: j.label,
        value: j.id as OptionKey<TOptions[number]>,
      }
    } else {
      return []
    }
  })

  return (
    <SelectInput
      {...props}
      // Note: id can't be the same as any element otherwise antd gets borked -_-
      disableSearch={!enableSearch}
      getId={(x) => `id-${x}`}
      getTitle={(x) => fullOptions.find((o) => o.value === x)?.title ?? x}
      getOptionProps={(x) => x}
      options={fullOptions}
      filter={(search, { value }) => filter?.(search, value) ?? true}
    />
  )
}
