/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateCreateType = (typeof EstimateCreateType)[keyof typeof EstimateCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateCreateType = {
  project: 'project',
  change_order: 'change_order',
} as const
