type MapOptions = {
  excludeFalsy: boolean
}

// eslint-disable-next-line mosaic-js/unnamed-args
export function mapObject<T extends { [key: string]: any }, R extends Record<keyof T, any>>(
  obj: T,
  mapper: <K extends keyof T>(item: T[K], k: K) => R[K],
  options?: Partial<MapOptions>,
) {
  const result = {} as R
  // eslint-disable-next-line
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const mappedValue = mapper(obj[key], key)
      if (mappedValue || !options?.excludeFalsy) {
        result[key] = mappedValue
      }
    }
  }
  return result
}
