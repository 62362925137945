/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayeeContactCreateAddress2Type =
  (typeof PayeeContactCreateAddress2Type)[keyof typeof PayeeContactCreateAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayeeContactCreateAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
