import {
  useCallback,
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import LogRocket from 'logrocket'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ENVIRONMENT, LOGROCKET_KEY, prodLike } from 'env'
import { SentryClient } from 'lib/sentry'
import { Cb } from 'cb'
import { addTag } from 'utils/string'

export type Tier = 'core' | 'plus' | 'plus-with-coi' | 'scale'

function getDefaultFlags() {
  return {
    // permanent flags
    showDevTools: true,
    showMultipleBankAccounts: true,
    businessTier: 'scale' as Tier,
    hideCosts: false,

    // temporary flags
    showNewProjectDetail: false,
    showStripeOnboarding: false,
    showEstimateSections: false,
    showHideEstimateItem: false,
    showSetMarkupAcrossAllItems: false,
    allowPostSubtotalConfiguration: false,
    runOcrAutomatically: false,
    // showNewEstimateItemsInput: false,
    showMultiBillPayment: false,
    showRequestApprovalForDonna: false,
    showNewQboFlow: false,
    showProcoreIntegration: false,
    showCostTypes: false,
    showEstimateCoverPage: false,
    showZeroAmountLineItems: false,
    showNewGuestBillDetail: false,
    allowCreateBufferedDiscounts: false,
  }
}

type Flags = ReturnType<typeof getDefaultFlags>

const DefaultSanitizedFields = [
  'password',
  'session_token',
  'ein',
  'ssn',
  'ssn_last_4',
  'account_number',
]

function bodySanitizer(body: string | undefined): string | undefined {
  try {
    const parsed = JSON.parse(body ?? '')
    DefaultSanitizedFields.forEach((f) => {
      if (parsed[f]) {
        parsed[f] = '<REDACTED>'
      }
    })
    return JSON.stringify(parsed)
  } catch {
    return body
  }
}

if (prodLike(ENVIRONMENT) && typeof window !== 'undefined') {
  LogRocket.init(LOGROCKET_KEY, {
    network: {
      requestSanitizer: (req) => {
        req.body = bodySanitizer(req.body)
        return req
      },
      responseSanitizer: (res) => {
        res.body = bodySanitizer(res.body)
        return res
      },
    },
  })
}

type AnalyticsUser = {
  id: string
  authType: 'impersonator' | 'default'
  name: string
  email: string | null
  phoneNumber: string | null
  business: Cb.Business | null
}

type TAnalyticsContext = {
  identify: (user: AnalyticsUser) => void
  deIdentify: () => void
  flags: Flags
  setFlagOverrides: Dispatch<SetStateAction<Partial<Flags>>>
}
const AnalyticsContext = createContext<TAnalyticsContext | undefined>(undefined)

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)
  if (context === undefined)
    throw new Error('AnalyticsContext must be used within an AnalyticsProvider')
  return context
}

type Props = { children: ReactNode }

function AnalyticsProviderInner({ children }: Props) {
  const [user, setUser] = useState<AnalyticsUser | null>(null)
  const ldFlags = useFlags() as TAnalyticsContext['flags']
  const flags = ENVIRONMENT === 'test' ? getDefaultFlags() : ldFlags
  const [flagOverrides, setFlagOverrides] = useState<Partial<Flags>>({})
  const LDClient = useLDClient()

  const deIdentify = useCallback(() => {
    SentryClient.deidentify()
    setUser(null)
  }, [])

  const identify = useCallback((u: AnalyticsUser) => {
    SentryClient.identify({
      userId: u.id,
      email: u.email,
      phoneNumber: u.phoneNumber,
      isImpersonator: u.authType === 'impersonator',
    })
    LogRocket.identify(u.id, {
      name: addTag(
        u.business ? u.business.name : null,
        addTag(
          !u.business ? 'Guest' : null,
          addTag(u.authType === 'impersonator' ? 'Impersonator' : null, u.name),
        ),
      ),
      ...(u.email ? { email: u.email } : {}),
      ...(u.phoneNumber ? { phoneNumber: u.phoneNumber } : {}),
    })
    setUser(u)
  }, [])

  useEffect(() => {
    if (user && LDClient) {
      LDClient.identify({
        kind: 'multi',
        user: {
          key: user.id,
          name: user.name,
          email: user.email,
        },
        ...(user.business && {
          business: {
            kind: 'business',
            name: user.business.name,
            key: user.business.id,
          },
        }),
      })
    }
  }, [user, LDClient])

  return (
    <AnalyticsContext.Provider
      // eslint-disable-next-line
      value={{
        identify,
        deIdentify,
        flags:
          ENVIRONMENT === 'local' || ENVIRONMENT === 'dev' ? { ...flags, ...flagOverrides } : flags,
        setFlagOverrides,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export default function AnalyticsProvider({ children }: Props) {
  return <AnalyticsProviderInner>{children}</AnalyticsProviderInner>
}
