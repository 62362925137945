/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GetRenofiAdViewOutputOfferType =
  (typeof GetRenofiAdViewOutputOfferType)[keyof typeof GetRenofiAdViewOutputOfferType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRenofiAdViewOutputOfferType = {
  heloc: 'heloc',
  personal_loan: 'personal_loan',
} as const
