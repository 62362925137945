import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import AnimatedHeight from 'react-animate-height'
import Typography from 'compass/data/Typography'
import { InputBaseUtils } from 'compass-local/InputBase'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import useScreenSize from 'compass/theme/useScreenSize'
import { isEnter } from 'compass-local/utils/key-press'
import Tooltip from 'compass-local/Tooltip'

function Checkmark() {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 4.9999L4.83333 8.33323L11.5 1.66656"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

type Props = InputProps<boolean> &
  InputBaseUtils.ExternalProps & {
    size?: 'small' | 'large'
    hidden?: boolean
    showDashWhenUnselected?: boolean
    expandHitbox?: boolean
    badge?: ReactNode
    disabledMessage?: string
  }

export default function Checkbox(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    disabled,
    title,
    subtitle,
    hidden,
    size,
    error,
    badge,
    willUpdate,
    didUpdate,
    showDashWhenUnselected,
    expandHitbox,
    disabledMessage,
  } = getInputProps(props)

  const sz = useScreenSize()
  const sizeOrDefault = size ?? sz === 'sm' ? 'large' : 'small'

  const updateInLifecycle = async (newValue: boolean) => {
    if (willUpdate) await willUpdate(value, newValue)
    update?.(newValue)
    didUpdate?.(value, newValue)
  }

  if (hidden) return null
  const component = (
    <div className="test-checkbox vflex gap-1 border-1">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (!disabled) {
            updateInLifecycle(!value)
          }
        }}
      >
        <div
          className={cn(
            'box-content shrink-0',
            sizeOrDefault === 'small' ? 'h-[16px] w-[16px]' : 'w-[24px] h-[24px]',
            sz !== 'sm' &&
              !disabled &&
              expandHitbox &&
              'hitbox-lg hover:bg-[rgba(114,101,98,0.1)] transition-all rounded-full',
          )}
        >
          <div
            onFocus={focus}
            onBlur={blur}
            onKeyDown={(e) => {
              if (isEnter(e) && !disabled) {
                updateInLifecycle(!value)
              }
            }}
            role="checkbox"
            aria-checked={value}
            tabIndex={0}
            className={cn(
              'transition-all duration-100 !rounded-2 relative w-full h-full',
              // bg
              value
                ? disabled
                  ? 'bg-th-coolgrey-3'
                  : 'bg-th-orange-beam'
                : disabled
                ? 'bg-th-coolgrey-3'
                : 'bg-th-bg-white',
              // border-color (TODO: needs important to override TW defaults)
              value
                ? disabled
                  ? '!border-th-warmgrey-1'
                  : '!border-th-orange-dark1'
                : '!border-th-warmgrey-1',
              // border w
              value ? 'border-2' : 'border',
              // cursor
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
            style={{ boxShadow: 'none' }}
          >
            <div className="absolute inset-0 p-[1px] flex items-center justify-center">
              {value ? (
                <Checkmark />
              ) : showDashWhenUnselected ? (
                <div className="w-[8px] h-[2px] bg-th-coolgrey-2" />
              ) : null}
            </div>
          </div>
        </div>
        {(title || badge || subtitle) && (
          <div className="vflex gap-1 px-1.5">
            {title && (
              <Typography
                variant={value ? 'bodybold' : 'body'}
                className={disabled ? 'cursor-not-allowed text-th-text-disabled' : ''}
              >
                <div className="flex gap-2 items-center">
                  {title}
                  {badge}
                </div>
              </Typography>
            )}
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </div>
        )}
      </div>
      <AnimatedHeight
        height={error ? 'auto' : 0}
        className="text-th-red-warning"
        duration={150}
        animateOpacity
      >
        {error}
      </AnimatedHeight>
    </div>
  )

  return (
    <Tooltip inactive={!disabled || !disabledMessage} message={disabledMessage}>
      {component}
    </Tooltip>
  )
}
