/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListTasksType = (typeof ListTasksType)[keyof typeof ListTasksType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTasksType = {
  approve_invoices: 'approve_invoices',
  link_bank_account: 'link_bank_account',
  upload_coi_document: 'upload_coi_document',
  admin_approve_invoice: 'admin_approve_invoice',
  nonadmin_resolve_invoice: 'nonadmin_resolve_invoice',
  action_approved_estimate: 'action_approved_estimate',
  sign_conditional_lien_waiver: 'sign_conditional_lien_waiver',
  sign_unconditional_lien_waiver: 'sign_unconditional_lien_waiver',
  schedule_bill_payment: 'schedule_bill_payment',
} as const
