/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceListRowOutboundSyncDetailsStatus =
  (typeof InvoiceListRowOutboundSyncDetailsStatus)[keyof typeof InvoiceListRowOutboundSyncDetailsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceListRowOutboundSyncDetailsStatus = {
  new: 'new',
  completed: 'completed',
  failed: 'failed',
} as const
