/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExpensePaymentDirection =
  (typeof BeamBalanceTransactionTypeExpensePaymentDirection)[keyof typeof BeamBalanceTransactionTypeExpensePaymentDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExpensePaymentDirection = {
  debit: 'debit',
  credit: 'credit',
} as const
