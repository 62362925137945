/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeProgressInvoiceType =
  (typeof BillTypeProgressInvoiceType)[keyof typeof BillTypeProgressInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeProgressInvoiceType = {
  progress: 'progress',
} as const
