import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const InternalFormatOptions = {
  short: 'MM/DD/YYYY',
  medium: 'MMM D, YYYY',
  monthDay: 'MMM D',
  monthYear: 'MMM YYYY',
  month: 'MMM',
  day: 'D',
  year: 'YYYY',
  iso: 'YYYY-MM-DD',
  isoMonth: 'MM-YYYY',
  datetimeMedium: 'MMM D, YYYY h:mma',
  datetimeMediumNoYear: 'MMM D h:mma',
}

const FormatOptions = {
  short: 'MM/DD/YYYY',
  medium: 'MMM D, YYYY',
  monthDay: 'MMM D, YYYY',
  isoMonth: 'MM-YYYY',
  datetimeMedium: 'MMM D, YYYY h:mma',
}

type MSDate = any
export const date = (initValue?: string | null): MSDate => {
  return dayjs(initValue)
}
export { dayjs }
export type Date = MSDate

type InternalDateFormat = keyof typeof InternalFormatOptions

function formatInternal(value: string | MSDate, form: InternalDateFormat) {
  const dayJsForm = InternalFormatOptions[form]

  const parsedValue = typeof value === 'string' ? date(value) : value
  return parsedValue.format(dayJsForm)
}

export type DateFormat = keyof typeof FormatOptions

type FormatDateOptions = {
  form: DateFormat
}

export function format(value: string | MSDate, options?: Partial<FormatDateOptions>) {
  return formatInternal(value, options?.form ?? 'medium')
}

type FormatTimestampOptions = {
  year: boolean
}

export function timestamp(value: string | MSDate, options?: Partial<FormatTimestampOptions>) {
  return formatInternal(value, options?.year ? 'datetimeMediumNoYear' : 'datetimeMedium')
}

export function monthYear(value: string | MSDate) {
  return formatInternal(value, 'monthYear')
}

export function monthDay(value: string | MSDate) {
  return formatInternal(value, 'monthDay')
}

export function formatRange(...values: [string | MSDate, string | MSDate]) {
  const date1 = date(values[0])
  const date2 = date(values[1])

  if (format(date1) === format(date2)) return format(date1)

  if (date1.year() === date2.year()) {
    const day1 = formatInternal(date1, 'day')
    const day2 = formatInternal(date2, 'day')
    const month1 = formatInternal(date1, 'month')
    const month2 = formatInternal(date2, 'month')
    const year1 = formatInternal(date1, 'year')
    if (date1.month() === date2.month()) {
      return `${month1} ${day1}–${day2}, ${year1}`
    } else {
      return `${month1} ${day1}–${month2} ${day2}, ${year1}`
    }
  }
  return `${format(date1)} – ${format(date2)}`
}

export function iso<T extends MSDate | null>(value: T): T extends MSDate ? string : string | null {
  return (value ? formatInternal(value, 'iso') : null) as T extends MSDate ? string : string | null
}

export function removeTime(d: Date) {
  return d.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
}

export function today() {
  return removeTime(date())
}

export function formatDateRelative(value: string | MSDate, withoutSuffix = false) {
  dayjs.extend(relativeTime)
  const parsedValue = typeof value === 'string' ? date(value) : value
  return parsedValue.fromNow(withoutSuffix)
}

export function chopTo5Minutes(value: MSDate) {
  const m = Math.ceil(value.minute() / 5) * 5
  return value.set('millisecond', 0).set('second', 0).set('minute', m)
}

export function tomorrow() {
  return today().add(1, 'day')
}

export function yesterday() {
  return today().add(-1, 'day')
}

export function daysAhead(n: number) {
  return today().add(n, 'day')
}

const DayOfWeek = {
  thursday: 4,
  friday: 5,
}

export function getNextOfDay(day: keyof typeof DayOfWeek) {
  const dayNum = DayOfWeek[day]
  const tomorrowNum = tomorrow().day()
  const daysTillTarget = (dayNum - tomorrowNum + 7) % 7

  return tomorrow().add(daysTillTarget, 'day')
}

export function isBeforeToday(x: string | Date) {
  return date(x) < today()
}

export function isToday(dateTime: MSDate) {
  return removeTime(dateTime).valueOf() === today().valueOf()
}

export function monthsBetween(start: MSDate, end: MSDate) {
  const months = []
  let current = start
  while (current < end) {
    months.push(current)
    current = current.add(1, 'month')
  }
  return months
}

export type DateRange = {
  start: Date
  end: Date
}
export function isBetween(dateRange: DateRange, dateTime: Date) {
  const start = removeTime(dateRange.start)
  const end = removeTime(dateRange.end)
  const dateWithoutTime = removeTime(dateTime)
  return (
    (dateWithoutTime.isSame(start) || dateWithoutTime.isAfter(start)) &&
    (dateWithoutTime.isSame(end) || dateWithoutTime.isBefore(end))
  )
}

export function isEmptyOrBetween(dateRange: DateRange | null, d: Date) {
  return !dateRange || isBetween(dateRange, d)
}

export function isNonEmptyAndBetween(dateRange: DateRange | null, d: Date) {
  return dateRange && isBetween(dateRange, d)
}
