import { base } from './factories'

type FieldProps<T, V> = {
  initialValue: T
  validate?: (val: T) => V
  validateField: (val: string) => void
}

export function field<T extends string | null>(props: FieldProps<string, T>) {
  return base({
    initialValue: props.initialValue,
    validate: (val): T => {
      if (val === '') {
        return props.validate?.(val) ?? (null as T)
      } else {
        props.validateField(val)
        return props.validate?.(val) ?? (val as T)
      }
    },
  })
}

type Props<T, V> = {
  initialValue?: T
  validate?: (val: T) => V
}

function validatePhone(s: string) {
  if (s.length !== 10) {
    throw new Error('Phone number must be 10 characters')
  }
  if (s[0] === '1') {
    throw new Error('Phone number must not start with 1')
  }
  if (s[0] === '0') {
    throw new Error('Phone number must not start with 0')
  }
  return s
}

export function phone<T extends string | null>(props?: Props<string, T>) {
  return field({
    initialValue: props?.initialValue ?? '',
    validate: props?.validate,
    validateField: validatePhone,
  })
}

type NumericProps = {
  min?: number
  max?: number
}

export function numeric2<T extends string | null>(props?: Props<string, T> & NumericProps) {
  return field({
    initialValue: props?.initialValue ?? '',
    validate: props?.validate,
    validateField: (val) => {
      const num = +val
      if (Number.isNaN(num)) throw new Error('Must be a number')
      if (props?.max && num > props.max)
        throw new Error(`Must be less than or equal to ${props.max}`)
      if (props?.min && num < props.min)
        throw new Error(`Must be greater than or equal to ${props.min}`)
    },
  })
}

function validateCurrency(val: string) {
  if (val !== '-' && Number.isNaN(+val)) throw new Error('Must be a number')
}

type AmountProps = {
  min?: string | number
  max?: string | number
}
export function currency<T extends string | null>(props?: Props<string, T> & AmountProps) {
  return field({
    initialValue: props?.initialValue ?? '',
    validate: props?.validate,
    validateField: (val) => {
      validateCurrency(val)
      const num = +val
      if (props?.max && num > Number(props.max)) {
        throw new Error(`Must be less than or equal to ${props.max}`)
      }
      if (props?.min && num < Number(props.min)) {
        throw new Error(`Must be greater than or equal to ${props.min}`)
      }
      return val
    },
  })
}

function validateEmail(s: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!s.toLowerCase().match(re)) {
    throw new Error('Invalid email')
  }
  return s
}

export function email<T extends string | null>(props?: Props<string, T>) {
  return field({
    initialValue: props?.initialValue ?? '',
    validate: props?.validate,
    validateField: validateEmail,
  })
}
