import { useState } from 'react'
import { ConnectAccountOnboarding } from '@stripe/react-connect-js'
import SupportLink from 'components/misc/SupportLink'
import { ClosableLayout } from 'utils/ClosableLayout'
import { t } from 'content'
import { OnboardAccountForm3Utils } from '../utils'

export default OnboardAccountForm3Utils.fc.Form(() => {
  const [stripeLoadingStarted, setStripeLoadingStarted] = useState(false)
  const { onSuccess } = OnboardAccountForm3Utils.fc.useContext()

  return (
    <ClosableLayout.Body title={t('Activate account')}>
      <div className="vflex items-center gap-10">
        <ConnectAccountOnboarding
          onLoaderStart={() => setStripeLoadingStarted(true)}
          onExit={onSuccess}
          fullTermsOfServiceUrl="https://www.trybeam.com/terms-of-use"
          privacyPolicyUrl="https://www.trybeam.com/privacy-policy"
          collectionOptions={{
            fields: 'currently_due',
            futureRequirements: 'omit',
          }}
        />
        {stripeLoadingStarted && <SupportLink tKey="Need help? <X>Please contact support</X>." />}
      </div>
    </ClosableLayout.Body>
  )
})
