type Props = {
  children: React.ReactNode
}

export default function Alert({ children }: Props) {
  return (
    <div className="px-3 py-2 text-red-800 bg-th-red-light1 border border-th-red-warning rounded-8">
      {children}
    </div>
  )
}
