import { Options } from 'orval'
import Axios, { AxiosRequestConfig } from 'axios'

let baseUrl = ''
let authToken = ''

export const getBaseUrl = () => baseUrl
export const setBaseUrl = (newBaseUrl: string) => {
  baseUrl = newBaseUrl
}
export const getAuthToken = () => authToken
export const setAuthToken = (newAuthToken: string) => {
  authToken = newAuthToken
}

type SomeListResult = { results: any[] }
export type ExtractFromList<T extends SomeListResult> = T['results']
export type Params<T> = { params?: T }
export type { UseQueryOptions, QueryKey } from '@tanstack/react-query'

function forcePartial(typeDef: string): string {
  const re = /([a-zA-z0-9]+: )([a-zA-Z0-9]*)/
  const res = typeDef.match(re)
  if (res?.length !== 3) return typeDef
  else return `${res.at(1)}Partial<${res.at(2)}>`
}

type GeneratorVerb = ReturnType<
  Exclude<
    Exclude<
      Exclude<Exclude<Options['output'], string | undefined>['override'], undefined>['transformer'],
      undefined
    >,
    string
  >
>

export function withPartialUpdateBody(verb: GeneratorVerb): GeneratorVerb {
  const bodyParamIndex = verb.props.findIndex((v) => v.type === 'body')

  if (bodyParamIndex >= 0) {
    return {
      ...verb,
      props: verb.props.map((prop, i) =>
        bodyParamIndex === i
          ? {
              ...prop,
              implementation: forcePartial(verb.props[bodyParamIndex].implementation),
            }
          : prop,
      ),
    }
  } else {
    return verb
  }
}

export function withRequiredResults(verb: GeneratorVerb): GeneratorVerb {
  return {
    ...verb,
    response: {
      ...verb.response,
      schemas: [
        {
          ...verb.response.schemas[0],
          model: verb.response.schemas[0].model
            .replace('results?', 'results')
            .replace('count?', 'count'),
        },
      ],
    },
  }
}

export const customAxios = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = Axios({
    ...config,
    ...options,
    baseURL: getBaseUrl(),
    ...(getAuthToken() && { headers: { Authorization: getAuthToken() } }),
    cancelToken: source.token,
  }).then(({ data }) => data)

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

type OrvalTransformer = Exclude<
  Exclude<Exclude<Options['input'], string | undefined>['override'], undefined>['transformer'],
  undefined | string
>
type OpenAPIObject = ReturnType<OrvalTransformer>
export function withCustomModels(spec: OpenAPIObject): OpenAPIObject {
  return {
    ...spec,
    components: {
      ...spec.components,
      schemas: {
        ...spec.components?.schemas,
        ...Object.keys(spec.components?.schemas ?? {}).reduce((p, c) => {
          const schema = spec.components?.schemas?.[c] ?? null

          if (schema && !('$ref' in schema)) {
            if (/.+Patch/.test(c)) {
              const overridenProperties = Object.keys(schema.properties ?? {}).reduce(
                (p2, c2) => {
                  const field = schema.properties?.[c2]
                  if (!field || '$ref' in field) return p2
                  if (field.type === 'array') {
                    const itemsType = field.items
                    if (itemsType && !('$ref' in itemsType) && 'oneOf' in itemsType) {
                      // eslint-disable-next-line
                      console.log(`Overriding field ${c2} in type ${c}...`)
                      return {
                        ...p2,
                        [c2]: {
                          ...field,
                          items: {
                            oneOf:
                              itemsType.oneOf?.map((x) => {
                                if ('$ref' in x) {
                                  return x
                                } else if ('id' in (x.properties ?? {})) {
                                  return { ...x, required: ['id'] }
                                } else {
                                  return x
                                }
                              }) ?? [],
                          },
                        },
                      }
                    }
                    return p2
                  }
                  return p2
                },
                schema.properties ?? ({} as any),
              )

              const newSchema = {
                ...p,
                [c]: {
                  ...schema,
                  properties: overridenProperties,
                },
              }
              return newSchema
            }
          }
          return p
        }, {} as any),
      },
    },
  }
}
