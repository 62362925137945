/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type VendorListRowComplianceDocumentsItemCoiDetailsCoverageTypesItem =
  (typeof VendorListRowComplianceDocumentsItemCoiDetailsCoverageTypesItem)[keyof typeof VendorListRowComplianceDocumentsItemCoiDetailsCoverageTypesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorListRowComplianceDocumentsItemCoiDetailsCoverageTypesItem = {
  general: 'general',
  automotive: 'automotive',
  umbrella: 'umbrella',
  excess: 'excess',
  workers_comp: 'workers_comp',
} as const
