/* eslint-disable mosaic-js/unnamed-args */
import { useRef, useState, useEffect } from 'react'
import * as Interaction from './interaction'
import { Field, FieldGroup } from './base'
import * as Utils from './js-utils'

export type Branded<T, S extends string> = T & { __brand: S }
export type EmptyString = Branded<'', 'empty'>

export type ConcreteShape<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? S
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]: ConcreteShape<S[K]> }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? ConcreteShape<S>[]
  : 'unmapped'

export type OutputShape<X extends Field<any, any, any>> = X extends {
  validate: (val: any) => infer S
}
  ? S
  : 'unmapped'

export class FieldError extends Error {}

type ErrorAndPath = { error: FieldError; path: string }
export class ValidationError extends Error {
  fieldErrors: ErrorAndPath[]

  constructor(fieldErrors: ErrorAndPath[]) {
    super()
    this.fieldErrors = fieldErrors
  }
}

type ConcreteCell<T> = {
  value: T
  update: (newValue: T) => void
  checkpoint: () => void
}
type CellConcreteConfig<V> = {
  disabled?: boolean
  hidden?: boolean
  validate?: (value: V) => void
}

export type InputValue<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? S
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]: InputValue<S[K]> }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? InputValue<S>[]
  : 'unmapped'

export type PartialInputValue<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? S
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]?: PartialInputValue<S[K]> }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? PartialInputValue<S>[]
  : 'unmapped'

type OnChange<S extends string | number | symbol> = S extends string
  ? `onChange${Capitalize<S>}`
  : S

export type InputCore<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? ConcreteCell<S>
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? {
      fields: { [K in keyof S]: InputCore<S[K]> }
      update: (newValue: PartialInputValue<X>) => void
    }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? {
      fields: InputCore<S>[]
      update: (newValue: PartialInputValue<X>) => void
    }
  : 'unmapped'

export type PartialConcreteConfig<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
} & Field<any, any, infer V>
  ? CellConcreteConfig<V>
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? {
      disabled?: boolean
      hidden?: boolean
      config?: { [K in keyof S]?: PartialConcreteConfig<S[K]> }
      hooks?: (core: { [K in keyof S]: InputCore<S[K]> }) => Partial<{
        [K in keyof S as OnChange<K>]?: (newValue: InputValue<S[K]>) => void
      }>
    }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? {
      config?: (core: InputCore<S>) => {
        values?: PartialConcreteConfig<S>
        disableRemove?: boolean
      }
      allowRemoveLast?: boolean
      disableAppend?: boolean
      disableReorder?: boolean
    }
  : 'unmapped'

export type DefaultValues<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? S
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]: DefaultValues<S[K]> }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? DefaultValues<S>[]
  : 'unmapped'

export type InitValue<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? S
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]?: InitValue<S[K]> }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? InitValue<S>[]
  : 'unmapped'

export function hasErrorKey<T extends Field<any, any, any>>(f: T, key: string) {
  return Utils.hasErrorKey(f, key)
}

// ~~~~~~~~~~~~~~~~~~~~~~~~

type Writables<T> = T extends { update: (newValue: infer S) => void }
  ? S
  : T extends { _controller: { update: (newValue: infer S) => void } }
  ? S
  : never

export type InputCell<T> = {
  initValue: T
  value: T
  status: Interaction.State
  update: (newValue: T) => void
  updateWithoutHooks: (newValue: T) => void
  focus: () => void
  blur: () => void
  error: string | null
  statelessError: string | null
  hasErrorWithin: boolean
  hasChanged: boolean
  hasFocusWithin: boolean
  tap: () => void
  reset: () => void
  checkpoint: () => void
  // concrete config
  disabled: boolean
  hidden: boolean
}

type InputGroup<T> = T & {
  _controller: {
    hasErrorWithin: boolean
    hasChanged: boolean
    hasFocusWithin: boolean
    /** Does NOT reset Interaction.status */
    update: (newValue: { [K in keyof T]?: Writables<T[K]> }) => void
    tap: () => void
    reset: () => void
    checkpoint: () => void
    fields: T
    // concrete config
    // --
  }
}

type InputList<T> = T[] & {
  _controller: {
    hasErrorWithin: boolean
    hasChanged: boolean
    hasFocusWithin: boolean
    tap: () => void
    reset: () => void
    checkpoint: () => void
    error: string | null
    /** Resets ALL Interaction.status */
    update: (newValue: Writables<T>[]) => void
    append: (newValue?: Writables<T>) => void
    insertAt: (i: number, newValue?: Writables<T>) => void
    remove: (i: number) => void
    move: (i: number, j: number) => void
    // concrete config
    disableAppend: boolean
    disableReorder: boolean
    disableRemove: (i: number) => boolean
  }
}

export type Input<X extends Field<any, any, any>> = X extends { _field_type: 'cell'; spec: infer S }
  ? InputCell<S>
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? InputGroup<{ [K in keyof S]: Input<S[K]> }>
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? InputList<Input<S>>
  : 'unmapped'

export function props<X>(cell: InputCell<X>) {
  return {
    value: cell.value,
    update: cell.update,
    focus: cell.focus,
    blur: cell.blur,
    error: cell.error,
    disabled: cell.disabled,
    hidden: cell.hidden,
  }
}

export function attachErrors(schema: any, state: any, errors: any) {
  return Utils.attachErrors(schema, state, errors)
}

export function collectErrors(schema: any, state: any) {
  return Utils.collectErrors(schema, state)
}

type SubgroupValidationResponse<
  T extends Field<any, any, any> & { _field_type: 'group' },
  K extends keyof T['spec'],
> = {
  error: string | null
  isLoading: boolean
  trigger: (
    callback: (valids: {
      [K2 in K]: ReturnType<T['spec'][K2]['validate']>
    }) => Promise<void> | void,
  ) => void
}

export function useSubgroupValidation<
  T extends Field<any, any, any> & { _field_type: 'group' },
  K extends keyof T['spec'],
>(_: T, state: Input<T>, fields: K[]): SubgroupValidationResponse<T, K> {
  const [status, setStatus] = useState<'loading' | 'idle' | 'error' | 'success'>('idle')
  const [error, setError] = useState('')

  return {
    error: status === 'error' ? error : null,
    isLoading: status === 'loading',
    trigger: async (callback) => {
      try {
        setStatus('loading')
        const valids = Object.fromEntries(
          fields.map((k) => {
            const stateK: any = state[k]
            const validation =
              '_controller' in stateK ? stateK._controller.validation : stateK.validation
            if (!validation.isValid) {
              const x = String(k)
              throw new Error(
                `${`${x[0].toUpperCase()}${x.slice(1)}`.split('_').join(' ')}: ${validation.error}`,
              )
            } else {
              return [k, validation.validValue]
            }
          }),
        )
        await callback(valids as any)
        setStatus('success')
      } catch (e: any) {
        fields.forEach((x) => {
          const cell: any = state[x]
          if ('tap' in cell) cell.tap()
          else cell._controller.tap()
        })
        setError(e.message)
        setStatus('error')
      }
    },
  }
}

/**
 * Note: this ignores `optional=true` on dimensional fields
 */
export function isValid(state: any) {
  return '_controller' in state
    ? state._controller.validation.isValid && state._controller.validation.validValue !== null
    : state.isValid
}

type FlattenedInputCore<X extends Field<any, any, any>> = X extends {
  _field_type: 'cell'
  spec: infer S
}
  ? ConcreteCell<S>
  : X extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? { [K in keyof S]: FlattenedInputCore<S[K]> } & {
      _controller: {
        update: (newValue: PartialInputValue<X>) => void
      }
    }
  : X extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? FlattenedInputCore<S>[] & {
      _controller: {
        update: (newValue: PartialInputValue<X>) => void
      }
    }
  : 'unmapped'

type LazyHooks<T extends Field<any, any, any>> = T extends {
  _field_type: 'cell'
}
  ? undefined
  : T extends { _field_type: 'group'; spec: infer S extends FieldGroup }
  ? {
      config?: { [K in keyof S]?: LazyHooks<S[K]> }
      hooks?: (core: { [K in keyof S]: FlattenedInputCore<S[K]> }) => Partial<{
        [K in keyof S as OnChange<K>]?: (newValue: InputValue<S[K]>) => void
      }>
    }
  : T extends { _field_type: 'list'; spec: infer S extends Field<any, any, any> }
  ? {
      config?: (core: InputCore<S>) => {
        values?: LazyHooks<S>
      }
    }
  : 'unmapped'

export function useAttachLazyHooks<T extends Field<any, any, any>>(
  f: T,
  state: Input<T>,
  config: LazyHooks<T>,
): Input<T> {
  const stateWithLazyHooks = Utils.attachLazyHooks(f, state, config)
  const propsRef = useRef({ state, f, config }).current
  useEffect(() => {
    Utils.callLazyOnInits(propsRef.f, propsRef.state, propsRef.config)
  }, [propsRef])

  return stateWithLazyHooks
}
