/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ItemType = (typeof ItemType)[keyof typeof ItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemType = {
  category: 'category',
  group: 'group',
  service: 'service',
  inventory: 'inventory',
  non_inventory: 'non_inventory',
} as const
