import { AxiosError } from 'axios'
import { MSError2, F } from 'msutils'

const flatten = (data: object, prefix: string = '') => {
  const result: { [key: string]: string | number | null } = {}

  Object.entries(data).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      Object.assign(result, flatten(value, `${prefix}${key}.`))
    } else {
      result[`${prefix}${key}`] = value
    }
  })

  return result
}

export function hasMatchedFieldError<T extends F.Field<any, any, any>>(spec: T, e: AxiosError) {
  const errors = Object.fromEntries(
    Object.entries(flatten(e.response?.data ?? {})).map(([k, v]) => [k.slice(0, -2), v]),
  )
  return Object.keys(errors).some((k) => !k.includes('non_field_errors') && F.hasErrorKey(spec, k))
}

export function getUnmatchedErrors<T extends F.Field<any, any, any>>(spec: T, e: AxiosError) {
  const errors = Object.fromEntries(
    Object.entries(flatten(e.response?.data ?? {})).map(([k, v]) => [k.slice(0, -2), v]),
  )
  const unrecognizedKeys: [string, any][] = []
  Object.entries(errors).forEach(([k, v]) => {
    if (!F.hasErrorKey(spec, k)) {
      unrecognizedKeys.push([k, v])
    }
  })

  Object.entries(errors).forEach(([k, v]) => {
    if (k.includes('non_field_errors')) {
      unrecognizedKeys.push([k.replaceAll('non_field_errors', ''), v])
    }
  })

  return unrecognizedKeys.flatMap(([k, v]) => {
    return v
      ? `${k
          .split('.')
          .map((x) => (x ? `${x[0].toUpperCase()}${x.slice(1)}`.split('_').join(' ') : 'Error'))
          .map((x) => (Number.isNaN(Number(x)) ? x : `${Number(x) + 1}`))
          .join(' > ')}: ${v}`
      : []
  })
}

export function parse4XX<T extends F.Field<any, any, any>>(spec: T, e: AxiosError) {
  const errors = Object.fromEntries(
    Object.entries(flatten(e.response?.data ?? {})).map(([k, v]) => [k.slice(0, -2), v]),
  )
  const unrecognizedKeys: string[] = []
  Object.keys(errors).forEach((k) => {
    if (!F.hasErrorKey(spec, k)) {
      unrecognizedKeys.push(k)
    }
  })

  // TODO: other kinds of warnings:
  // * error on hidden/disabled field
  if (unrecognizedKeys.length > 0) {
    MSError2.getErrorFn()(new MSError2.Error2(`Unrecognized error keys: ${unrecognizedKeys}`))
  }

  return e.response?.data ?? {}
}

export function get400ReportMessage(e: AxiosError) {
  const errors = Object.fromEntries(
    Object.entries(flatten(e.response?.data ?? {})).map(([k, v]) => [k.slice(0, -2), v]),
  )

  return JSON.stringify(errors)
}

function isAxiosErrorInRange(e: any, range: [lower: number, upper: number]) {
  const statusCode = e.response?.status
  if (typeof statusCode !== 'number') return false

  return e?.name === 'AxiosError' && statusCode >= range[0] && statusCode <= range[1]
}

export const isAxios4XX = (e: Error): e is AxiosError<any> => isAxiosErrorInRange(e, [400, 499])

export function collectErrors(f: any, state: any) {
  const collectedErrors = F.collectErrors(f, state)
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(flatten(collectedErrors)).flatMap(([k, v]) => {
        if (v === null) return []
        return [[k, v]]
      }),
    ),
  )
}

export class StaticValidationError extends Error {
  collectedErrors: string

  constructor(message: string, collectedErrors: string) {
    super(message)
    this.collectedErrors = collectedErrors
  }
}
