import { useCallback } from 'react'
import { useRouter } from 'next/router'

export function useCurrentPath() {
  return useRouter().asPath
}

export function useCurrentRoute() {
  return useRouter().route
}

export function useQueryParams(): Record<string, string | undefined> {
  return useRouter().query as any
}

export function useAssertQueryParam(key: string): string {
  const value = useQueryParams()[key]
  if (!value) {
    throw new Error(`Expected parameter for ${key}`)
  } else {
    return value
  }
}

export function useNavigate() {
  const r = useRouter()

  return useCallback(
    (route: string, options?: { replace: boolean }) => {
      if (options?.replace) {
        return r.replace(route)
      } else {
        return r.push(route)
      }
    },
    [r],
  )
}
