/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeCreditCardBillPaymentDirection =
  (typeof BeamBalanceTransactionTypeCreditCardBillPaymentDirection)[keyof typeof BeamBalanceTransactionTypeCreditCardBillPaymentDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeCreditCardBillPaymentDirection = {
  debit: 'debit',
  credit: 'credit',
} as const
