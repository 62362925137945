import { ReactNode, useEffect, useState } from 'react'
import { Drawer as BaseDrawer } from 'antd'
import { cn } from 'msutils/classnames'
import useScreenSize from '../../theme/useScreenSize'
import { DrawerProvider, useDrawerContext } from './internal-utils'

type Props = {
  isActive: boolean
  setInactive: () => void
  direction?: 'right' | 'bottom'
  children: ReactNode
  debug?: boolean
}

export default function Drawer(props: Props) {
  const { isActive, setInactive, children } = props
  const sz = useScreenSize()
  const direction = props.direction ?? sz === 'sm' ? 'bottom' : 'right'
  const parentDrawerContext = useDrawerContext()
  const [childrenWidth, setChildrenWidth] = useState<number>()
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
  const [contentSize, setContentSize] = useState<[number, number]>()

  const actualWidth = childrenWidth ? childrenWidth + 20 : contentSize?.[0]

  useEffect(() => {
    if (contentRef) {
      setContentSize([contentRef.clientWidth, contentRef.clientHeight])
    }
  }, [contentRef])

  useEffect(() => {
    if (parentDrawerContext && actualWidth && isActive) {
      parentDrawerContext.setChildrenWidth(actualWidth)
    }
  }, [actualWidth, parentDrawerContext, isActive])

  useEffect(() => {
    if (!isActive) {
      if (parentDrawerContext) {
        parentDrawerContext.setChildrenWidth(undefined)
      }
    }
  }, [isActive, parentDrawerContext])

  return (
    <BaseDrawer
      open={isActive}
      onClose={(e) => {
        setInactive()
        e.stopPropagation()
      }}
      {...(direction === 'right'
        ? {
            width: actualWidth,
            placement: direction,
            push: false,
          }
        : {
            height: document.body.clientHeight,
            placement: direction,
            push: false,
          })}
      closeIcon={null}
      zIndex={10000}
      rootStyle={{ isolation: 'isolate', position: 'absolute', top: 0, bottom: 0 }}
      headerStyle={{ display: 'none' }}
      drawerStyle={{ background: 'transparent' }}
      bodyStyle={{ padding: 0, background: 'white' }}
      maskStyle={{ background: 'transparent' }}
      contentWrapperStyle={{
        overflow: 'hidden',
        ...(sz !== 'sm' && {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        }),
      }}
      destroyOnClose
    >
      <DrawerProvider setChildrenWidth={setChildrenWidth}>
        <div className={cn('isolate h-full', direction === 'right' && 'w-fit')} ref={setContentRef}>
          {children}
        </div>
      </DrawerProvider>
    </BaseDrawer>
  )
}
