/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QboAccountStatus = (typeof QboAccountStatus)[keyof typeof QboAccountStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QboAccountStatus = {
  new: 'new',
  linked: 'linked',
} as const
