import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Lightning({
  width,
  height = 12,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.55742 0.520593C3.73582 0.578913 3.85714 0.750488 3.85714 0.944473V3.16668L5.57143 3.16668C5.73123 3.16668 5.87776 3.25888 5.95156 3.40587C6.02536 3.55286 6.01417 3.73022 5.92253 3.86599L2.92253 8.3104C2.81526 8.46932 2.62099 8.53773 2.44258 8.47941C2.26418 8.42109 2.14286 8.24951 2.14286 8.05553L2.14286 5.83332H0.428572C0.268769 5.83332 0.122238 5.74112 0.0484389 5.59413C-0.0253601 5.44714 -0.0141682 5.26978 0.0774732 5.13401L3.07747 0.689603C3.18474 0.530684 3.37901 0.462273 3.55742 0.520593Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
