import { useRef, useEffect } from 'react'
import enUS from './en-US.json'
import esMX from './es-MX.json'
import { TransAdaptor } from './TransAdaptor'

const regionTranslations = {
  'en-us': enUS,
  'en-gb': enUS,
  'es-mx': esMX,
  'es-us': esMX,
  'es-es': esMX,
  'es-419': esMX,
  'es-co': esMX,
  // non-supported
  'cs-cz': enUS,
  'ru-ru': enUS,
}
const langTranslations = {
  en: enUS,
  es: esMX,
  // non-supported
  ru: enUS,
  cs: enUS,
}
const lang = typeof navigator !== 'undefined' ? navigator.language.toLowerCase() : 'en-us'

function isSupportedRegion(val: string): val is keyof typeof regionTranslations {
  return val in regionTranslations
}

function isSupportedLanguage(val: string): val is keyof typeof langTranslations {
  return val in langTranslations
}

type StatusReportProps = {
  onError: (context: { message: string }) => void
}
export function StatusReport({ onError }: StatusReportProps) {
  const onErrorRef = useRef(onError).current

  useEffect(() => {
    if (!isSupportedLanguage(lang.slice(0, 2))) {
      onErrorRef({ message: `Unsupported lang: ${lang.slice(0, 2)}` })
    } else {
      if (!isSupportedLanguage(lang) && !isSupportedRegion(lang)) {
        onErrorRef({ message: `Unsupported region: ${lang}` })
      }
    }
  }, [onErrorRef])
  return <></>
}

function translateOrDefault(key: string): string {
  if (isSupportedRegion(lang)) {
    const translations = regionTranslations[lang]
    if (key in translations) {
      return (translations as any)[key] ?? key
    }
  } else {
    const langWithoutRegion = lang.slice(0, 2)
    if (isSupportedLanguage(langWithoutRegion)) {
      const translations = langTranslations[langWithoutRegion]
      if (key in translations) {
        return (translations as any)[key] ?? key
      }
    }
  }
  return key
}

type InterpolatedValue = string | number
type InterpolationVar = `X${number | ''}`

type InterpolationProps<T extends string> = T extends `${string}{{ ${infer Var extends
  InterpolationVar} }}${infer Tail extends string}`
  ? { [K in Var]: InterpolatedValue } & InterpolationProps<Tail>
  : {}

type TProps<T extends string> = Record<string, never> extends InterpolationProps<T>
  ? [T]
  : [T, InterpolationProps<T>]

type TAutofills = keyof typeof enUS

export function t<T extends TAutofills | (string & {})>(...props: TProps<T>) {
  const translation = translateOrDefault(props[0])

  if (props.length === 1) return translation
  else {
    let result = translation
    const values = props[1]
    Object.keys(values).forEach((k) => {
      const value = (values as any)[k]
      result = result.replaceAll(`{{ ${k} }}`, value)
    })
    return result
  }
}

type TagOpen<T extends string> = `<${T}>`
type TagClose<T extends string> = `</${T}>`

type NodeInterpolationProps<T extends string> = T extends `${string}${TagOpen<
  infer Tag
>}${infer Tail1 extends string}`
  ? Tail1 extends `${infer Child extends string}${TagClose<Tag>}${infer Tail2 extends string}`
    ? { [K in Tag]: JSX.Element } & NodeInterpolationProps<Tail2> & NodeInterpolationProps<Child>
    : never
  : {}

type TNodeProps2<T extends string> = NodeInterpolationProps<T> &
  ({
    tKey: T
  } & (InterpolationProps<T> extends Record<string, never>
    ? { values?: undefined }
    : {
        values: InterpolationProps<T>
      }))

// Note: nested interpolation don't work (see https://github.com/i18next/react-i18next/issues/928)
export function Trans<T extends string>({ tKey, values, ...components }: TNodeProps2<T>) {
  const translation = translateOrDefault(tKey)
  let result = translation
  if (values) {
    Object.keys(values).forEach((k) => {
      const value = (values as any)[k]
      result = result.replaceAll(`{{ ${k} }}`, value)
    })
  }
  return (
    <span>
      <TransAdaptor tKey={result} components={components as Record<string, JSX.Element>} />
    </span>
  )
}
