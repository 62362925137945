import { useCallback } from 'react'
import { CompassTypes } from 'compass-local'
import { useCompassContext } from 'compass-local/CompassProvider/utils'
import { useNavigate } from 'utils/router'

export namespace Link2Utils {
  export type LinkTypeProps =
    | {
        external: true
        href: string
      }
    | {
        external?: false
        href: CompassTypes['href']
      }

  export function usePush() {
    const navigate = useNavigate()
    const { shouldHardNavigate: shouldHardNavigateFn } = useCompassContext()
    return useCallback(
      async (href: CompassTypes['href']) => {
        await navigate(`${window.location.pathname}${window.location.search}`, { replace: true })
        const shouldHardNavigate = shouldHardNavigateFn?.()
        if (shouldHardNavigate) {
          window.location.href = href
        } else {
          navigate(href)
        }
      },
      [navigate, shouldHardNavigateFn],
    )
  }

  export function useReplace() {
    const navigate = useNavigate()
    return useCallback(
      (href: CompassTypes['href']) => {
        navigate(href, { replace: true })
      },
      [navigate],
    )
  }
}
