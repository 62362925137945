import { MSObject } from 'msutils'
import { t } from 'content'
import { AxiosError } from 'axios'

type ValidationErrorProps = {
  nonFieldErrors?: string[]
  fieldErrors?: { [key: string]: string[] }
}
export class ValidationError extends Error {
  nonFieldErrors?: string[]

  fieldErrors?: { [key: string]: string[] }

  constructor(message: string, props: ValidationErrorProps) {
    super(message)
    this.nonFieldErrors = props.nonFieldErrors
    this.fieldErrors = props.fieldErrors
  }
}

export class TimeoutError extends Error {}

function isAxiosErrorInRange(e: any, range: [lower: number, upper: number]) {
  const statusCode = e.response?.status
  if (typeof statusCode !== 'number') return false

  return e?.name === 'AxiosError' && statusCode >= range[0] && statusCode <= range[1]
}

export const isAxios4XX = (e: Error): e is AxiosError<any> => isAxiosErrorInRange(e, [400, 499])

function findFieldError(obj: any, isTopLevel: boolean): string {
  return Object.keys(obj).flatMap((x) => {
    const key = x
      .split('_')
      .map((y: any) => `${y[0].toUpperCase()}${y.slice(1)}`)
      .join(' ')

    const value = typeof obj[x] === 'string' ? obj[x] : obj[x][0]

    if (typeof value === 'string') {
      return isTopLevel ? value : `${key} > ${value}`
    } else if (typeof value === 'object') {
      const nestedError = findFieldError(value, false)
      if (nestedError) return `${key} > ${nestedError}`
      else return []
    } else {
      return []
    }
  })[0]
}

export function get400Message(e: any) {
  if (isAxios4XX(e)) {
    const { non_field_errors: nonFieldErrors, ...fieldErrors } = e.response?.data ?? {}
    let arbitraryFieldError: string | null = null
    try {
      arbitraryFieldError = findFieldError(fieldErrors, true)
    } catch (err: any) {
      //
    }
    const printableFieldError =
      arbitraryFieldError && typeof arbitraryFieldError === 'string' ? arbitraryFieldError : null
    return nonFieldErrors?.at(0) ?? printableFieldError ?? t('Validation error')
  }

  return 'Unexpected error'
}

function extractErrorArray(o: any): string[] {
  if (typeof o === 'string') {
    return [o]
  }
  if (Array.isArray(o)) {
    return o.flatMap((oo) => extractErrorArray(oo))
  }
  if (typeof o === 'object') {
    let output = [] as any[]
    // eslint-disable-next-line
    for (const key in o) {
      if (Object.prototype.hasOwnProperty.call(o, key)) {
        output = output.concat(extractErrorArray(o[key]))
      }
    }
    return output
  }
  return []
}

/**
 * mapErrorsToInputKey
 * - In many cases, an action will have an input that does not exactly match the name of the field
 * getting sent to the backend.
 */
export async function mapErrorsToInputKey<T>(
  p: Promise<T>,
  mapping: { [key: string]: string } | string,
): Promise<T> {
  try {
    return await p
  } catch (e: any) {
    if (isAxios4XX(e)) {
      const { non_field_errors: nonFieldErrors, ...fieldErrorsSnake } = e.response?.data ?? {}
      const transformedFieldErrors = {} as any
      // eslint-disable-next-line
      for (const key in fieldErrorsSnake) {
        if (Object.prototype.hasOwnProperty.call(fieldErrorsSnake, key)) {
          const remappedKey = typeof mapping === 'string' ? mapping : mapping[key] ?? key
          const errorForKey = fieldErrorsSnake[key]
          if (typeof errorForKey === 'string') {
            transformedFieldErrors[remappedKey] = errorForKey
          } else {
            transformedFieldErrors[remappedKey] = extractErrorArray(errorForKey)
          }
        }
      }
      throw new ValidationError(nonFieldErrors?.at(0) ?? t('Validation error'), {
        nonFieldErrors,
        fieldErrors: MSObject.toCamel(transformedFieldErrors),
      })
    }
    throw e
  }
}
