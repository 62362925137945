import { MSDate } from '../date'
import { Cell, CellField } from './base'
import { FieldError } from './utils'

type Date<V> = CellField<MSDate.Date | null, V>

type DateProps<TReq extends boolean> = {
  required?: TReq
  initValue?: MSDate.Date
}

export function Date<TReq extends boolean = false>(
  props?: DateProps<TReq>,
): Date<TReq extends true ? MSDate.Date : MSDate.Date | null> {
  const validate: any = (x: MSDate.Date | null) => {
    if (props?.required && x === null) {
      throw new FieldError('Required')
    } else {
      return x
    }
  }

  return Cell({
    initValue: props?.initValue ?? null,
    validate,
  })
}
