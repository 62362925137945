/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayeeContactCreateContactType =
  (typeof PayeeContactCreateContactType)[keyof typeof PayeeContactCreateContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayeeContactCreateContactType = {
  homeowner: 'homeowner',
  contractor: 'contractor',
  developer: 'developer',
} as const
