import { ReactNode } from 'react'
import { getAuth } from 'root/utils'

export type DevtoolsModuleInput<Id extends string> = {
  id: Id
  name?: string
  showRule?: 'authed-only' | 'unauthed-only' | 'always'
  component: ReactNode
}

export type DevtoolsModule<Id extends string> = {
  id: Id
  name: string
  showRule: 'authed-only' | 'unauthed-only' | 'always'
  component: ReactNode
}

export function defineModule<T extends string>(m: DevtoolsModuleInput<T>): DevtoolsModule<T> {
  return { showRule: 'always', name: m.id, ...m }
}

export function defineModules<M extends DevtoolsModule<any>[]>(values: M) {
  return values
}

export function getIsAuthed() {
  try {
    return getAuth() !== null
  } catch (e) {
    return false
  }
}
