/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AddressTypeRichType = (typeof AddressTypeRichType)[keyof typeof AddressTypeRichType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressTypeRichType = {
  rich: 'rich',
} as const
