import * as Cb from '../../client'
import { text } from './base'
import { config } from './config'

const DefaultPassword = 'wemberly'

type CreateUserProps = {
  props?: Partial<{
    email: string
    fullName: string
  }>
}

export async function createUser({ props }: CreateUserProps) {
  const user = await Cb.createUser(
    {
      full_name: props?.fullName ?? 'Thomas C',
      email: props?.email ?? `thomas+test-${text(24)}@usebeam.co`,
      password: DefaultPassword,
    },
    config(),
  )
  return user
}

type CreateLoginProps = {
  props: {
    email: string
  }
}

export async function createLogin({ props }: CreateLoginProps) {
  const login = await Cb.createLogin(
    {
      email: props.email,
      password: DefaultPassword,
    },
    config(),
  )
  return login
}
