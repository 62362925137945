import { ReactNode } from 'react'
import { MSCurrency } from '../currency'
import { MSDate } from '../date'
import { unreachable } from '../misc'
import { MSPercentage } from '../percentage'
import { MSPhone } from '../phone'

type GenericProps = {
  empty?: ReactNode
}

type CurrencyProps = GenericProps & {
  sign?: boolean
}

type DateProps = GenericProps & {
  form?: MSDate.DateFormat
}

type ValueProps =
  | [value: ReactNode, type?: 'raw', props?: GenericProps]
  | [value: number | string | null, type: 'currency', props?: CurrencyProps]
  | [value: string | null, type: 'percent', props?: GenericProps]
  | [
      value: [numerator: string | number | null, denominator: string | number | null],
      type: 'ratio',
      props?: GenericProps,
    ]
  | [value: string | MSDate.Date | null, type: 'date', props?: DateProps]
  | [value: string | null, type?: 'decimal', props?: GenericProps]
  | [value: string | null, type: 'phone', props?: GenericProps]

function empty(...args: ValueProps) {
  switch (args[1]) {
    case 'raw':
    case undefined:
      return args[2]?.empty ?? '--'
    case 'ratio':
      return args[2]?.empty ?? '--'
    case 'currency':
      return args[2]?.empty ?? '$ --'
    case 'decimal':
      return args[2]?.empty ?? '--'
    case 'percent':
      return args[2]?.empty ?? '-- %'
    case 'date':
      return args[2]?.empty ?? '--'
    case 'phone':
      return args[2]?.empty ?? '--'
    default:
      return unreachable(args[1])
  }
}

export function format(...args: ValueProps): ReactNode {
  if (args[0] === null || args[0] === '') return empty(...args)

  switch (args[1]) {
    case undefined:
    case 'raw':
      return args[0]
    case 'currency':
      return MSCurrency.format(args[0], args[2])
    case 'decimal':
      return parseFloat(args[0]).toString()
    case 'percent':
      return MSPercentage.format(args[0])
    case 'ratio':
      if (args[0][0] === null || args[0][1] === null) return '$ -- (-- %)'
      if (Number(args[0][0]) === 0 && Number(args[0][1]) === 0) return '--'
      return `${MSCurrency.format(args[0][0])} (${MSPercentage.format(
        Number(args[0][0]) / Number(args[0][1]),
      )})`
    case 'date':
      return MSDate.format(args[0], args[2])
    case 'phone':
      return MSPhone.format(args[0])
    default:
      return unreachable(args[1])
  }
}
