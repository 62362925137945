import { setBaseUrl, getBaseUrl } from '../generator-utils'

export function init(baseUrl: string) {
  setBaseUrl(baseUrl)
}

export function config(auth?: string) {
  return {
    baseURL: getBaseUrl(),
    headers: {
      Authorization: auth,
    },
  }
}
