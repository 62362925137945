/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceListRowPaymentState =
  (typeof InvoiceListRowPaymentState)[keyof typeof InvoiceListRowPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceListRowPaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
