import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'

type CardVariant = 'white' | 'slate' | 'cool' | 'orange' | 'yellow'

type Props = {
  className?: string
  variant?: CardVariant
  border?: boolean
  noRounded?: boolean
  children: ReactNode
  disablePadding?: boolean
}

function getBackgroundForVariant(variant: CardVariant) {
  switch (variant) {
    case 'white':
      return 'bg-th-bg-white'
    case 'slate':
      return 'bg-th-bg-slate'
    case 'cool':
      return 'bg-th-coolgrey-3'
    case 'orange':
      return 'bg-th-orange-light2'
    case 'yellow':
      return 'bg-th-yellow-light1'
    default:
      return unreachable(variant)
  }
}

export default function Card({
  variant = 'slate',
  border = false,
  noRounded,
  className,
  children,
  disablePadding = false,
}: Props) {
  return (
    <div
      className={cn(
        !disablePadding && 'p-5',
        !noRounded && 'rounded-2',
        getBackgroundForVariant(variant),
        border && 'border border-th-warmgrey-1',
        className,
      )}
    >
      {children}
    </div>
  )
}
