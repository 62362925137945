/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExternalTransferType =
  (typeof BeamBalanceTransactionTypeExternalTransferType)[keyof typeof BeamBalanceTransactionTypeExternalTransferType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExternalTransferType = {
  external_transfer: 'external_transfer',
} as const
