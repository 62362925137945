/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GetInvoiceApprovalWorkflowRequirementsViewOutputRequirementsItemType =
  (typeof GetInvoiceApprovalWorkflowRequirementsViewOutputRequirementsItemType)[keyof typeof GetInvoiceApprovalWorkflowRequirementsViewOutputRequirementsItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInvoiceApprovalWorkflowRequirementsViewOutputRequirementsItemType = {
  admin_approval_required: 'admin_approval_required',
  project_manager_approval_required: 'project_manager_approval_required',
} as const
