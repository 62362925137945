import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Link({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8281 8.17198C12.078 7.4221 11.0607 7.00084 10.0001 7.00084C8.93942 7.00084 7.92219 7.4221 7.17208 8.17198L3.17208 12.172C2.79004 12.541 2.48531 12.9823 2.27567 13.4704C2.06604 13.9584 1.95569 14.4833 1.95108 15.0144C1.94646 15.5455 2.04767 16.0722 2.24879 16.5638C2.44992 17.0554 2.74693 17.502 3.1225 17.8776C3.49807 18.2531 3.94468 18.5501 4.43626 18.7513C4.92785 18.9524 5.45457 19.0536 5.98569 19.049C6.5168 19.0444 7.04168 18.934 7.5297 18.7244C8.01771 18.5148 8.45909 18.21 8.82808 17.828L9.93008 16.727M9.17208 11.828C9.92219 12.5779 10.9394 12.9991 12.0001 12.9991C13.0607 12.9991 14.078 12.5779 14.8281 11.828L18.8281 7.82798C19.5567 7.07357 19.9599 6.06316 19.9508 5.01438C19.9417 3.96559 19.521 2.96234 18.7794 2.22071C18.0377 1.47907 17.0345 1.0584 15.9857 1.04928C14.9369 1.04017 13.9265 1.44335 13.1721 2.17198L12.0721 3.27198"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
