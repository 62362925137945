import { Cb } from 'cb'
import { t } from 'content'
import { Module } from 'modules/routes'
import Typography from 'compass/data/Typography'
import { Action } from 'utils/actions'

type Props = {
  details: NonNullable<Cb.TaskSignConditionalLienWaiverDetails>
  close: () => void
}

export default function SignConditionalLienWaiver({ details, close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>
        {t('Sign the conditional lien waiver for Invoice #{{ X1 }} to receive payment.', {
          X1: details.invoice_number,
        })}
      </Typography>
      <Action.Mount
        {...Action.href(t('Sign'), {
          theme: 'orange-text',
          href: Module(`/invoices/${details.invoice_id}`).href,
          onClick: close,
        })}
      />
    </div>
  )
}
