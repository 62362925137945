import BigNumber from 'bignumber.js'

export * from './format'

export function xor(a: boolean, b: boolean) {
  return (a || b) && a !== b
}

export function xnor(a: boolean, b: boolean) {
  return !xor(a, b)
}

// eslint-disable-next-line mosaic-js/no-bignumber-zero
export const Zero = BigNumber(0)

export function random(length: number = 14) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function capitalize(val: string) {
  if (val.length === 0) {
    return val
  } else {
    return `${val[0].toUpperCase()}${val.slice(1)}`
  }
}

export function toSentenceCase(val: string) {
  return capitalize(
    Array.from(val).reduce((p, c) => {
      if (c === '_') {
        return `${p} `
      } else if (c.toUpperCase() === c) {
        return `${p} ${c.toLowerCase()}`
      } else {
        return `${p}${c}`
      }
    }, ''),
  )
}
