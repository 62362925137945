/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeMilestoneInvoiceType =
  (typeof BillTypeMilestoneInvoiceType)[keyof typeof BillTypeMilestoneInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeMilestoneInvoiceType = {
  milestone: 'milestone',
} as const
