export function eqIgnoreCase(a: string, b: string) {
  return a.toLowerCase() === b.toLowerCase()
}

export function includesIgnoreCase(value: string, substring: string) {
  return value.toLowerCase().includes(substring.toLowerCase())
}

export function isEmptyStringOrIncludes(value: string, substring: string) {
  return substring === '' || includesIgnoreCase(value, substring)
}

export function addTag(tag: string | null, target: string) {
  if (tag) {
    return `[${tag}] ${target}`
  } else {
    return target
  }
}
