/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CashBackPaymentState = (typeof CashBackPaymentState)[keyof typeof CashBackPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CashBackPaymentState = {
  new: 'new',
  pending: 'pending',
  complete: 'complete',
  failed: 'failed',
} as const
