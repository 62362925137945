/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CompanyCamAccountStatus =
  (typeof CompanyCamAccountStatus)[keyof typeof CompanyCamAccountStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCamAccountStatus = {
  new: 'new',
  linked: 'linked',
} as const
