import { Cb } from 'cb'
import { t } from 'content'
import Link2 from 'compass-local/Link2'
import { Module } from 'modules/routes'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import Typography from 'compass/data/Typography'

type Props = {
  details: NonNullable<Cb.TaskActionApprovedEstimateDetails>
  close: () => void
}

export default function ActionApprovedEstimate({ details, close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>
        {t('Estimate for {{ X1 }} has been approved by {{ X2 }}.', {
          X1: details.estimate_display_name,
          X2: details.estimate_payer_name,
        })}
      </Typography>
      <Link2 href={Module(`/estimates/${details.estimate_id}`).href}>
        <Button theme={theme2.ButtonThemeTextOrange} onClick={close}>
          {t('View estimate')}
        </Button>
      </Link2>
    </div>
  )
}
