import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import useFormProps from 'utils/useFormProps'
import { Cb, Q } from 'cb'
import { Arrow, X } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import { t } from 'content'
import OnboardAccountForm2 from 'components/forms/OnboardAccountForm2'
import { unreachable } from 'msutils/misc'
import { Link2Utils } from 'compass-local/Link2'
import { Module } from 'modules/routes'

function useQueries() {
  return Q.group({
    bankAccounts: Cb.useListBankAccounts({ params: { archived: false }, select: Q.all }),
    payerEnabled: Cb.useWhoAmI({ select: (data) => data.payer_enabled }),
    onboardingSurvey: Cb.useListOnboardingSurveys({ select: Q.opt }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
  })
}

export default function ActivateAccountBanner() {
  const sz = useScreenSize()
  const queryset = useQueries()
  const onboardingFormProps = useFormProps()
  const push = Link2Utils.usePush()
  const updateOnboardingSurvey = Cb.usePartialUpdateOnboardingSurveyHook()

  if (queryset.status !== 'success') return null

  const { bankAccounts, payerEnabled, onboardingSurvey, cardProgram } = queryset.queries
  const wantsBeamCard = onboardingSurvey?.wants_beam_card ?? false
  const needsBankAccount = bankAccounts.filter((ba) => ba.inbound_transfers_enabled).length === 0
  const variant =
    wantsBeamCard &&
    cardProgram?.status !== 'ineligible' &&
    cardProgram?.status !== 'unavailable_in_state'
      ? 'card'
      : 'normal'
  const cardAppCompleted =
    cardProgram &&
    (cardProgram.status === 'approved' ||
      cardProgram.status === 'processing' ||
      cardProgram.status === 'declined' ||
      cardProgram.status === 'pending' ||
      cardProgram.status === 'closed' ||
      cardProgram.status === 'unavailable_in_state')

  if (!payerEnabled) return null

  switch (variant) {
    case 'normal':
      return needsBankAccount ? (
        <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-center md:-mb-2">
          <div className="w-full max-w-[1280px] flex justify-between items-center gap-3">
            <Typography className="text-th-brown-1" variant={sz === 'sm' ? 'caption' : 'body'}>
              {t('Activate your account to send and receive payments.')}
            </Typography>
            <LinkButtonDONOTUSE
              variant="unstyled"
              onClick={onboardingFormProps.setActive}
              endIcon={<Arrow height={14} className="text-th-brown-2 rotate-90" />}
            >
              {sz !== 'sm' && (
                <Typography variant="bodybold" className="text-th-brown-1">
                  {t('Activate account')}
                </Typography>
              )}
            </LinkButtonDONOTUSE>
            <OnboardAccountForm2 {...onboardingFormProps} />
          </div>
        </div>
      ) : null
    case 'card':
      return needsBankAccount ? (
        <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-center md:-mb-2">
          <div className="w-full max-w-[1280px] flex justify-between items-center gap-3">
            <Typography className="text-th-brown-1" variant={sz === 'sm' ? 'caption' : 'body'}>
              {t('Step 1 of 2: Validate your business information before applying for the card.')}
            </Typography>
            <LinkButtonDONOTUSE
              variant="unstyled"
              onClick={onboardingFormProps.setActive}
              endIcon={<Arrow height={14} className="text-th-brown-2 rotate-90" />}
            >
              {sz !== 'sm' && (
                <Typography variant="bodybold" className="text-th-brown-1">
                  {t('Validate business')}
                </Typography>
              )}
            </LinkButtonDONOTUSE>
            <OnboardAccountForm2 {...onboardingFormProps} />
          </div>
        </div>
      ) : !cardAppCompleted ? (
        <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-center md:-mb-2">
          <div className="w-full max-w-[1280px] flex justify-between items-center gap-3">
            <Typography className="text-th-brown-1" variant={sz === 'sm' ? 'caption' : 'body'}>
              {t('Step 2 of 2: Apply for the Beam Card! Start your card application.')}
            </Typography>
            <div className="flex gap-5 md:gap-10 items-center">
              <LinkButtonDONOTUSE
                variant="unstyled"
                onClick={() => push(Module('/expense-cards').href)}
                endIcon={<Arrow height={14} className="text-th-brown-2 rotate-90" />}
              >
                {sz !== 'sm' && (
                  <Typography variant="bodybold" className="text-th-brown-1">
                    {t('Start card application')}
                  </Typography>
                )}
              </LinkButtonDONOTUSE>
              <div className="cursor-pointer text-th-brown-2">
                <X
                  height={12}
                  thickness={2.2}
                  onClick={() => {
                    if (onboardingSurvey) {
                      updateOnboardingSurvey(onboardingSurvey.id, { wants_beam_card: false })
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null
    default:
      return unreachable(variant)
  }
}
