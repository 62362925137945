import * as Cb from '../../client'
import { text } from './base'
import { config } from './config'
import * as UserUtils from './user'

type CreateBusinessProps = {
  props?: Partial<{
    name: string
    address: string
  }>
  auth: string
}

export function createBusiness({ props, auth }: CreateBusinessProps) {
  return Cb.createBusiness(
    {
      name: props?.name ?? "Thomas's Rocks",
      address_2: {
        type: 'raw',
        rich_details: null,
        raw_details: {
          raw_address: props?.address ?? 'Yolo County',
        },
      },
    },
    config(auth),
  )
}

type OnboardBusinessProps = {
  business: Cb.Business
  auth: string
  noFakeStripe?: boolean
}

export async function onboardBusiness({ business, auth, noFakeStripe }: OnboardBusinessProps) {
  if (!noFakeStripe) {
    await Cb.setOnboardingPushedToStripe({}, config(auth))
  }

  const employee = await Cb.listEmployees(undefined, config(auth)).then((res) => res.results[0])

  const [businessProfileOpt, employeeProfileOpt, bankAccount, termsOfServices] = await Promise.all([
    Cb.listBusinessProfiles({}, config(auth)),
    Cb.listEmployeeProfiles({}, config(auth)),
    Cb.createBankAccount(
      {
        business_id: business.id,
        routing_number: '110000000',
        account_number: '000123456789',
      },
      config(auth),
    ),
    Cb.listTermsOfServices({}, config(auth)),
    Cb.setPayerEnabled({ business_id: business.id }, config(auth)),
  ])

  const createBusinessProfileOpt =
    businessProfileOpt.results.length === 0
      ? Cb.createBusinessProfile(
          {
            business_id: business.id,
            legal_name: "Thomas's Rocks",
            dba_name: "Thomas's Rocks",
            structure: 'private_corporation',
            mcc: '5045', // computers_peripherals_and_software
            address: {
              type: 'rich',
              rich_details: {
                line_1: '19 Walter St',
                line_2: '',
                city: 'San Francisco',
                zip_code: '94114',
                state: 'NY', // Expense card is not available in CA so don't use that
              },
              raw_details: null,
            },
            product_description: 'testing services',
            phone: '2345678910',
            url: 'www.trybeam.com',
            // These values are specifically chosen to ensure that Stripe verification succeeds:
            // https://stripe.com/docs/connect/testing
            ein: '000000000',
          },
          config(auth),
        )
      : Promise.resolve(null)

  const createEmployeeProfileOpt =
    employeeProfileOpt.results.length === 0
      ? Cb.createEmployeeProfile(
          {
            business_id: business.id,
            line_1: '19 Walter St',
            line_2: null,
            city: 'San Francisco',
            zip_code: '94114',
            state: 'CA',
            title: 'head honcho',
            identity_verification_file_id: null,
            ownership_multiplier: '1.00',
            phone: '2345678910',
            // These values are specifically chosen to ensure that Stripe verification succeeds:
            // https://stripe.com/docs/connect/testing
            dob: '1901-01-01',
            ssn: '000000000',
            email: employee.email ?? '',
            is_representative: true,
            first_name: 'First',
            last_name: 'Last',
          },
          config(auth),
        )
      : Promise.resolve(null)

  const setInboundTransferEnabledP = Cb.setInboundTransfersEnabled(
    { bank_account_id: bankAccount.id },
    config(),
  )

  await Promise.all([
    Promise.all(
      termsOfServices.results.map((tos) =>
        Cb.createTermsOfServiceAcceptance(
          { business_id: business.id, terms_of_service_id: tos.id },
          config(auth),
        ),
      ),
    ),
    createBusinessProfileOpt,
    createEmployeeProfileOpt,
    setInboundTransferEnabledP,
  ])
}

type CreateVendor = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    email: string
    name: string
    phone: string
    address: string
  }>
}

export async function createVendor({ props, business, auth }: CreateVendor) {
  const vendor = await Cb.createPayeeContact(
    {
      name: props?.name ?? 'Thomas Electric',
      email: props?.email ?? `thomas+payee-${text(24)}@usebeam.co`,
      cc_emails: [],
      contact_type: 'contractor',
      phone: props?.phone ?? '3333333333',
      address_2: {
        type: 'raw',
        raw_details: {
          raw_address: props?.address ?? '123 Main St',
        },
        rich_details: null,
      },
      payer_id: business.id,
    },
    config(auth),
  )

  return vendor
}

type CreateClientProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    email: string
    name: string
    phone: string
    address: string
  }>
}

export async function createClient({ props, business, auth }: CreateClientProps) {
  const client = await Cb.createPayerContact(
    {
      name: props?.name ?? `Thomas Electric (${text(8)})`,
      email: props?.email ?? `thomas+payee-${text(24)}@usebeam.co`,
      cc_emails: [],
      contact_type: 'contractor',
      phone: props?.phone ?? '3333333333',
      address_2: {
        type: 'raw',
        raw_details: {
          raw_address: props?.address ?? '123 Main St',
        },
        rich_details: null,
      },
      payee_id: business.id,
    },
    config(auth),
  )

  return client
}

type SetupBusinessProps = {
  props?: Partial<{
    email: string
    userFullName: string
    businessName: string
    businessAddress: string
  }>
}

export async function setupBusiness({ props }: SetupBusinessProps) {
  const user = await UserUtils.createUser({
    props: {
      email: props?.email,
      fullName: props?.userFullName,
    },
  })

  if (!user.email) throw new Error('Created user is missing an email')

  const login = await UserUtils.createLogin({
    props: { email: user.email },
  })

  const auth = `Token ${login.session_token}`

  const business = await createBusiness({
    props: { name: props?.businessName, address: props?.businessAddress },
    auth,
  })

  return { auth, business, user }
}
