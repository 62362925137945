/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExternalTransferDirection =
  (typeof BeamBalanceTransactionTypeExternalTransferDirection)[keyof typeof BeamBalanceTransactionTypeExternalTransferDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExternalTransferDirection = {
  debit: 'debit',
  credit: 'credit',
} as const
