/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ClientListRowContactType =
  (typeof ClientListRowContactType)[keyof typeof ClientListRowContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientListRowContactType = {
  homeowner: 'homeowner',
  contractor: 'contractor',
  developer: 'developer',
} as const
