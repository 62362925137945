import { ReactNode } from 'react'
import { MSArray, MSDate } from 'msutils'
import { unreachable } from 'msutils/misc'
import { z } from 'zod'

export const DateFilterSpec = z
  .object({
    type: z.literal('date-range'),
    start: z.string().nullable(),
    end: z.string().nullable(),
  })
  .default({ type: 'date-range', start: null, end: null })

export type TypedFilterSpec =
  | string[]
  | {
      type: 'date-range'
      start: string | null
      end: string | null
    }

export type SomeFilterInput = {
  [key: string]: TypedFilterSpec
}

export type EnumFilterLabelConfig = string | { name: string; hide?: boolean }

type BaseConfig = {
  name: ReactNode
  hide?: boolean
}

export type SubConfigForFilter<T extends TypedFilterSpec> = T extends string[]
  ? BaseConfig & {
      labels: {
        [K in T[number]]: string | { name: string; hide?: boolean }
      }
    }
  : BaseConfig & {
      showFutureDatePresets?: boolean
      hidePastDatePresets?: boolean
    }

export type ConfigForFilter<T extends SomeFilterInput> = {
  [K in keyof T]: SubConfigForFilter<T[K]>
}

export function getLabelForFilter<T extends TypedFilterSpec>(
  val: T,
  config: SubConfigForFilter<T>,
) {
  if (Array.isArray(val)) {
    if ('labels' in config) {
      return MSArray.print(
        val.map((s) => {
          const labelDef = config.labels[s]
          if (typeof labelDef === 'string') return labelDef
          return labelDef.name
        }),
      )
    } else {
      // TODO: unreachable
      return null
    }
  } else if (val.type === 'date-range') {
    if (val.start && val.end) {
      return MSDate.formatRange(val.start, val.end)
    } else {
      return null
    }
  } else {
    return unreachable(val.type)
  }
}

export function emptyState<T extends TypedFilterSpec>(val: T): T {
  if (Array.isArray(val)) {
    return [] as any
  } else if (val.type === 'date-range') {
    return { type: 'date-range', start: null, end: null } as any
  } else {
    return unreachable(val.type)
  }
}
