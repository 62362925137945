/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EmployeeCreateRole = (typeof EmployeeCreateRole)[keyof typeof EmployeeCreateRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeCreateRole = {
  admin: 'admin',
  member: 'member',
  project_manager: 'project_manager',
  estimator: 'estimator',
  cardholder: 'cardholder',
} as const
