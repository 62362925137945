import { ReactNode } from 'react'
import BaseLink from 'next/link'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'
import Typography from 'compass/data/Typography'
import { Arrow } from 'compass-local/legacy/icons'
import { withPassthrough } from 'compass-local/legacy/hocs'

type VariantSpecificProps =
  | {
      variant?: 'default' | 'beam-color' | 'bordered'
      settings?: Partial<{
        hideArrow: boolean
        shouldOpenInNewTab: boolean
      }>
    }
  | {
      variant: 'back' | 'unstyled'
      settings?: Partial<{
        shouldOpenInNewTab: boolean
      }>
    }

type Props = VariantSpecificProps & {
  href: string
  className?: string
  children?: ReactNode
}

function Link({ href, className, settings, children, variant }: Props) {
  const target = settings?.shouldOpenInNewTab ? '_blank' : undefined
  switch (variant) {
    case undefined:
    case 'default':
      return (
        <BaseLink
          href={href}
          target={target}
          className={cn(
            'flex gap-2 items-center text-th-brown-1 hover:text-th-brown-2 w-fit',
            className,
          )}
        >
          <Typography variant="bodybold">{children}</Typography>
          {!settings?.hideArrow && <Arrow className="text-th-brown-2 rotate-90" />}
        </BaseLink>
      )
    case 'beam-color':
      return (
        <BaseLink
          href={href}
          target={target}
          className={cn(
            'flex gap-2 items-center text-th-orange-dark1 hover:text-th-orange-dark1 w-fit',
            className,
          )}
        >
          <Typography variant="bodybold">{children}</Typography>
          {!settings?.hideArrow && <Arrow className="text-th-orange-beam rotate-90" />}
        </BaseLink>
      )
    case 'back':
      return (
        <BaseLink
          href={href}
          target={target}
          className={cn(
            'flex gap-2 items-center text-th-brown-1 hover:text-th-brown-2 w-fit',
            className,
          )}
        >
          <Arrow className="text-th-brown-2 -rotate-90" />
          <Typography variant="bodybold">{children}</Typography>
        </BaseLink>
      )
    case 'unstyled':
      return (
        <BaseLink
          href={href}
          target={target}
          className={cn('text-th-text hover:text-th-text active:text-th-text', className)}
        >
          {children}
        </BaseLink>
      )
    case 'bordered':
      return (
        <BaseLink
          href={href}
          target={target}
          className={cn(
            'flex py-2 px-2 gap-2 justify-center text-th-brown-1 border-2 border-th-brown-2 rounded-2 h-fit hover:text-th-brown-1',
            className,
          )}
        >
          <Typography variant="bodybold">{children}</Typography>
          {!settings?.hideArrow && <Arrow className="text-th-brown-2 rotate-90" />}
        </BaseLink>
      )
    default:
      return unreachable(variant)
  }
}

export default withPassthrough(Link)
