import { unreachable } from 'msutils/misc'
import { Cb } from 'cb'
import { t } from 'content'
import MicrodepositCodeVerificationInput, {
  MicrodepositCodeVerificationInputUtils,
} from 'components/inputs/MicrodepositCodeVerificationInput'
import { useFormState } from 'utils/form-input'
import MicrodepositAmountsVerificationInput, {
  MicrodepositAmountsVerificationInputUtils,
} from 'components/inputs/MicrodepositAmountsVerificationInput'

type Props = {
  requirement: NonNullable<Cb.BankAccountMicrodepositRequirement>
  onSuccess?: () => void
}

function CodeVerification({ requirement, onSuccess }: Props) {
  const createMicrodepositAttempt = Cb.useCreateMicrodepositAttemptHook()

  const { state, useValidatedAction } = useFormState(
    MicrodepositCodeVerificationInputUtils.schema,
    {
      config: {
        code: {
          validate: (code) => {
            if (code.length !== 6) {
              throw new Error('Code must be 6 digits')
            }
          },
        },
      },
    },
  )
  const verifyCode = useValidatedAction(
    async (data) => {
      const attempt = await createMicrodepositAttempt({
        microdeposit_requirement_id: requirement.id,
        code: data.code,
      })
      if (!attempt.succeeded) {
        throw new Error(t('Provided code is not valid, please double check and try again.'))
      }
      return attempt
    },
    { onSuccess },
  )

  return <MicrodepositCodeVerificationInput state={state} submit={verifyCode} />
}

function AmountsVerification({ requirement, onSuccess }: Props) {
  const createMicrodepositAttempt = Cb.useCreateMicrodepositAttemptHook()

  const { state, useValidatedAction } = useFormState(
    MicrodepositAmountsVerificationInputUtils.schema,
  )
  const verifyCode = useValidatedAction(
    async (data) => {
      const attempt = await createMicrodepositAttempt({
        microdeposit_requirement_id: requirement.id,
        amount_1: Math.round(Number(data.amountOne) * 100),
        amount_2: Math.round(Number(data.amountTwo) * 100),
      })
      if (!attempt.succeeded) {
        throw new Error(t('Provided amounts are not valid, please double check and try again.'))
      }
      return attempt
    },
    { onSuccess },
  )

  return <MicrodepositAmountsVerificationInput state={state} submit={verifyCode} />
}

export default function MicrodepositVerificationView({ requirement, onSuccess }: Props) {
  switch (requirement.type) {
    case 'code':
      return <CodeVerification requirement={requirement} onSuccess={onSuccess} />
    case 'amounts':
      return <AmountsVerification requirement={requirement} onSuccess={onSuccess} />
    default:
      return unreachable(requirement.type)
  }
}
