/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectExpensesOrdering =
  (typeof ListProjectExpensesOrdering)[keyof typeof ListProjectExpensesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectExpensesOrdering = {
  date: 'date',
  '-date': '-date',
  payee_business__name: 'payee_business__name',
  '-payee_business__name': '-payee_business__name',
  payer_business__name: 'payer_business__name',
  '-payer_business__name': '-payer_business__name',
} as const
