import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function PlayVideo({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 1.5,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4462 8.7604L8.60418 6.86575C8.01346 6.47194 7.22222 6.8954 7.22222 7.60535V11.3947C7.22222 12.1046 8.01346 12.5281 8.60418 12.1343L11.4462 10.2396C11.9739 9.88776 11.9739 9.11224 11.4462 8.7604Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 9.5C17 13.9183 13.4183 17.5 9 17.5C4.58172 17.5 1 13.9183 1 9.5C1 5.08172 4.58172 1.5 9 1.5C13.4183 1.5 17 5.08172 17 9.5Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
