/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TransactionAccountTypeInternalBankAccountType =
  (typeof TransactionAccountTypeInternalBankAccountType)[keyof typeof TransactionAccountTypeInternalBankAccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountTypeInternalBankAccountType = {
  internal_bank_account: 'internal_bank_account',
} as const
