/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateCreateAddress2Type =
  (typeof EstimateCreateAddress2Type)[keyof typeof EstimateCreateAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateCreateAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
