/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentGroupPaymentsItemCardDetailsState =
  (typeof InvoicePaymentGroupPaymentsItemCardDetailsState)[keyof typeof InvoicePaymentGroupPaymentsItemCardDetailsState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentGroupPaymentsItemCardDetailsState = {
  new: 'new',
  pending: 'pending',
  complete: 'complete',
  failed: 'failed',
} as const
