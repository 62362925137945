import { SpinnerCircularFixed } from 'spinners-react'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'

type SizeProps = { w: `w-${string}`; h: `h-${string}` } | { size?: 'normal' | 'large' }

type Props = SizeProps & {
  color?: `text-${string}`
  thickness?: number
  className?: string
  fill?: boolean
}

const getSizeFromSizeName = (sizeName: 'normal' | 'large') => {
  switch (sizeName) {
    case 'normal':
      return 'w-[12px] h-[12px]'
    case 'large':
      return 'w-[28px] h-[28px]'
    default:
      return unreachable(sizeName)
  }
}

export default function Spinner({ thickness, color, fill, className, ...sizeProps }: Props) {
  return (
    <div className={cn(fill && 'h-full vflex justify-center items-center')}>
      <div
        className={cn(
          'w' in sizeProps
            ? cn(sizeProps.w, sizeProps.h)
            : getSizeFromSizeName(sizeProps.size ?? 'normal'),
          'relative',
          color,
        )}
      >
        <div className={cn('absolute inset-0 justify-center', className)}>
          <SpinnerCircularFixed
            style={{ height: '100%', width: '100%', margin: 'auto' }}
            thickness={thickness ?? 120}
            speed={120}
            color="currentcolor"
            secondaryColor="transparent"
          />
        </div>
      </div>
    </div>
  )
}
