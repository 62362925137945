/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeCashBackPaymentType =
  (typeof BeamBalanceTransactionTypeCashBackPaymentType)[keyof typeof BeamBalanceTransactionTypeCashBackPaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeCashBackPaymentType = {
  cash_back_payment: 'cash_back_payment',
} as const
