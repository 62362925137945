/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimatesOrdering =
  (typeof ListEstimatesOrdering)[keyof typeof ListEstimatesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimatesOrdering = {
  expiration_date: 'expiration_date',
  '-expiration_date': '-expiration_date',
  created_at: 'created_at',
  '-created_at': '-created_at',
  staging_total_amount: 'staging_total_amount',
  '-staging_total_amount': '-staging_total_amount',
} as const
