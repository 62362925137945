import { createContext, FC, useContext, useMemo } from 'react'
import { useHistoryState } from 'utils/useHistoryState'

type Branded<T, K extends string> = T & {
  __brand: K
}

type PageContext<T> = Branded<T, 'page-context'> | undefined
type SomePageSpec = {
  [key: string]: PageContext<{}>
}

type PageArgs<T extends SomePageSpec, K extends keyof T> = T[K] extends PageContext<infer S>
  ? {} extends NonNullable<S>
    ? [key: K]
    : [key: K, ctx: NonNullable<S>]
  : never

export type PageState<T extends SomePageSpec> = {
  [K in keyof T]: { id: PageArgs<T, K>[0] } & (PageArgs<T, K> extends [any, infer S] ? S : {})
}[keyof T]

type PageController<T extends SomePageSpec> = {
  push: <K extends keyof T>(...args: PageArgs<T, K>) => void
  replace: <K extends keyof T>(...args: PageArgs<T, K>) => void
  back: (() => void) | undefined
  page: PageState<T>
}

type PageSpecWithInitialState<T extends SomePageSpec> = {
  pageSpec: T
  initialState: PageState<T>
}

export function pageContext<T>(): PageContext<T> {
  return undefined
}

function usePageController<T extends SomePageSpec>({
  initialState,
}: PageSpecWithInitialState<T>): PageController<T> {
  const state = useHistoryState(initialState)
  return useMemo(
    () => ({
      push: <K extends keyof T>(...args: PageArgs<T, K>) =>
        state.push({ id: args[0], ...((args as any)[1] as unknown as {}) } as any),
      replace: <K extends keyof T>(...args: PageArgs<T, K>) =>
        state.replace({ id: args[0], ...((args as any)[1] as unknown as {}) } as any),
      back: state.back,
      page: state.state,
    }),
    [state],
  )
}

export function definePageSpec<T extends SomePageSpec>(x: T, initialState: PageState<T>) {
  const Context = createContext<PageController<T> | undefined>(undefined)
  return {
    useContext: () => {
      const ctx = useContext(Context)
      if (ctx === undefined) throw new Error('Page context must be used with a form')
      return ctx
    },
    wrap: <S extends object>(Component: FC<S>): FC<S & { initialPage?: PageState<T> }> => {
      return (props: S & { initialPage?: PageState<T> }) => {
        const controller = usePageController({
          pageSpec: x,
          initialState: props.initialPage ?? initialState,
        })
        return (
          <Context.Provider value={controller}>
            <Component {...props} />
          </Context.Provider>
        )
      }
    },
  }
}

export type ContextForPage<T extends SomePageSpec, K extends keyof T> = T[K] extends PageContext<
  infer S
>
  ? S
  : never
