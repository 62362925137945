import { Cell, CellField } from './base'
import { Branded, EmptyString, FieldError } from './utils'

type SsnString = Branded<string, 'ein'>
type SsnField<V> = CellField<string, V>

type SsnProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validateSsn(s: string) {
  if (Number.isNaN(s) || s.length !== 9) {
    throw new Error('SSN must be 9 numeric characters')
  }
  return s
}

export function Ssn<TReq extends boolean = false>(
  props?: SsnProps<TReq>,
): SsnField<TReq extends true ? SsnString : SsnString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError('Required')
      } else {
        return validateSsn(x)
      }
    } else if (!x) {
      return ''
    } else {
      return validateSsn(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? '',
    validate,
    errorKey: props?.errorKey,
  })
}
