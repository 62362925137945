/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AddressTypeRawType = (typeof AddressTypeRawType)[keyof typeof AddressTypeRawType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressTypeRawType = {
  raw: 'raw',
} as const
