type Props = {
  type: string
  report?: string
  context?: object
  tags?: object
  displayMessage?: string
}

export class MSError extends Error {
  context: object

  tags: object

  constructor({ type, report, context, tags, displayMessage }: Props) {
    super(displayMessage ?? 'An unexpected error has occurred')
    this.name = report ? `MSError.${type} - ${report}` : `MSError.${type}`
    this.context = context ?? {}
    this.tags = tags ?? {}
  }
}
