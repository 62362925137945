/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayerContactCreateContactType =
  (typeof PayerContactCreateContactType)[keyof typeof PayerContactCreateContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayerContactCreateContactType = {
  homeowner: 'homeowner',
  contractor: 'contractor',
  developer: 'developer',
} as const
