import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Ellipsis({
  width,
  height = 5,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2.5H2.01M9 2.5H9.01M16 2.5H16.01M3 2.5C3 2.76522 2.89464 3.01957 2.70711 3.20711C2.51957 3.39464 2.26522 3.5 2 3.5C1.73478 3.5 1.48043 3.39464 1.29289 3.20711C1.10536 3.01957 1 2.76522 1 2.5C1 2.23478 1.10536 1.98043 1.29289 1.79289C1.48043 1.60536 1.73478 1.5 2 1.5C2.26522 1.5 2.51957 1.60536 2.70711 1.79289C2.89464 1.98043 3 2.23478 3 2.5ZM10 2.5C10 2.76522 9.89464 3.01957 9.70711 3.20711C9.51957 3.39464 9.26522 3.5 9 3.5C8.73478 3.5 8.48043 3.39464 8.29289 3.20711C8.10536 3.01957 8 2.76522 8 2.5C8 2.23478 8.10536 1.98043 8.29289 1.79289C8.48043 1.60536 8.73478 1.5 9 1.5C9.26522 1.5 9.51957 1.60536 9.70711 1.79289C9.89464 1.98043 10 2.23478 10 2.5ZM17 2.5C17 2.76522 16.8946 3.01957 16.7071 3.20711C16.5196 3.39464 16.2652 3.5 16 3.5C15.7348 3.5 15.4804 3.39464 15.2929 3.20711C15.1054 3.01957 15 2.76522 15 2.5C15 2.23478 15.1054 1.98043 15.2929 1.79289C15.4804 1.60536 15.7348 1.5 16 1.5C16.2652 1.5 16.5196 1.60536 16.7071 1.79289C16.8946 1.98043 17 2.23478 17 2.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
