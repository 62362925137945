import { base } from './factories'

type TransformerProps<T, S extends T, TRequired extends boolean, TValid = T> = {
  isNonempty(val: T): val is S
  validate: (value: S) => TValid
  initValue: T
  required?: TRequired
}

export function transformedThing<T, S extends T, TValid, TRequired extends boolean>(
  props: TransformerProps<T, S, TRequired, TValid>,
) {
  return base({
    initialValue: props.initValue,
    validate: (val): TRequired extends true ? TValid : TValid | null => {
      if (props.isNonempty(val)) {
        return props.validate(val) as any
      } else if (props.required) throw new Error('Required')
      else return null as any
    },
  })
}

type FileValue =
  | { type: 'ref'; uploadId: string; name: string }
  | { type: 'ready'; uploadId: string; file: File }
  | { type: 'loading' }
  | null

type FileProps<TRequired extends boolean> = {
  initValue?: FileValue
  required?: TRequired
}

export function file<TRequired extends boolean>(props?: FileProps<TRequired>) {
  return transformedThing({
    required: props?.required,
    initValue: props?.initValue ?? null,
    isNonempty: (val): val is NonNullable<FileValue> => val !== null,
    validate: (val) => {
      if (val.type === 'loading') {
        throw new Error('File is uploading')
      } else {
        return val
      }
    },
  })
}

/*
type RichAddress = {
  line1: string
  line2: string | null
  city: string
  zip: string
  state: string
}

function richAddressToString(val: RichAddress) {
  return `${val.line1}${val.line2 ? ` ${val.line2}` : ''}, ${val.city}, ${val.zip}, ${val.state}`
}

type AddressValue =
  | { type: 'rich'; value: RichAddress }
  | { type: 'raw'; value: string }

type AddressProps<TRequired extends boolean> = {
  initValue?: AddressValue
  required?: TRequired
}

export function address<TRequired extends boolean = false>(props?: AddressProps<TRequired>) {
  return base({
    initialValue: { type: 'raw', value: '' },
    validate: (val: AddressValue): TRequired extends true ? string : string | null => {
      const str = val.type === 'rich' ? richAddressToString(val.value) : val.value
      if (props?.required) {
        
      } else {
      }
    },
    ...props,
  })
}
*/
