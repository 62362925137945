/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeFreeFormApprovalState =
  (typeof BillTypeFreeFormApprovalState)[keyof typeof BillTypeFreeFormApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeFreeFormApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  void: 'void',
} as const
