// TODO: breaks the DAG

import { useQueries as useProjectQueries } from 'modules/Projects/config'
import { useQueries as useInvoiceQueries } from 'modules/Invoices/config'
import { useQueries as useClientQueries } from 'modules/Clients/config'
import { useQueries as useEstimateQueries } from 'modules/Estimates/config'
import { useQueries as useBillQueries } from 'modules/Bills/config'
import { useQueries as useBusinessSettingsQueries } from 'modules/BusinessSettings/config'
import { useQueries as useProfileSettingsQueries } from 'modules/ProfileSettings/config'
import Mount from 'utils/Mount'
import Delayed from 'utils/Delayed'
import { useAppContext } from './user'
import { useAnalyticsContext } from './global'

export default function Prefetches() {
  const { payeeEnabled, payerEnabled } = useAppContext()
  const { hideCosts } = useAnalyticsContext().flags

  return (
    <>
      {payeeEnabled && (
        <Delayed delayMilliseconds={1000}>
          <Mount useSomething={useInvoiceQueries} />
        </Delayed>
      )}
      {payeeEnabled && payerEnabled && !hideCosts && (
        <Delayed delayMilliseconds={2000}>
          <Mount useSomething={useBillQueries} />
        </Delayed>
      )}
      {payeeEnabled && (
        <Delayed delayMilliseconds={3000}>
          <Mount useSomething={useProjectQueries} />
        </Delayed>
      )}
      {payeeEnabled && (
        <Delayed delayMilliseconds={4000}>
          <Mount useSomething={useClientQueries} />
        </Delayed>
      )}
      {payeeEnabled && (
        <Delayed delayMilliseconds={5000}>
          <Mount useSomething={useEstimateQueries} />
        </Delayed>
      )}
      {payeeEnabled && (
        <Delayed delayMilliseconds={6000}>
          <Mount useSomething={useBusinessSettingsQueries} />
        </Delayed>
      )}
      {payeeEnabled && (
        <Delayed delayMilliseconds={7000}>
          <Mount useSomething={useProfileSettingsQueries} />
        </Delayed>
      )}
    </>
  )
}
