import { Cb } from 'cb'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { t } from 'content'
import { F, xnor } from 'msutils'
import { useBusinessContext } from 'root/user'

export namespace BusinessPageUtils {
  const GeneralContractorMcc = '1520'
  const GeneralContractorProductDescription =
    'Our business is the general contractor for construction projects. We invoice property owners and pay bills to our subcontractors and vendors.'

  export const BusinessTypes = [
    { title: t('Private corporation'), id: 'private_corporation' },
    { title: t('Multi-member LLC'), id: 'multi_member_llc' },
    { title: t('Single-member LLC'), id: 'single_member_llc' },
    { title: t('Sole proprietorship'), id: 'sole_proprietorship' },
    { title: t('Private partnership'), id: 'private_partnership' },
    // These business types are extremely unlikely for general contractors we will onboard
    // { title: t('businessTypes.publicCorporation'), id: 'public_corporation' },
    // { title: t('businessTypes.publicPartnership'), id: 'public_partnership' },
    // { title: t('businessTypes.unincorporatedAssociation'), id: 'unincorporated_association' },
  ] as const

  export const EinInputProps = {
    type: 'custom',
    cleave: { numericOnly: true, delimiter: '-', blocks: [2, 7] },
    inputMode: 'numeric',
    placeholder: '12-3456789',
  } as const

  export const SsnInputProps = {
    type: 'custom',
    cleave: { numericOnly: true, delimiter: '-', blocks: [3, 2, 4] },
    inputMode: 'numeric',
    inputType: 'password',
    placeholder: '123-45-6789',
  } as const

  export const schema = F.Group({
    spec: {
      legalName: F.Text({ required: true }),
      dbaName: F.Text(),
      businessType: F.Choice<(typeof BusinessTypes)[number]['id']>().required(),
      address: RichAddressInputUtils.schema,
      ein: F.Ein(),
      ssn: F.Ssn(),
      phone: F.Phone({ required: true }),
      url: F.Url({ required: true }),
      treasuryTerms: F.Toggle({ required: true }),
      connectTerms: F.Toggle({ required: true }),
    },
  })

  type CreateContext = {
    termsOfServices: { [key: string]: Cb.TermsOfService }
  }
  export function useCreateBusinessProfile({ termsOfServices }: CreateContext) {
    const createBusinessProfile = Cb.useCreateBusinessProfileHook()
    const createTermsOfServiceAcceptance = Cb.useCreateTermsOfServiceAcceptanceHook()
    const { business } = useBusinessContext()

    return async (valids: F.OutputShape<typeof schema>) => {
      if (valids.businessType === 'sole_proprietorship') {
        if (xnor(!!valids.ein, !!valids.ssn)) {
          throw new Error(t('Please enter either EIN or SSN, but not both'))
        }
      } else if (!valids.ein) {
        throw new Error(t('Please enter a value for EIN'))
      }

      // this will fail if you haven't initialized your DB: https://www.notion.so/beamco/Initial-data-for-new-DBs-dec98b5c68e2423f9e354ed8b6ef79be
      await Promise.all(
        [termsOfServices.stripe_connect.id, termsOfServices.stripe_treasury.id].map((tosId) =>
          createTermsOfServiceAcceptance({ business_id: business.id, terms_of_service_id: tosId }),
        ),
      )

      return createBusinessProfile({
        business_id: business.id,
        address: RichAddressInputUtils.toApi(valids.address),
        legal_name: valids.legalName,
        structure: valids.businessType,
        dba_name: valids.dbaName,
        mcc: GeneralContractorMcc,
        product_description: GeneralContractorProductDescription,
        ein: valids.ein,
        ssn: valids.businessType === 'sole_proprietorship' ? valids.ssn : '',
        phone: valids.phone,
        url: valids.url,
      })
    }
  }
}
