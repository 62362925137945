/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceTypeFreeFormPaymentState =
  (typeof InvoiceTypeFreeFormPaymentState)[keyof typeof InvoiceTypeFreeFormPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTypeFreeFormPaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
