/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PaymentSourcePatchType =
  (typeof PaymentSourcePatchType)[keyof typeof PaymentSourcePatchType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSourcePatchType = {
  credit_card: 'credit_card',
  bank_account: 'bank_account',
} as const
