import { ReactNode } from 'react'
import Typography from 'compass/data/Typography'
import { toast } from 'react-toastify'
import { CompassTypes } from 'compass-local'
import { Link2Utils } from 'compass-local/Link2'

type Props = {
  title: string
  isError?: boolean
  label?: ReactNode
  navigateTo2?: CompassTypes['href']
}

function ToastUI({ title, label, navigateTo2 }: Props) {
  const push = Link2Utils.usePush()

  return (
    <div className="vflex gap-2" onClick={() => (navigateTo2 ? push(navigateTo2) : undefined)}>
      <div className="vflex gap-0.5">
        <Typography variant="bodybold">{title}</Typography>
      </div>
      {label && <Typography variant="caption">{label}</Typography>}
    </div>
  )
}

export function showToast({ title, label, isError, navigateTo2 }: Props) {
  if (isError) {
    toast.error(<ToastUI title={title} label={label} navigateTo2={navigateTo2} />)
  } else {
    toast.success(<ToastUI title={title} label={label} navigateTo2={navigateTo2} />)
  }
}
