import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Download({
  width,
  height = 18,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 19"
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
      >
        <path
          d="M1 13.5V14.5C1 15.2956 1.31607 16.0587 1.87868 16.6213C2.44129 17.1839 3.20435 17.5 4 17.5H14C14.7956 17.5 15.5587 17.1839 16.1213 16.6213C16.6839 16.0587 17 15.2956 17 14.5V13.5M13 9.5L9 13.5M9 13.5L5 9.5M9 13.5V1.5"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
