/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowLienWaiverStatus =
  (typeof BillListRowLienWaiverStatus)[keyof typeof BillListRowLienWaiverStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowLienWaiverStatus = {
  not_required: 'not_required',
  required: 'required',
  collected: 'collected',
} as const
