import { ReactNode } from 'react'
import { unreachable } from 'msutils/misc'
import { EmailVerificationBlocker } from 'root/component-utils'
import { useAppContext } from '../AppContext'
import { BusinessUiBase } from './BusinessUiBase'
import GuestUiBase from './GuestUiBase'

type Props = {
  children: ReactNode
}

export function UiBaseForUserType({ children }: Props) {
  const context = useAppContext()

  switch (context.type) {
    case 'business':
      return (
        <BusinessUiBase>
          <EmailVerificationBlocker />
          {children}
        </BusinessUiBase>
      )
    case 'client':
      return <GuestUiBase>{children}</GuestUiBase>
    default:
      return unreachable(context)
  }
}
