/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TransactionAccountTypeExternalBankAccountType =
  (typeof TransactionAccountTypeExternalBankAccountType)[keyof typeof TransactionAccountTypeExternalBankAccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountTypeExternalBankAccountType = {
  external_bank_account: 'external_bank_account',
} as const
