/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ProjectExpensePermissionUpdatePermissionNotAllowedReason =
  (typeof ProjectExpensePermissionUpdatePermissionNotAllowedReason)[keyof typeof ProjectExpensePermissionUpdatePermissionNotAllowedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectExpensePermissionUpdatePermissionNotAllowedReason = {
  is_markup_source: 'is_markup_source',
  null: null,
} as const
