/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type RequirementListCoverageTypesRequiredItem =
  (typeof RequirementListCoverageTypesRequiredItem)[keyof typeof RequirementListCoverageTypesRequiredItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequirementListCoverageTypesRequiredItem = {
  general: 'general',
  automotive: 'automotive',
  umbrella: 'umbrella',
  excess: 'excess',
  workers_comp: 'workers_comp',
} as const
