import { ReactNode, useState } from 'react'
import AnimatedHeight from 'react-animate-height'
import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'
import { Render } from 'utils/components'
import { switchInline } from 'utils/misc'
import Typography from 'compass/data/Typography'
import { CircledCheckmark, ErrorIcon, Bank } from 'compass-local/legacy/icons'

type Props = {
  show: boolean
  color?: 'red' | 'green' | 'gray'
  icon?: 'exclamation' | 'checkmark' | 'bank'
  message: ReactNode
}

const DURATION = 100

function getColor(color: Props['color']) {
  switch (color) {
    case undefined:
    case 'red':
      return 'text-th-red-warning'
    case 'green':
      return 'text-th-green-success'
    case 'gray':
      return 'text-th-text'
    default:
      return unreachable(color)
  }
}

function getIcon(icon: Props['icon']) {
  return (
    <Render
      Component={switchInline(icon ?? 'exclamation', {
        exclamation: ErrorIcon,
        checkmark: CircledCheckmark,
        bank: Bank,
      })}
      height={12}
    />
  )
}

export default function Annotation({ show, icon, color, message }: Props) {
  const [showInDom, setShowInDom] = useState(show)

  return (
    <AnimatedHeight
      height={show ? 'auto' : 0}
      duration={DURATION}
      animateOpacity
      className={cn(!showInDom && 'absolute')}
      onHeightAnimationEnd={() => {
        if (!show) setShowInDom(false)
      }}
      onHeightAnimationStart={() => {
        if (show) setShowInDom(true)
      }}
    >
      <div className={cn('flex gap-2 items-baseline', getColor(color))}>
        {getIcon(icon)}
        <Typography>{message}</Typography>
      </div>
    </AnimatedHeight>
  )
}
