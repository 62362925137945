import { useMemo } from 'react'
import { z } from 'zod'
import { Cb, Q } from 'cb'
import useUrlParam from 'utils/useUrlParam'
import { useBusinessContext } from 'root/user'
import { DateFilterSpec } from 'compass-local/FilterInput/utils'
import { Pagination } from 'utils/pagination'

export const moduleId = 'estimates'

export function useUrlParams() {
  const tabState = useUrlParam(
    't',
    z.enum(['all', 'pending', 'approved', 'rejected']).default('all'),
  )
  const searchState = useUrlParam('q', z.string().default(''))
  const sortState = useUrlParam(
    's',
    z
      .enum([
        'staging_total_amount',
        '-staging_total_amount',
        'expiration_date',
        '-expiration_date',
        'created_at',
        '-created_at',
      ])
      .nullable()
      .default(null),
  )
  const filterState = useUrlParam(
    'f',
    z
      .object({
        status: z.enum(['approved', 'rejected', 'sent', 'void', 'draft']).array().default([]),
        expiration: DateFilterSpec,
      })
      .default({}),
  )
  const p = useUrlParam('p', z.number().default(1))

  return useMemo(
    () => ({ tabState, searchState, sortState, filterState, pageState: p }),
    [tabState, searchState, sortState, filterState, p],
  )
}

export function useQueries() {
  const { business } = useBusinessContext()

  return Q.group({
    estimatesSummary: Cb.useRetrieveEstimatesPageSummary(business.id),
    allEstimatesCount: Cb.useListEstimates({
      params: { payee_id: business.id, type: 'project', page: 1, page_size: Pagination.PageSize },
      select: (data) => data.count,
    }),
  })
}
