/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentGroupPaymentsItemMethod =
  (typeof InvoicePaymentGroupPaymentsItemMethod)[keyof typeof InvoicePaymentGroupPaymentsItemMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentGroupPaymentsItemMethod = {
  card: 'card',
  bank_account: 'bank_account',
  external: 'external',
} as const
