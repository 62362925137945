import { createContext, ReactNode, useContext, useMemo } from 'react'
import { ExternalProps } from './utils'

type TInputBaseContext = Partial<{
  annotationStyle: ExternalProps['annotationStyle']
  base: ExternalProps['base']
  title: 'force-empty' | null
  align?: 'right' | 'left'
  autoselectOnFocus?: (inputType: string) => boolean
}>
const Ctx = createContext<TInputBaseContext | undefined>(undefined)

export function useInputBaseContext() {
  return useContext(Ctx)
}

export function InputBaseContext(props: TInputBaseContext & { children: ReactNode }) {
  const currentScope = useInputBaseContext()
  const newScope = useMemo(() => ({ ...currentScope, ...props }), [currentScope, props])
  return <Ctx.Provider value={newScope}>{props.children}</Ctx.Provider>
}
