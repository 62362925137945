import Typography from 'compass/data/Typography'
import { useAppContext } from 'root/user'
import { setAuth } from 'root/utils'
import Metrics from 'compass-local/Metrics'
import BaseMetric from 'compass-local/BaseMetric'
import { useNavigate } from 'utils/router'
import Codeblock from '../../components/Codeblock'
import { defineModule } from '../../utils'

function BusinessDetails() {
  const { user, ...context } = useAppContext()
  const navigate = useNavigate()

  const fakeLogout = () => {
    setAuth(null)
    navigate('/')
    window.location.reload()
  }

  return (
    <div className="vflex gap-3">
      <Metrics layout="h">
        <BaseMetric k="User ID" v={<Codeblock>{user.id}</Codeblock>} />
        {'business' in context && (
          <BaseMetric k="Business ID" v={<Codeblock>{context.business.id}</Codeblock>} />
        )}
        {'business' in context && (
          <BaseMetric k="Business name" v={<Codeblock>{context.business.name}</Codeblock>} />
        )}
        {'client' in context && (
          <BaseMetric k="Client ID" v={<Codeblock>{context.client.id}</Codeblock>} />
        )}
      </Metrics>
      <Typography onClick={fakeLogout} className="cursor-pointer text-th-red-warning">
        Fake logout
      </Typography>
    </div>
  )
}

export default defineModule({
  id: 'business-details',
  showRule: 'authed-only',
  name: 'Business',
  component: <BusinessDetails />,
})
