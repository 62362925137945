/* eslint-disable mosaic-js/unnamed-args */
import { Error2 } from './error'
import * as Expectation from './expectations'
import { getErrorFn } from './useErrorHandling'

export function assert(condition: boolean, message?: string) {
  if (!condition) {
    throw new Error2(message ?? 'Assertion error')
  }
}

export function expect<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): asserts val is S {
  const condition: any = cond ?? Expectation.present()
  if (!condition(val)) throw new Error2('Expectation error')
}

export function expectReturn<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): S {
  const condition: any = cond ?? Expectation.present()
  if (!condition(val)) throw new Error2('Expectation error')
  return val as S
}

export function expectReturn2<T, S extends T = NonNullable<T>>(
  val: T,
  cond?: (val2: T) => val2 is S,
): (message?: string) => S {
  return (message: string = 'Expectation error') => {
    const condition: any = cond ?? Expectation.present()
    if (!condition(val)) throw new Error2(message)
    return val as S
  }
}

export function guard<T, S extends T>(
  val: T,
  cond: (val2: T) => val2 is S,
  message?: string,
): val is S {
  if (cond(val)) {
    return true
  } else {
    getErrorFn()(new Error2(`[Guard] ${message ?? 'Unexpected failure'}`, { level: 'warning' }))
    return false
  }
}
