/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ExternalPaymentSourceType =
  (typeof ExternalPaymentSourceType)[keyof typeof ExternalPaymentSourceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPaymentSourceType = {
  credit_card: 'credit_card',
  bank_account: 'bank_account',
} as const
