/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeProgressApprovalState =
  (typeof BillTypeProgressApprovalState)[keyof typeof BillTypeProgressApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeProgressApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  void: 'void',
} as const
