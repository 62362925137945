import Button from 'compass/data/Button'
import DropdownButton from 'compass/data/Button/DropdownButton'
import { Option } from 'compass/data/Button/DropdownButton/internal-utils'
import Icon from 'compass/data/Icon'
import { MSArray } from 'msutils'
import { unreachable } from 'msutils/misc'
import { theme2 } from 'theme2'
import { ContextBuilder } from 'msutils/common'
import { getButtonThemeFromThemeName } from './theme'
import { ActionConfig, ThemeProps } from './types'
import { performQualify } from './internal-utils'

const { useContext, Provider } = ContextBuilder<ThemeProps>()

export { Provider as UIConfigProvider }

export function MenuOptionMount<TQualifyCtx, TMountProps, TMountCtx extends object>({
  name,
  theme,
  qualify,
  getMountProps,
  useMountCtx,
  Mount: ComponentMount,
  onClick,
  icon,
  endIcon,
  customIconDISCOURAGED,
}: ActionConfig<TQualifyCtx, TMountProps, TMountCtx>) {
  const mountCtx = useMountCtx()
  const qual = performQualify(qualify)

  if (qual.type === 'hidden') {
    return null
  } else if (qual.type === 'disabled') {
    return (
      <div className="py-0.5 rounded-4">
        <Option
          name={name}
          icon={customIconDISCOURAGED || (icon && <Icon name={icon} />)}
          endIcon={endIcon && <Icon name={endIcon} />}
          color={theme === 'danger' ? 'text-th-red-warning' : undefined}
          disabled
          disabledMessage={qual.message}
        />
      </div>
    )
  } else if (qual.type === 'qualified') {
    const ctx = { qualCtx: qual.ctx, mountCtx }
    const mountProps = getMountProps(ctx)
    return (
      <>
        <ComponentMount {...mountProps} />
        <div className="py-0.5 rounded-4" onClick={() => onClick?.(ctx)}>
          <Option
            name={name}
            icon={customIconDISCOURAGED || (icon && <Icon name={icon} />)}
            endIcon={endIcon && <Icon name={endIcon} />}
            color={theme === 'danger' ? 'text-th-red-warning' : undefined}
          />
        </div>
      </>
    )
  } else {
    return unreachable(qual)
  }
}

export function Mount<TQualifyCtx, TMountProps, TMountCtx extends object>({
  name,
  richText,
  type = 'button',
  options,
  isLoading,
  qualify,
  getMountProps,
  useMountCtx,
  Mount: ComponentMount,
  submit,
  onClick,
  icon,
  endIcon,
  theme,
  internalVariantDONOTUSE,
}: ActionConfig<TQualifyCtx, TMountProps, TMountCtx> & { submit?: boolean }) {
  const mountCtx = useMountCtx()
  const qual = performQualify(qualify)
  const defaultTheme = useContext().theme
  const themeOrDefault = theme ?? defaultTheme ?? 'primary'

  if (qual.type === 'hidden') {
    return null
  } else if (qual.type === 'disabled') {
    if (type === 'button') {
      return (
        <Button
          icon={icon && <Icon name={icon} />}
          endIcon={endIcon && <Icon name={endIcon} />}
          theme={getButtonThemeFromThemeName(themeOrDefault)}
          disabled
          disabledMessage={qual.message}
          richText={richText}
        >
          {name}
        </Button>
      )
    } else if (type === 'dropdown') {
      return (
        <DropdownButton
          icon={
            internalVariantDONOTUSE === 'ellipsis' ? (
              <div className="py-1">
                <Icon name="ellipsis" />
              </div>
            ) : (
              icon && <Icon name={icon} />
            )
          }
          theme={getButtonThemeFromThemeName(themeOrDefault)}
          closeButtonTheme={theme2.ButtonThemeLight}
          disabled
          hideChevron={internalVariantDONOTUSE === 'ellipsis'}
          options={[]}
        >
          {name}
        </DropdownButton>
      )
    } else {
      return unreachable(type)
    }
  } else if (qual.type === 'qualified') {
    const ctx = { qualCtx: qual.ctx, mountCtx }
    const mountProps = getMountProps(ctx)
    if (type === 'button') {
      return (
        <>
          <ComponentMount {...mountProps} />
          <Button
            icon={icon && <Icon name={icon} />}
            theme={getButtonThemeFromThemeName(themeOrDefault)}
            endIcon={endIcon && <Icon name={endIcon} />}
            onClick={() => onClick?.(ctx)}
            isLoading={typeof isLoading === 'boolean' ? isLoading : isLoading?.(mountCtx)}
            submit={submit}
            richText={richText}
          >
            {name}
          </Button>
        </>
      )
    } else if (type === 'dropdown') {
      return (
        <>
          <ComponentMount {...mountProps} />
          <DropdownButton
            icon={
              internalVariantDONOTUSE === 'ellipsis' ? (
                <div className="py-1">
                  <Icon name="ellipsis" cursor="pointer" />
                </div>
              ) : (
                icon && <Icon name={icon} />
              )
            }
            theme={getButtonThemeFromThemeName(themeOrDefault)}
            closeButtonTheme={theme2.ButtonThemeLight}
            keepMenuMounted
            hideChevron={internalVariantDONOTUSE === 'ellipsis'}
            popupOnly={internalVariantDONOTUSE === 'ellipsis'}
            options={MSArray.collapse(
              options?.map((opt, i) => {
                if (opt.type === 'divider') {
                  return opt
                } else {
                  // qual needs to be able to operate without mount ctx because otherwise
                  // we wouldn't be able to hide this?
                  const innerQual = performQualify(opt.qualify)
                  if (innerQual.type === 'hidden') {
                    return null
                  } else {
                    return {
                      key: `${opt.name}-${i}`,
                      type: 'override',
                      disabled: innerQual.type === 'disabled',
                      ui: <MenuOptionMount {...opt} />,
                    }
                  }
                }
              }) ?? [],
            )}
          >
            {name}
          </DropdownButton>
        </>
      )
    } else {
      return unreachable(type)
    }
  } else {
    return unreachable(qual)
  }
}

export function base<TQualifyCtx, TMountProps, TMountCtx extends object>(
  name: string,
  props: Omit<ActionConfig<TQualifyCtx, TMountProps, TMountCtx>, 'name'>,
): ActionConfig<TQualifyCtx, TMountProps, TMountCtx> {
  return { name, ...props }
}
