import Typography from 'compass/data/Typography'
import { t } from 'content'
import useScreenSize from 'compass/theme/useScreenSize'
import { theme2 } from 'theme2'
import Button from 'compass/data/Button'

type Props = {
  closeForm: () => void
  dismissWarning: () => void
}

export default function WarningOverlay({ closeForm, dismissWarning }: Props) {
  const sz = useScreenSize()

  return (
    <div className="absolute bg-th-bg-white-transparent inset-0 p-9 vflex justify-center z-[3]">
      <div className="vflex items-center">
        <div className="vflex gap-3">
          <Typography variant="title">{t('Discard changes?')}</Typography>
          <div className={sz === 'sm' ? 'vflex gap-3' : 'flex justify-end gap-3'}>
            <Button
              theme={theme2.ButtonThemeLight}
              onClick={(e) => {
                e.stopPropagation()
                closeForm()
              }}
            >
              {t('Discard')}
            </Button>
            <Button
              theme={theme2.ButtonThemeDefault}
              onClick={(e) => {
                e.stopPropagation()
                dismissWarning()
              }}
            >
              {t('Continue editing')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
