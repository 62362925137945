/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceTypeProgressInvoiceType =
  (typeof InvoiceTypeProgressInvoiceType)[keyof typeof InvoiceTypeProgressInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTypeProgressInvoiceType = {
  progress: 'progress',
} as const
