/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceCreateApprovalState =
  (typeof InvoiceCreateApprovalState)[keyof typeof InvoiceCreateApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceCreateApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  void: 'void',
} as const
