/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayeeContactPatchAddress2Type =
  (typeof PayeeContactPatchAddress2Type)[keyof typeof PayeeContactPatchAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayeeContactPatchAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
