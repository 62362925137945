import { useState, useMemo, useCallback } from 'react'

export type FormProps = {
  setActive: () => void
  setInactive: () => void
  isActive: boolean
}

export default function useFormProps(defaultValue = false) {
  const [isActive, setIsActive] = useState(defaultValue)

  const setActive = useCallback(() => setIsActive(true), [])
  const setInactive = useCallback(() => setIsActive(false), [])

  return useMemo(() => ({ isActive, setActive, setInactive }), [isActive, setActive, setInactive])
}
