import { MSDate } from '../date'
import { MSObject } from '../object'
import { base } from './factories/base'
import { listFactory } from './factories/list'
import {
  groupFactory,
  TReadablesForGroup,
  TStorablesForGroup,
  TValidsForGroup,
  TWritablesForGroup,
} from './factories/group'
import { AnyFactory, Factory, Valid } from './types'

export { base }

export type ConstructorProps<TWritable, TValid = TWritable, FValid = TValid> = Partial<{
  initialValue: TWritable
  validate: (value: TValid) => FValid
}>

export function literal<T extends string | undefined>(value: T) {
  return base({ initialValue: value, validate: (x) => x })
}

export function constant<T>(value: T) {
  return base({ initialValue: value, validate: (x) => x })
}

export function text(props?: ConstructorProps<string>) {
  return base({ initialValue: '', validate: (x) => x, ...props })
}

export function toggle(props?: ConstructorProps<boolean>) {
  return base({ initialValue: false, validate: (x) => x, ...props })
}

export function choice<T, S = T | null>(props?: ConstructorProps<T | null, T | null, S>) {
  return base<T | null, S>({
    validate: (x) => x as S,
    ...props,
    initialValue: props?.initialValue ?? null,
  })
}

export function date<T = MSDate.Date | null>(
  props?: Omit<ConstructorProps<MSDate.Date | null, MSDate.Date | null, T>, 'initialValue'> & {
    initialValue?: string | MSDate.Date | null
  },
) {
  const p = {
    ...props,
    initialValue: props?.initialValue ? MSDate.date(props.initialValue) : null,
  }
  return base<MSDate.Date | null, T>({ validate: (x) => x as T, ...p })
}

type UnconfirmedFileUpload = {
  file: File
  uploadId: null
  isLoading: boolean
}

type ConfirmedFileUpload = {
  file: File | string
  uploadId: string
  isLoading: false
}

type FileInputMetadata = UnconfirmedFileUpload | ConfirmedFileUpload

export function files<T = FileInputMetadata[]>(
  props?: ConstructorProps<FileInputMetadata[], FileInputMetadata[], T>,
) {
  return base({
    initialValue: props?.initialValue ?? [],
    validate: props?.validate ?? ((x) => x as T),
  })
}

export function group<F extends { [key: string]: AnyFactory }, FValid = TValidsForGroup<F>>(
  fs: {
    [K in keyof F]: F[K]
  },
  // Note: lower inference precedence of F in the Partial by &ing with {}
  props?: Omit<
    ConstructorProps<Partial<TWritablesForGroup<F & {}>>, TStorablesForGroup<F>, FValid>,
    'validate'
  > & {
    validate?: (value: TStorablesForGroup<F>, factories: F) => FValid
  },
) {
  return groupFactory(fs, {
    validate: (x) => {
      if (props?.validate) {
        return props.validate(x, fs)
      } else {
        return MSObject.map<F, TValidsForGroup<F>>(fs, (f, k) =>
          f.attemptValidation(x[k].value),
        ) as FValid
      }
    },
    initialValue: props?.initialValue,
  })
}

export function group2<F extends { [key: string]: AnyFactory }, FValid = TValidsForGroup<F>>(
  fs: {
    [K in keyof F]: F[K]
  },
  // Note: lower inference precedence of F in the Partial by &ing with {}
  props?: ConstructorProps<Partial<TWritablesForGroup<F & {}>>, TValidsForGroup<F>, FValid>,
) {
  const validate = props?.validate
    ? (x: TStorablesForGroup<F & {}>) =>
        props.validate?.(
          MSObject.map<F, TValidsForGroup<F>>(fs, (f, k) => f.attemptValidation(x[k].value)),
        ) as FValid
    : (x: TStorablesForGroup<F & {}>) =>
        MSObject.map<F, TValidsForGroup<F>>(fs, (f, k) => f.attemptValidation(x[k].value)) as FValid

  return groupFactory(fs, {
    ...props,
    validate,
  })
}

export function list<TWritable, TStorable, TReadable, TValid, C extends object, FValid = TValid[]>(
  f: Factory<TWritable, TStorable, TReadable, TValid, C>,
  props?: ConstructorProps<TWritable[], TValid[], FValid>,
) {
  return listFactory(f, { validate: (x) => x as FValid, ...props })
}

export function groupList<F extends { [key: string]: AnyFactory }, FValid = TValidsForGroup<F>[]>(
  fs: {
    [K in keyof F]: F[K]
  },
  props?: ConstructorProps<Partial<TWritablesForGroup<F>>[], TValidsForGroup<F>[], FValid>,
) {
  return list(group(fs), props)
}

type OneOfResult<F extends { [key: string]: AnyFactory }, K extends keyof F> = {
  id: K
  detail: Valid<F[K]>
}

type OneOfFactory<F extends { [key: string]: AnyFactory }> = Factory<
  Partial<
    TWritablesForGroup<
      F & { type: Factory<keyof F | null, keyof F | null, keyof F | null, keyof F, {}> }
    >
  >,
  TStorablesForGroup<
    F & { type: Factory<keyof F | null, keyof F | null, keyof F | null, keyof F, {}> }
  >,
  TReadablesForGroup<
    F & { type: Factory<keyof F | null, keyof F | null, keyof F | null, keyof F, {}> }
  >,
  { [K in keyof F]: OneOfResult<F, K> }[keyof F],
  {}
>

export function oneOf<F extends { [key: string]: AnyFactory }>(
  fs: {
    [K in keyof F]: F[K]
  },
  initialValue?: keyof F,
): OneOfFactory<F> {
  return group(
    {
      ...fs,
      type: choice({ initialValue: initialValue ?? null }),
    },
    {
      validate: ({ type, ...rest }) => {
        if (type.value === null) throw new Error('Type is required')
        return {
          id: type.value,
          detail: fs[type.value].attemptValidation(rest[type.value as string].value),
        }
      },
    },
  ) as OneOfFactory<F>
}
