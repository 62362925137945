import { MSObject } from '../../object'
import { Factory, StorableInputState, AnyFactory, InputState } from '../types'
import { containsWithStatus, factory, FactoryProps } from './utils'

export type TWritablesForGroup<F extends { [key: string]: AnyFactory }> = {
  [K in keyof F]: F[K] extends Factory<infer TWritable, any, any, any, any> ? TWritable : never
}

export type TStorablesForGroup<F extends { [key: string]: AnyFactory }> = {
  [K in keyof F]: F[K] extends Factory<infer TWritable, infer TStorable, any, any, any>
    ? StorableInputState<TWritable, TStorable>
    : never
}

export type TReadablesForGroup<F extends { [key: string]: AnyFactory }> = {
  [K in keyof F]: F[K] extends Factory<
    infer TWritable,
    infer TStorable,
    infer TReadable,
    infer TValid,
    infer C extends object
  >
    ? InputState<TWritable, TStorable, TReadable, TValid, C>
    : never
}

export type TValidsForGroup<F extends { [key: string]: AnyFactory }> = {
  [K in keyof F]: F[K] extends Factory<any, any, any, infer TValid, any> ? TValid : never
}

export function groupFactory<F extends { [key: string]: AnyFactory }, FValid>(
  fs: { [K in keyof F]: F[K] },
  props: FactoryProps<Partial<TWritablesForGroup<F>>, TStorablesForGroup<F>, FValid>,
): Factory<Partial<TWritablesForGroup<F>>, TStorablesForGroup<F>, TReadablesForGroup<F>, FValid> {
  const prepareWriteForStorage = (writables: Partial<TWritablesForGroup<F>>) =>
    MSObject.map<F, TStorablesForGroup<F>>(fs, (f, k) => f.init(writables[k]) as any)

  return factory({
    // initial values won't spread, but that doesn't really matter
    initialValue: props.initialValue ?? ({} as Partial<TWritablesForGroup<F>>),
    mergeParentAndChildInitialValues: (parentInitialValue, childInitialValue) => ({
      ...parentInitialValue,
      ...childInitialValue,
    }),
    validate: props.validate,
    prepareWriteForStorage,
    prepareStoredValueForRead: (storables, [, setState]) =>
      MSObject.map<F, TReadablesForGroup<F>>(fs, <K extends keyof F>(f: F[K], k: K) => {
        const defaultChild = f.build([
          storables[k],
          (prevStateGetter) =>
            setState((prevState) => ({
              ...prevState,
              value: { ...prevState.value, [k]: prevStateGetter(prevState.value[k]) },
            })),
        ]) as TReadablesForGroup<F>[K]

        return defaultChild
      }),
    overrideStatus: (value) => {
      try {
        if (containsWithStatus(value, 'editing')) {
          return 'editing'
        } else if (containsWithStatus(value, 'changed')) {
          return 'changed'
        } else if (containsWithStatus(value, 'retouched')) {
          return 'retouched'
        } else if (containsWithStatus(value, 'touched')) {
          return 'touched'
        } else if (containsWithStatus(value, 'unclean')) {
          return 'unclean'
        } else {
          return 'clean'
        }
      } catch (e: any) {
        // eslint-disable-next-line
        console.error(e)
        return 'clean'
      }
    },
    setUpController: ([state, setState], baseController) => {
      const prepareTap = () => {
        const childState = MSObject.map<F, TStorablesForGroup<F>>(fs, (f, k) => {
          return f
            .build([
              state.value[k],
              (prevStateGetter) =>
                setState((prevState) => ({
                  ...prevState,
                  value: { ...prevState.value, [k]: prevStateGetter(prevState.value[k]) },
                })),
            ])
            .prepareTap() as any
        })
        return { ...baseController.prepareTap(), value: childState }
      }

      return {
        ...baseController,
        prepareTap,
        tap: () => setState(prepareTap),
        set: (newState) =>
          setState((prevState) => ({
            ...newState,
            value: { ...prevState.value, ...newState.value },
          })),
      }
    },
    type: 'group',
  })
}
