type FormatCurrencyOptions = {
  sign: boolean
}

export function format(value: number | string, options?: Partial<FormatCurrencyOptions>) {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    const formatted = formatter.format(Number(value))
    if (Number(value) > 0 && options?.sign) {
      return `+${formatted}`
    } else {
      return formatted
    }
  } catch (e: any) {
    const num = Number(value)
    return `${num < 0 ? '-' : ''}$${Math.abs(num).toFixed(2)}`
  }
}
