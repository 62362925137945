import { useMemo, useState } from 'react'
import { SentryClient } from 'lib/sentry'
import { useMutation } from '@tanstack/react-query'
import { MSArray, MSError2, MSInput, MSObject } from 'msutils'
import { mapObject } from 'utils/object'
import { InputState } from 'msutils/input/types'
import { t } from 'content'
import { getFirst } from 'utils/array'
import { isAxios4XX, get400Message, ValidationError, TimeoutError } from './error'

type Props<TriggerProps, Output, TWritable, TReadable, TStorable, TValid, C extends object> = {
  onSuccess?: (v: Output) => void | Promise<void>
  onError?: (e: any) => void | Promise<void>
  trigger: (_: TriggerProps, validatedInputs: TValid) => Promise<Output>
  inputs: InputState<TWritable, TReadable, TStorable, TValid, C>
  settings?: Partial<{
    disableLog4XX: boolean
    checkpointOnSuccess: boolean
  }>
}

export type ResultSet<T> =
  | { status: 'idle'; data: undefined; isLoading: false }
  | { status: 'loading'; data: undefined; isLoading: true }
  | { status: 'error'; data: undefined; isLoading: false }
  | { status: 'success'; data: T; isLoading: false }

type AsyncHandlers<T> = Partial<{
  onSuccess: (v: T) => void | Promise<void>
  onError: (e: any) => void | Promise<void>
}>

export type Action<
  TProps,
  Output,
  Input extends MSInput.InputState<any, any, any, any, {}>,
> = ResultSet<Output> & {
  inputs: Input
  hasChanged: boolean
  trigger: (props: TProps, handlers?: AsyncHandlers<Output>) => void
  reset: () => void
  error: Error | null
  triggerProps: TProps | null
}

export function useAction<
  TriggerProps,
  Output,
  TWritable,
  TReadable,
  TStorable,
  TValid,
  C extends object,
>({
  trigger,
  inputs,
  settings,
  onSuccess,
  onError,
}: Props<TriggerProps, Output, TWritable, TReadable, TStorable, TValid, C>): Action<
  TriggerProps,
  Output,
  InputState<TWritable, TReadable, TStorable, TValid, C>
> {
  const [ackedErrors, setAckedErrors] = useState<string[]>([])
  const [triggerProps, setTriggerProps] = useState<TriggerProps | null>(null)

  const triggerWithValidatedInput = async (props: TriggerProps) => {
    if (!inputs.isValid) {
      const errors = MSInput.collectErrors(inputs)
      const err = new MSError2.Error2(errors)
      err.name = 'Attempted mutation with invalid inputs'
      SentryClient.report(err, 'warning')
      throw new Error(t('Invalid input'))
    }

    try {
      setTriggerProps(props)
      setAckedErrors([])
      return await trigger(props, inputs.validValue)
    } catch (e: any) {
      if (e instanceof ValidationError) {
        e.name = e.name || t('Validation error')
        throw e
      } else if (e instanceof TimeoutError) {
        throw new MSError2.Error2('Request timed out')
      } else if (isAxios4XX(e)) {
        const message = get400Message(e)
        const reportE = new MSError2.Error2(`Error ${e.response?.status}: ${message}`, {
          wrappedError: e,
        })
        if (!settings?.disableLog4XX) SentryClient.report(reportE, 'warning')
        throw new ValidationError(message, {
          nonFieldErrors: e.response?.data?.non_field_errors,
          fieldErrors: MSObject.toCamel(e.response?.data ?? {}),
        })
      } else {
        SentryClient.report(e)
        throw new MSError2.Error2('Unexpected error')
      }
    }
  }

  const baseAction = useMutation<Output, Error, TriggerProps>(triggerWithValidatedInput, {
    onSuccess: (res) => {
      onSuccess?.(res)
      if (settings?.checkpointOnSuccess) MSInput.checkpointAll(inputs)
    },
    onError: (e) => {
      onError?.(e)
      inputs.tap()
    },
  })

  const newInputs = useMemo(() => {
    if (inputs.value === null || typeof inputs.value !== 'object') {
      return inputs
    }

    const newValue = mapObject(inputs.value, (input, k) => {
      const errorOverride =
        baseAction.error instanceof ValidationError &&
        !ackedErrors.includes(k as string) &&
        baseAction.error.fieldErrors?.[k as string]?.at(0)
          ? getFirst(baseAction.error.fieldErrors[k as string])
          : null

      if (errorOverride) {
        return {
          ...input,
          focus: () => {
            setAckedErrors((oldValue) => MSArray.dedupe([...oldValue, k as string]))
            ;(input as any).focus(newValue)
          },
          error: errorOverride,
          isValid: false,
        }
      }

      return input
    })

    return { ...inputs, value: newValue }
  }, [baseAction, inputs, ackedErrors])

  return {
    ...baseAction,
    reset: () => {
      baseAction.reset()
      inputs.reset()
    },
    hasChanged: MSInput.hasEdited(inputs.status),
    trigger: baseAction.mutate,
    inputs: newInputs,
    triggerProps,
  }
}
