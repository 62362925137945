/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExternalDirection =
  (typeof BeamBalanceTransactionTypeExternalDirection)[keyof typeof BeamBalanceTransactionTypeExternalDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExternalDirection = {
  debit: 'debit',
  credit: 'credit',
} as const
