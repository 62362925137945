/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GetProjectImportRequestViewOutputState =
  (typeof GetProjectImportRequestViewOutputState)[keyof typeof GetProjectImportRequestViewOutputState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectImportRequestViewOutputState = {
  new: 'new',
  failed: 'failed',
  started: 'started',
  complete: 'complete',
} as const
