/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowPaymentState =
  (typeof BillListRowPaymentState)[keyof typeof BillListRowPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowPaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
