import { ReactNode } from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import Typography from 'compass/data/Typography'
import { Plus } from 'compass-local/legacy/icons'
import { t } from 'content'

type Props = {
  title: ReactNode
  required: boolean
  inputProps?: DropzoneRootProps
  rootProps?: DropzoneInputProps
}

export default function Header({ title, required, inputProps, rootProps }: Props) {
  return (
    <div className="vflex gap-2">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-baseline">
          <Typography variant="label">{title}</Typography>
          {!required && (
            <Typography variant="label" className="text-th-text-secondary">
              {t('Optional')}
            </Typography>
          )}
        </div>
        {inputProps && rootProps && (
          <div {...rootProps} className="w-fit">
            <input {...inputProps} />
            <LinkButtonDONOTUSE icon={<Plus height={14} />} onClick={() => undefined}>
              {t('Add')}
            </LinkButtonDONOTUSE>
          </div>
        )}
      </div>
    </div>
  )
}
