import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function Phone({
  width,
  height = 16,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.72827C0 1.17599 0.447715 0.728271 1 0.728271H3.15287C3.64171 0.728271 4.0589 1.08168 4.13927 1.56387L4.87858 5.99975C4.95075 6.43278 4.73206 6.86224 4.3394 7.05857L2.79126 7.83264C3.90756 10.6066 6.12168 12.8207 8.89563 13.937L9.6697 12.3889C9.86603 11.9962 10.2955 11.7775 10.7285 11.8497L15.1644 12.589C15.6466 12.6694 16 13.0866 16 13.5754V15.7283C16 16.2806 15.5523 16.7283 15 16.7283H13C5.8203 16.7283 0 10.908 0 3.72827V1.72827Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
