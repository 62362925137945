/* eslint-disable mosaic-js/unnamed-args */
import { Factory, StorableInputState, InputState } from '../types'
import { containsWithStatus, factory, FactoryProps } from './utils'

type ListController<TWritable> = {
  append: (value?: TWritable) => void
  initAfter: (i: number, value?: TWritable) => void
  remove: (i: number) => void
  move: (i: number, j: number) => void
}

function replaceInList<T>(i: number, newItem: T, l: T[]) {
  return l.map((item, j) => (i === j ? newItem : item))
}

function removeAt<T>(i: number, l: T[]) {
  return l.flatMap((item, j) => (i === j ? [] : [item]))
}

function insertAt<T>(i: number, newItem: T, l: T[]) {
  return l.flatMap((x, j) => (i === j ? [x, newItem] : [x]))
}

function insertBefore<T>(i: number, newItem: T, l: T[]) {
  return l.flatMap((x, j) => (i === j ? [newItem, x] : [x]))
}

function move<T>(i: number, j: number, l: T[]) {
  const el = l[i]
  if (i === j) {
    return l.slice()
  } else if (i > j) {
    return insertBefore(j, el, removeAt(i, l))
  } else {
    return insertAt(j - 1, el, removeAt(i, l))
  }
}

export function listFactory<TWritable, TStorable, TReadable, TValid, C extends object, FValid>(
  f: Factory<TWritable, TStorable, TReadable, TValid, C>,
  props: FactoryProps<TWritable[], TValid[], FValid>,
): Factory<
  TWritable[],
  StorableInputState<TWritable, TStorable>[],
  InputState<TWritable, TStorable, TReadable, TValid, C>[],
  FValid,
  ListController<TWritable>
> {
  const prepareWriteForStorage = (writables: TWritable[]) => writables.map(f.init)

  return factory({
    initialValue: props?.initialValue ?? [],
    validate: (storables) =>
      props.validate(storables.map(({ value }) => f.attemptValidation(value))),
    prepareWriteForStorage,
    prepareStoredValueForRead: (storable, [, setState]) =>
      storable.map((x, i) =>
        f.build([
          x,
          (prevStateGetter) =>
            setState((prevState) => ({
              ...prevState,
              value: replaceInList(i, prevStateGetter(prevState.value[i]), prevState.value),
            })),
        ]),
      ),
    overrideStatus: (value) => {
      try {
        if (containsWithStatus(value, 'editing')) {
          return 'editing'
        } else if (containsWithStatus(value, 'changed')) {
          return 'changed'
        } else if (containsWithStatus(value, 'retouched')) {
          return 'retouched'
        } else if (containsWithStatus(value, 'touched')) {
          return 'touched'
        } else if (containsWithStatus(value, 'unclean')) {
          return 'unclean'
        } else {
          return 'clean'
        }
      } catch (e: any) {
        // eslint-disable-next-line
        console.error(e)
        return 'clean'
      }
    },
    setUpController: ([state, setState], baseController) => {
      const prepareTap = () => {
        const childState = state.value.map((x, i) =>
          f
            .build([
              x,
              (prevStateGetter) =>
                setState((prevState) => ({
                  ...prevState,
                  value: replaceInList(i, prevStateGetter(prevState.value[i]), prevState.value),
                })),
            ])
            .prepareTap(),
        )
        return { ...baseController.prepareTap(), value: childState }
      }

      return {
        ...baseController,
        tap: () => setState(prepareTap),
        prepareTap,
        // TODO: these need to update interaction state
        append: (initValue) =>
          setState((oldState) => ({ ...oldState, value: [...oldState.value, f.init(initValue)] })),
        initAfter: (i, initValue) =>
          setState((oldState) => ({
            ...oldState,
            value: insertAt(i, f.init(initValue), oldState.value),
          })),
        remove: (i) =>
          setState((oldState) => ({ ...oldState, value: removeAt(i, oldState.value) })),
        move: (i, j) =>
          setState((oldState) => ({ ...oldState, value: move(i, j, oldState.value) })),
      }
    },
    type: 'list',
  })
}
