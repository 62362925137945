import { useState } from 'react'
import { ConstructorProps, group } from './constructors'
import { TStorablesForGroup, TValidsForGroup, TWritablesForGroup } from './factories/group'
import { AnyFactory, Factory } from './types'

export type {
  InputState,
  InputStateFromFactory,
  GroupItem,
  ListItem,
  Writable,
  Valid,
} from './types'
export { shouldShowError, hasEdited, isFocused, isClean } from './interaction'
export * from './constructors'
export * from './fields'
export * from './transformers'
export * from './validators'
export * from './utils'

// based mine off of these:
// https://stackoverflow.com/questions/57683303/how-can-i-see-the-full-expanded-contract-of-a-typescript-type
// https://www.typescriptlang.org/play?ssl=17&ssc=26&pln=16&pc=49#code/C4TwDgpgBAKgrmAMhAdgHgHJQgD2KgEwGcoU4BbAIwgCcAaWKAXigEMUQGBBbPQkmAG0AusygiAfGJ658KYlADeUADaoA5sAAWALihYAvlAD8UHnvhJUmBjAaCAdE663hEgNwBYAFA-QkKABRHDB2AgAlCABjOBoiAEsANwgVEDQ7KAARXjkFMipaMQAWbhz+Ng4RMUtkdEyJKSYfKEZZcoB7SgAraOATMzL5EkF2TignB3iUADNC8NFjZta+Iagp2ZooAHl+5UEAaTWUKABrCBB26e3hPWDQ+UiYuKSUtK2D4QZMhnCpIz0UBBkjQlhYoBYvL5vFN8DRpqwotBEFMTkololWCo9ERgDQpupIS1AXhjHpkSgTpCDD4-OBoHcwhACOSTgAGMQMh7RWIJZKpNAshisjw+AD0ACooP56SFGcyUeyWCyoOLRTTvNKgrL5EyWQBGDnaiLc558tKCqB6kXeCVSula+4EXUog0sRRLFoYrFQHF4lAEj2kWSkqDKgA+UDgOumUyZVJVaqhms5TvlFIATIbHY8eS9+Rb09bbcmjc6M2J3d4Wp7MdjcfjCdXicAQ5Xq+2oF6636A1WO0Tg2SUVAI1GnTHAQRGy0jKPo7Gpz4jKr1SXHWWTgBmLNhHOm14ClEMTdFyVruUs7duwNdn31-3ToMkvRt-ud2t3nuP9vN1uBt-vt6voNv+b6-kOFIjpG86Tt+1aztB44Lo+CFjhAE5xkuCZAA

// Notes on type inference
/*

  function beep<T = string>(p?: T): T {
    return '' as T
  }

  function badboop<T extends { [key: string]: any }>(x: T) {
  }

  // Note: must give T[K] an inference site
  function boop<T extends { [key: string]: any }>(x: { [K in keyof T]: T[K] }) {
  }

  boop({ a: beep() }) // works
  badboop({ a: beep() }) // does not work (`a` is typed as any since there's no T[K] inference site)

 */

// TODO: maybe this should be a whitelist instead of a blacklist
/* type Id<T> = T extends (...args: infer Args extends any[]) => infer Return
  ? (...args: Id<Args>) => Return
  : T extends File
  ? T
  : T extends object
  ? { [K in keyof T]: Id<T[K]> }
  : T
*/

export function useInputState<TWritable, TStorable, TReadable, TValid, C extends object>(
  f: Factory<TWritable, TStorable, TReadable, TValid, C>,
) {
  // the typing works except for the Id call
  // eslint-disable-next-line
  return f.build(useState(f.init()))
}

export function useGroupInputState<
  F extends { [key: string]: AnyFactory },
  FValid = TValidsForGroup<F>,
>(
  fs: { [K in keyof F]: F[K] },
  props?: ConstructorProps<TWritablesForGroup<F>, TStorablesForGroup<F>, FValid>,
) {
  return useInputState(group(fs, props))
}
