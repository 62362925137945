/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExpenseCardRepaymentType =
  (typeof BeamBalanceTransactionTypeExpenseCardRepaymentType)[keyof typeof BeamBalanceTransactionTypeExpenseCardRepaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExpenseCardRepaymentType = {
  expense_card_repayment: 'expense_card_repayment',
} as const
