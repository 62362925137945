import { FC, ReactNode, useState } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { DragIndicator, Plus, X } from 'compass-local/legacy/icons'
import { t } from 'content'
import { Collapsable, MSArray } from 'msutils/array'
import useScreenSize from 'compass/theme/useScreenSize'
import { InputBaseContext } from 'compass-local/InputBase'
import { theme2 } from 'theme2'
import { useDnd } from 'utils/dnd'
import { InputTable } from './InputTable'

type Column<S extends string> = {
  id: S
  name: ReactNode
  minWidth?: `min-w-${string}`
  maxWidth?: `max-w-${string}`
  defaultWidth?: `w-${string}`
  fillWidth?: boolean
  align?: 'right' | 'left'
}

type RowProps<S extends string> = {
  columns: Column<S>[]
  components: { [K in S]: ReactNode }
  showBorder?: boolean
  remove: null | (() => void)
  index: number
  allowReorder?: boolean
  move?: (i: number, j: number) => void
}

function InputTable2Row<S extends string>({
  columns,
  showBorder,
  components,
  remove,
  index,
  allowReorder,
  move,
}: RowProps<S>) {
  const [isHovered, setIsHovered] = useState(false)
  const isActive = isHovered || showBorder
  const dnd = useDnd({
    category: 'table-row',
    myId: `${index}`,
    onDrop: (j) => move?.(Number(j), index),
  })

  return (
    <InputBaseContext
      base={{ ...theme2.InputBaseThin, hideBorder: !isHovered && !showBorder }}
      annotationStyle="border-only"
      title="force-empty"
    >
      <tr
        ref={dnd.dndRef}
        className={cn(dnd.dropIsPending && 'opacity-50')}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {allowReorder && (
          <td className={cn('transition-all', !isActive && 'opacity-0')}>
            <div
              ref={dnd.dragRef}
              className={cn(
                'text-th-text-secondary transition-all w-0 relative right-5',
                dnd.isDragging ? 'cursor-grabbing' : 'cursor-grab',
              )}
            >
              <DragIndicator />
            </div>
          </td>
        )}
        {columns.map((column, j) => {
          if (components[column.id] === null) {
            return null
          } else {
            // this is to allow components to use up space that would be otherwise taken by null columns
            const nextNonNullIndex = columns.findIndex((x, i) => i > j && components[x.id] !== null)
            const span = nextNonNullIndex >= 0 ? nextNonNullIndex - j : 1

            return (
              <InputBaseContext key={j} align={column.align}>
                <td
                  className={cn('py-1 px-1 first:pl-3 last:pr-3', column.maxWidth, column.minWidth)}
                  colSpan={span}
                >
                  {components[column.id]}
                </td>
              </InputBaseContext>
            )
          }
        })}
        {!!remove && (
          <td className={cn('pl-3 transition-all', !isActive && 'opacity-0')}>
            <X
              height={14}
              className="text-th-text-secondary cursor-pointer hitbox"
              onClick={remove}
            />
          </td>
        )}
      </tr>
    </InputBaseContext>
  )
}

type InputTableProps<T, S extends string> = {
  rows: T[]
  controller: {
    append: () => void
    remove: (i: number) => void
    move?: (i: number, j: number) => void
  }
  hideAppend?: boolean
  hideHeader?: boolean
  disableAppend?: boolean
  columns: Collapsable<Column<S>[]>
  disableRemove?: (entry: T, i: number) => boolean | undefined
  showBorder?: (entry: T, i: number) => boolean | undefined
  components: (entry: T, i: number) => { [K in S]: ReactNode }
  allowReorder?: boolean
  MobileRow?: FC<{ entry: T }>
  overrides?: Partial<{
    append: ReactNode
  }>
}

export function InputTable2<T, S extends string>(props: InputTableProps<T, S>) {
  const {
    controller,
    rows,
    hideAppend,
    hideHeader,
    disableAppend,
    disableRemove,
    showBorder,
    components,
    allowReorder,
    overrides,
  } = props
  const sz = useScreenSize()
  const columns = MSArray.collapse(props.columns)

  const table = (
    <table>
      {!hideHeader && (
        <thead>
          <tr className="border-b-2">
            {allowReorder && <th> </th>}
            {columns.map((x, i) => (
              <th
                key={i}
                className={cn(
                  'px-1.5 first:px-3 py-2',
                  x.align === 'right' ? 'text-right' : 'text-left',
                  x.fillWidth ? 'w-full' : x.defaultWidth,
                  x.minWidth ?? 'min-w-[120px]',
                  x.maxWidth,
                )}
              >
                <Typography variant="label" className="text-th-text-secondary">
                  {x.name}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((entry, i) => {
          const componentsForRow = components(entry, i)
          const deletable = !disableRemove?.(entry, i)
          const showBorderForRow = showBorder?.(entry, i)

          return (
            <InputTable2Row
              key={i}
              index={i}
              components={componentsForRow}
              columns={columns}
              showBorder={showBorderForRow}
              remove={deletable ? () => controller.remove(i) : null}
              allowReorder={allowReorder}
              move={(i2, j) => controller.move?.(i2, j)}
            />
          )
        })}
      </tbody>
    </table>
  )

  if (sz === 'sm') {
    return <InputTable {...props} overrides={hideAppend ? { append: <></> } : undefined} />
  } else {
    return (
      <div className="vflex gap-2 md:gap-1">
        {table}
        {!hideAppend && (
          <div className="flex justify-end">
            {overrides?.append ?? (
              <LinkButtonDONOTUSE
                onClick={controller.append}
                icon={<Plus height={16} />}
                disabled={disableAppend}
              >
                {t('Add item')}
              </LinkButtonDONOTUSE>
            )}
          </div>
        )}
      </div>
    )
  }
}
