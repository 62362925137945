import { useEffect, useState } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'

type Props = {
  disableCopy?: boolean
  children: string
}
export default function Codeblock({ disableCopy, children }: Props) {
  // TODO: useTransientState
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const t = setTimeout(() => setIsCopied(false), 800)
      return () => {
        clearTimeout(t)
      }
    }
    return undefined
  }, [isCopied])

  return (
    <code
      className="whitespace-nowrap py-1 px-2 bg-slate-200 font-mono rounded-md cursor-pointer hover:bg-slate-300 transition relative"
      onClick={() => {
        if (!disableCopy) {
          navigator.clipboard.writeText(children)
          setIsCopied(true)
        }
      }}
    >
      <span className={cn(isCopied && 'invisible')}>{children}</span>
      {isCopied && (
        <Typography variant="label" className="font-sans absolute inset-0 flex items-center px-2">
          Copied!
        </Typography>
      )}
    </code>
  )
}
