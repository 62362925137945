import { FC, useEffect, useState } from 'react'
import Image from 'next/image'
import { MSArray } from 'msutils/array'
import Hotkeys from 'react-hot-keys'
import { createPortal } from 'react-dom'
import { ENVIRONMENT, devLike, prodLike } from 'env'
import { getFirst } from 'utils/array'
import Typography from 'compass/data/Typography'
import Divider from 'compass-local/Divider'
import { useAnalyticsContext } from 'root/global'
import Chicken from 'assets/chicken.png'
import { X } from 'compass-local/legacy/icons'
import TabGroup, { TabGroupUtils } from 'compass-local/TabGroup'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import BaseMetric from 'compass-local/BaseMetric'
import { getIsAuthed } from './utils'
import DevtoolsModules from './modules'
import AuthManager from './components/AuthManager'

const moduleIdPrefix = '__mosaic-devtools-'

const UnauthedModules = DevtoolsModules.filter((m) =>
  ['unauthed-only', 'always'].includes(m.showRule),
)
const AuthedModules = DevtoolsModules.filter((m) => ['authed-only', 'always'].includes(m.showRule))

function withFlagCheck(Component: FC) {
  return function Inner() {
    const { showDevTools } = useAnalyticsContext().flags
    return showDevTools ? <Component /> : null
  }
}

type DevtoolsModuleId = (typeof DevtoolsModules)[number]['id']

type Portals = {
  [K in DevtoolsModuleId]: HTMLElement | null
}
const Authed = withFlagCheck(() => {
  const [portals, setPortals] = useState<Portals | null>(null)

  useEffect(() => {
    if (devLike(ENVIRONMENT) && ENVIRONMENT !== 'test') {
      const authedModuleIds = AuthedModules.map((m) => m.id)

      const observer = new MutationObserver(() => {
        const newPortals = MSArray.toObject(authedModuleIds, (id) => {
          const el = document.getElementById(`${moduleIdPrefix}${id}`)
          return [id, el]
        })
        setPortals(newPortals)
      })
      observer.observe(document, { subtree: true, attributes: true })

      return () => {
        observer.disconnect()
      }
    } else {
      return undefined
    }
  }, [])

  if (!portals) return null

  return (
    <>
      {Object.keys(portals).map((k) => {
        const portal = portals[k as DevtoolsModuleId]
        return portal
          ? createPortal(MSArray.assertFind(AuthedModules, (m) => m.id === k).component, portal)
          : null
      })}
    </>
  )
})

const Unauthed = withFlagCheck(() => {
  const [selectedTab, setSelectedTab] = useState<DevtoolsModuleId>('cinderblock')
  const [show, setShow] = useState(false)

  if (!devLike(ENVIRONMENT) || prodLike(ENVIRONMENT) || ENVIRONMENT === 'test') {
    return null
  }

  const isAuthed = getIsAuthed()

  const moduleIsAllowed = (isAuthed ? AuthedModules : UnauthedModules)
    .map((m) => m.id)
    .includes(selectedTab)

  useEffect(() => {
    if (!moduleIsAllowed) {
      setSelectedTab(isAuthed ? getFirst(AuthedModules).id : getFirst(UnauthedModules).id)
    }
  }, [isAuthed, moduleIsAllowed])

  const selectedModule = MSArray.assertFind(DevtoolsModules, (m) => m.id === selectedTab)
  const component =
    selectedModule.showRule === 'authed-only' ? (
      <div id={`${moduleIdPrefix}${selectedModule.id}`} />
    ) : (
      selectedModule.component
    )

  const allowedModules = isAuthed ? AuthedModules : UnauthedModules

  return (
    <div className="fixed bottom-[6px] right-[6px] z-[100001]">
      <button
        type="button"
        title="Shift-A to open"
        className="w-[45px] h-[45px] rounded-[12px] bg-th-orange-beam shadow-large hover:shadow-none transition cursor-pointer p-2 hover:bg-th-orange-beam border-none"
        onClick={() => setShow(true)}
      >
        <Hotkeys keyName="shift+a" onKeyDown={() => setShow(!show)} />
        <Image src={Chicken} alt="Chicken" />
      </button>
      {show && (
        <div className="w-full isolate fixed bottom-0 inset-x-0 z-[100001] h-[400px] bg-[rgba(210,220,230,0.9)] border-t shadow-container p-4 overflow-y-auto">
          <div className="absolute right-[16px] top-[16px] text-th-brown-2 cursor-pointer flex gap-3 items-center">
            <Typography variant="caption" className="text-th-text-secondary">
              (shift-A)
            </Typography>
            <ReactQueryDevtools position="bottom-right" />
            <X onClick={() => setShow(false)} height={14} />
          </div>
          <TabGroup
            value={selectedTab}
            update={setSelectedTab}
            type="section"
            options={allowedModules.map((m) => TabGroupUtils.option(m.id, m.name))}
            ui={null}
          />
          <div className="pb-3 overflow-x-auto vflex gap-3">
            <div className="flex gap-6">
              <AuthManager />
              <BaseMetric k="App version" v={process.env.NEXT_PUBLIC_APP_VERSION || '--'} />
            </div>
            <Divider />
            {component}
          </div>
        </div>
      )}
    </div>
  )
})

export default { Authed, Unauthed }
