/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PaymentSourceCreateType =
  (typeof PaymentSourceCreateType)[keyof typeof PaymentSourceCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSourceCreateType = {
  credit_card: 'credit_card',
  bank_account: 'bank_account',
} as const
