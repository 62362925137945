import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function ReceiptWithCheckmark({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8856 14.6801C12.7263 14.6801 12.5869 14.6203 12.4674 14.5009L10.9937 13.0073L9.52006 14.4809C9.28109 14.7199 8.92262 14.7199 8.68365 14.4809L7.19005 13.0272L5.71636 14.5009C5.47739 14.7398 5.11892 14.7398 4.87995 14.5009L3.40626 13.0272L1.93258 14.5208C1.6936 14.7597 1.33514 14.7597 1.09616 14.5208C0.976675 14.4013 0.916931 14.2619 0.916931 14.1026V3.18933C0.916931 1.81522 2.03215 0.699997 3.40626 0.699997H10.9937C12.3679 0.699997 13.4831 1.81522 13.4831 3.18933V14.0826C13.4831 14.4212 13.2043 14.6801 12.8856 14.6801ZM7.19005 11.5933C7.34936 11.5933 7.48877 11.6531 7.60825 11.7725L9.08194 13.2661L10.5556 11.7925C10.7946 11.5535 11.1531 11.5535 11.392 11.7925L12.2683 12.6687V3.18933C12.2683 2.4724 11.6708 1.89488 10.9738 1.89488H3.40626C2.68934 1.89488 2.11181 2.4724 2.11181 3.18933V12.6687L2.98805 11.7925C3.22703 11.5535 3.58549 11.5535 3.82447 11.7925L5.29815 13.2661L6.77184 11.7925C6.89133 11.673 7.03073 11.6132 7.19005 11.5933Z"
          fill="#6D7180"
        />
        <path
          d="M8.38489 9.0841H4.6011C4.28247 9.0841 4.00366 8.82521 4.00366 8.48666C4.00366 8.16803 4.26255 7.88922 4.6011 7.88922H8.38489C8.70352 7.88922 8.98233 8.14811 8.98233 8.48666C8.98233 8.8053 8.70352 9.0841 8.38489 9.0841Z"
          fill="#6D7180"
        />
        <path
          d="M10.0379 6.07694H4.36221C4.04358 6.07694 3.76477 5.81805 3.76477 5.4795C3.76477 5.16087 4.02366 4.88206 4.36221 4.88206H10.0379C10.3565 4.88206 10.6353 5.14096 10.6353 5.4795C10.6353 5.81805 10.3565 6.07694 10.0379 6.07694Z"
          fill="#6D7180"
        />
        <g clipPath="url(#clip0_677_5096)">
          <rect x="6.40002" y="6.89999" width="9.6" height="9.6" rx="4.8" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2 16.5C12.4731 16.5 13.694 15.9943 14.5941 15.0941C15.4943 14.1939 16 12.973 16 11.7C16 10.427 15.4943 9.20606 14.5941 8.30588C13.694 7.40571 12.4731 6.89999 11.2 6.89999C9.92699 6.89999 8.70609 7.40571 7.80591 8.30588C6.90574 9.20606 6.40002 10.427 6.40002 11.7C6.40002 12.973 6.90574 14.1939 7.80591 15.0941C8.70609 15.9943 9.92699 16.5 11.2 16.5V16.5ZM13.4242 10.9242C13.5335 10.811 13.594 10.6595 13.5926 10.5022C13.5913 10.3448 13.5282 10.1943 13.4169 10.0831C13.3057 9.97186 13.1552 9.90876 12.9979 9.90739C12.8405 9.90602 12.689 9.9665 12.5758 10.0758L10.6 12.0516L9.82422 11.2758C9.71106 11.1665 9.5595 11.106 9.40218 11.1074C9.24487 11.1088 9.09438 11.1719 8.98313 11.2831C8.87189 11.3943 8.80879 11.5448 8.80742 11.7022C8.80605 11.8595 8.86653 12.011 8.97582 12.1242L10.1758 13.3242C10.2883 13.4367 10.4409 13.4999 10.6 13.4999C10.7591 13.4999 10.9117 13.4367 11.0242 13.3242L13.4242 10.9242V10.9242Z"
            fill="#008932"
          />
        </g>
        <defs>
          <clipPath id="clip0_677_5096">
            <rect x="6.40002" y="6.89999" width="9.6" height="9.6" rx="4.8" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconBase>
  )
}
