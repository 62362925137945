/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeInvoicePaymentType =
  (typeof BeamBalanceTransactionTypeInvoicePaymentType)[keyof typeof BeamBalanceTransactionTypeInvoicePaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeInvoicePaymentType = {
  invoice_payment: 'invoice_payment',
} as const
