import { useMemo } from 'react'
import { z } from 'zod'
import { Cb, Q } from 'cb'
import useUrlParam from 'utils/useUrlParam'
import { useBusinessContext } from 'root/user'

export const moduleId = 'projects'

export function useUrlParams() {
  const tabState = useUrlParam('t', z.enum(['active', 'completed', 'all']).default('active'))
  const searchState = useUrlParam('q', z.string().default(''))
  const p = useUrlParam('p', z.number().default(1))
  const sortState = useUrlParam(
    's',
    z.enum(['name', '-name', 'start_date', '-start_date']).or(z.null()).default(null),
  )

  return useMemo(
    () => ({ tabState, searchState, sortState, pageState: p }),
    [tabState, searchState, sortState, p],
  )
}

export function useQueries() {
  const { business } = useBusinessContext()
  return Q.group({
    projectsSummary: Cb.useRetrieveProjectsPageSummary(business.id),
  })
}
