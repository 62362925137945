/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ContractItemApprovalState =
  (typeof ContractItemApprovalState)[keyof typeof ContractItemApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractItemApprovalState = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
} as const
