/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeFreeFormInvoiceType =
  (typeof BillTypeFreeFormInvoiceType)[keyof typeof BillTypeFreeFormInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeFreeFormInvoiceType = {
  free_form: 'free_form',
} as const
