/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeYieldPaymentType =
  (typeof BeamBalanceTransactionTypeYieldPaymentType)[keyof typeof BeamBalanceTransactionTypeYieldPaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeYieldPaymentType = {
  yield_payment: 'yield_payment',
} as const
