/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetupIntentCreatePaymentMethodType =
  (typeof SetupIntentCreatePaymentMethodType)[keyof typeof SetupIntentCreatePaymentMethodType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetupIntentCreatePaymentMethodType = {
  card: 'card',
  bank_account: 'bank_account',
} as const
