import { useDrag, useDrop } from 'react-dnd'

type UseDndProps = {
  category: string
  myId: string
  onDrop: (otherId: string) => void
}

export function useDnd({ category, onDrop, myId }: UseDndProps) {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: category,
      item: { id: myId },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [myId, onDrop],
  )

  const [{ canDrop, isOver }, drop] = useDrop(
    {
      accept: category,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item: { id: string }) => {
        if (item.id !== myId) {
          onDrop(item.id)
        }
      },
    },
    [myId, onDrop],
  )

  const dndRef = (el: any) => {
    drop(el)
    dragPreview(el)
  }

  return {
    isDragging,
    dropIsPending: canDrop && isOver,
    dndRef,
    dragRef: drag,
  }
}
