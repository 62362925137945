import { formatCurrencyDONOTUSE } from 'utils/currency'
import { MSDate } from 'msutils'
import Typography from 'compass/data/Typography'
import Card from 'compass/layout/Card'
import { Cb } from 'cb'
import { t, Trans } from 'content'
import Link2 from 'compass-local/Link2'
import { Module } from 'modules/routes'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'

type Props = {
  details: NonNullable<Cb.TaskAdminApproveInvoiceDetails>
  close: () => void
}

export default function AdminApproveInvoice({ details, close }: Props) {
  return (
    <div className="vflex gap-4 px-4 py-3">
      <div className="flex justify-between items-center gap-4">
        <span>
          <Trans
            tKey="Bill of <X>{{ X }}</X> needs your approval."
            X={<Typography variant="bodybold" />}
            values={{ X: formatCurrencyDONOTUSE(details?.invoice_payment_amount ?? '0') }}
          />
        </span>
        <Link2 href={Module(`/bills/${details.invoice_id}`).href}>
          <Button theme={theme2.ButtonThemeTextOrange} onClick={close}>
            {t('View bill')}
          </Button>
        </Link2>
      </div>
      <Card variant="slate" className="vflex gap-6 !p-3">
        <div className="flex gap-4 justify-between">
          <div className="vflex gap-1">
            <Typography variant="captionbold">{details?.invoice_payee_name}</Typography>
            <Typography variant="caption" className="text-th-text-secondary">
              <Trans
                tKey="Due on <X>{{ X }}</X>"
                X={<Typography variant="captionbold" />}
                values={{ X: MSDate.format(details?.invoice_due_date ?? MSDate.today()) }}
              />
            </Typography>
          </div>
          <Typography variant="captionbold">
            {formatCurrencyDONOTUSE(details?.invoice_payment_amount ?? '0')}
          </Typography>
        </div>
      </Card>
    </div>
  )
}
