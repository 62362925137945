import { createContext, ReactNode, useContext, useMemo } from 'react'

const Ctx = createContext<{ setChildrenWidth: (newValue: number | undefined) => void } | undefined>(
  undefined,
)

export function useDrawerContext() {
  return useContext(Ctx)
}

type DrawerProviderProps = {
  setChildrenWidth: (newValue: number | undefined) => void
  children: ReactNode
}

export function DrawerProvider({ setChildrenWidth, children }: DrawerProviderProps) {
  const ctxValue = useMemo(() => ({ setChildrenWidth }), [setChildrenWidth])

  return <Ctx.Provider value={ctxValue}>{children}</Ctx.Provider>
}
