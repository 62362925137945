/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayerContactPatchAddress2Type =
  (typeof PayerContactPatchAddress2Type)[keyof typeof PayerContactPatchAddress2Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayerContactPatchAddress2Type = {
  raw: 'raw',
  rich: 'rich',
} as const
