/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BusinessProfileCreateAddressType =
  (typeof BusinessProfileCreateAddressType)[keyof typeof BusinessProfileCreateAddressType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessProfileCreateAddressType = {
  raw: 'raw',
  rich: 'rich',
} as const
