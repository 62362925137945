import { useState, useCallback, useMemo, ReactNode } from 'react'
import { useDropzone } from 'react-dropzone'
import { cn } from 'msutils/classnames'
import { MSArray, MSInput } from 'msutils'
import { DocumentWithPlus, X } from 'compass-local/legacy/icons'
import Typography from 'compass/data/Typography'
import ErrorMessage from 'compass-local/ErrorMessage'
import { getInputProps as getMSInputProps, InputProps } from 'compass-local/utils/InputProps'
import { t, Trans } from 'content'
import { useCompassContext } from 'compass-local/CompassProvider/utils'
import FilePreview from 'compass-local/FilePreview'
import { switchInline } from 'utils/misc'
import Header from './Header'

const DefaultFileTypes = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
}
const CsvOnlyFileTypes = {
  'text/csv': ['.csv'],
}
const ImageFileTypes = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
}

const MaxFileSize = 10 * 1024 * 1024 // 10 MB

type FilesInputStateValue = MSInput.InputStateFromFactory<ReturnType<typeof MSInput.files>>['value']

type Props = InputProps<FilesInputStateValue> & {
  fileTypes?: 'default' | 'image' | 'csv'
  settings?: Partial<{
    maxFiles: number
  }>
  customUi?: ReactNode
  hideTitle?: boolean
  fullHeight?: boolean
  title?: ReactNode
  description?: string
  required?: boolean
}

export default function FileInput(props: Props) {
  const {
    value,
    update,
    didUpdate,
    fileTypes = 'default',
    settings,
    customUi,
    fullHeight,
    hideTitle,
    title,
    description,
    required = false,
    error,
  } = getMSInputProps(props)
  const { uploadFile = () => Promise.resolve('') } = useCompassContext()
  const [rejectionError, setRejectionError] = useState<string | null>(null)

  const handleDrop = useCallback(
    async (newFiles: File[]) => {
      setRejectionError(null)
      const oldFiles = value
      update?.(oldFiles.concat(newFiles.map((x) => ({ file: x, uploadId: null, isLoading: true }))))
      const uploadedFiles = await Promise.all(
        newFiles.map((file) =>
          uploadFile(file).then((uploadId) => ({
            file,
            uploadId,
            isLoading: false as const,
          })),
        ),
      )
      update?.(oldFiles.concat(uploadedFiles))
      didUpdate?.(oldFiles.concat(uploadedFiles), uploadedFiles)
    },
    [value, update, didUpdate, uploadFile],
  )

  const maxFiles = settings?.maxFiles ?? 6
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleDrop,
    onDropRejected: (files) => {
      if (files.length > maxFiles - value.length)
        setRejectionError(t('Please upload at most {{ X }} files', { X: maxFiles }))
      else if (files.some((f) => f.file.size > MaxFileSize))
        setRejectionError(t('Files must be no larger than 10 MB in size'))
      else {
        setRejectionError(
          switchInline(fileTypes, {
            default: t('File type is not supported. Please upload a .pdf, .png, or .jpeg'),
            image: t('File type is not supported. Please upload a .png, or .jpeg'),
            csv: t('File type is not supported. Please upload a .csv'),
          }),
        )
      }
    },
    maxFiles: maxFiles - value.length,
    disabled: maxFiles - value.length === 0,
    maxSize: MaxFileSize,
    accept: switchInline(fileTypes, {
      default: DefaultFileTypes,
      image: ImageFileTypes,
      csv: CsvOnlyFileTypes,
    }),
  })

  const rootProps = useMemo(getRootProps, [getRootProps])
  const inputProps = useMemo(getInputProps, [getInputProps])

  if (customUi) {
    return (
      <div {...rootProps} className="h-full">
        <input {...inputProps} />
        {customUi}
      </div>
    )
  }

  if (value.length > 0) {
    return (
      <div className="vflex">
        {!hideTitle && (
          <Header
            title={title ?? t('Add attachments')}
            required={required}
            {...(maxFiles - value.length > 0 && { inputProps, rootProps })}
          />
        )}
        <div className="vflex py-2 gap-2">
          {value.map((f, i) => (
            <div key={i} className="flex gap-2 justify-between items-center">
              <FilePreview
                leftIcon="file"
                name={typeof f.file === 'string' ? f.file : f.file.name}
                loading={f.isLoading}
              />
              <X
                height={12}
                thickness={2.5}
                className="cursor-pointer text-th-brown-2"
                onClick={() => {
                  const newFiles = MSArray.removeAt(value, i)
                  update?.(newFiles)
                  didUpdate?.(value, newFiles)
                }}
              />
            </div>
          ))}
        </div>
        <ErrorMessage show={!!rejectionError || !!error}>
          <div onClick={() => setRejectionError(null)}>{rejectionError || error}</div>
        </ErrorMessage>
      </div>
    )
  }

  return (
    <div className={cn('vflex gap-2', fullHeight && 'h-full')}>
      {!hideTitle && <Header title={title ?? t('Add attachments')} required={required} />}
      <div {...rootProps} className="h-full">
        <input {...inputProps} />
        <div className="relative p-5 border border-dashed border-th-warmgrey-1 rounded-8 inline-block w-full h-full flex justify-center items-center">
          <div className="vflex gap-3 items-center text-center">
            <DocumentWithPlus className="text-th-orange-dark1" />
            <div className="vflex gap-1">
              <Typography variant="bodybold" className="text-th-brown-2">
                <Trans
                  tKey="Drag and drop, or <X>click to upload</X>"
                  X={
                    <Typography
                      variant="bodybold"
                      className="text-th-orange-dark1 cursor-pointer"
                    />
                  }
                />
              </Typography>
              {description && <Typography className="text-th-brown-2">{description}</Typography>}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage show={!!rejectionError || !!error}>
        <div onClick={() => setRejectionError(null)}>{rejectionError || error}</div>
      </ErrorMessage>
    </div>
  )
}
