import { CleaveOptions } from 'cleave.js/options'
import { BigNumber } from 'bignumber.js'
import { t } from 'content'
import { unreachable } from 'msutils/misc'

type Key = {
  code: string
  ctrlKey: boolean
}
function isBackspace(k: Key) {
  return k.code === 'Backspace' || (k.code === 'KeyH' && k.ctrlKey)
}

const getNextValue = (e: any) => {
  const currentValue = e.target.rawValue
  const replacement = e.data
  const selectionStart = e.target.selectionStart ?? 0
  const selectionEnd = e.target.selectionEnd ?? selectionStart

  return `${currentValue.slice(0, selectionStart)}${replacement}${currentValue.slice(selectionEnd)}`
}

function clipSuffixSelection(e: any, suffixLength: number) {
  const allowedEnd = e.target.value.length - suffixLength
  const start = e.target.selectionStart
  const end = e.target.selectionEnd
  if (start === end && start > allowedEnd) {
    e.target.selectionStart = allowedEnd
    e.target.selectionEnd = allowedEnd
  }
}

export const propsOverride = {
  border: 'none',
  width: '100%',
  background: 'transparent',
  cursor: 'inherit',
  padding: 0,
  height: '17px',
  fontSize: 'inherit',
  fontWeight: 'inherit',
}

type InputMode = 'tel' | 'numeric' | 'email' | 'search' | 'text' | 'url' | 'decimal'

export type InputTypeProps =
  | {
      type?: 'raw'
    }
  | {
      type: 'name'
    }
  | {
      type: 'numeric'
      decimal?: boolean
      allowNegative?: boolean
    }
  | {
      type: 'email'
    }
  | {
      type: 'phone'
    }
  | {
      type: 'currency'
      allowNegative?: boolean
    }
  | {
      type: 'password'
    }
  | {
      type: 'percent'
      maximum?: number
      allowNegative?: boolean
    }
  | {
      type: 'ein'
    }
  | {
      type: 'custom'
      cleave?: CleaveOptions
      inputMode?: InputMode
    }

export type InputTypePropsResults = {
  cleave?: CleaveOptions
  onKeyDown?: (key: Key, val: string, update?: (newVal: string) => void) => void
  onSelect?: (e: any, val: string) => void
  placeholder?: string
  autoCapitalize?: boolean
  title?: string
  inputType?: 'password' | 'email'
  inputMode?: InputMode
  fromDisplay?: (val: string) => string
  toDisplay?: (val: string) => string
  onBeforeInput?: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
} | null

export function getInputTypeProps(props: InputTypeProps): InputTypePropsResults {
  switch (props.type) {
    case undefined:
    case 'raw':
      return null
    case 'name':
      return { autoCapitalize: true, title: t('Name') }
    case 'numeric':
      return {
        cleave: props.decimal
          ? {
              // right click -> paste doesn't work here for some reason (i.e., trying to enter a value while the field is not focused)
              numeral: true,
              numeralPositiveOnly: !props.allowNegative,
            }
          : {
              numericOnly: true,
              numeralPositiveOnly: !props.allowNegative,
            },
      }
    case 'email':
      return { placeholder: t('joe@business-name.com'), title: t('Email'), inputType: 'email' }
    case 'phone':
      return {
        placeholder: '(234) 123-4567',
        title: t('Phone number'),
        inputMode: 'tel',
        cleave: {
          numericOnly: true,
          blocks: [0, 3, 0, 3, 4],
          delimiters: ['(', ')', ' ', '-'],
          delimiterLazyShow: true,
        },
      }
    case 'currency':
      return {
        placeholder: '$0',
        title: t('Amount'),
        cleave: {
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: !props.allowNegative,
          ...{ signBeforePrefix: true },
        },
        // eslint-disable-next-line mosaic-js/unnamed-args
        onKeyDown: (key, val, update) => {
          if (val === '-' && isBackspace(key)) update?.('')
        },
        onSelect: (e, val) => {
          if (+val >= 0 && e.target.selectionStart < 1) e.target.selectionStart = 1
        },
        inputMode: 'decimal',
        toDisplay: (val) =>
          val ? BigNumber(val).toFixed(Math.min(BigNumber(val).decimalPlaces() ?? 2, 2)) : '',
      }
    case 'percent': {
      const fromDisplay = (val: string) => (val ? (Number(val) / 100).toString() : '')
      return {
        placeholder: '0%',
        cleave: {
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          prefix: '%',
          tailPrefix: true,
          numeral: true,
          numeralPositiveOnly: !props.allowNegative,
        },
        onFocus: (e) => {
          setTimeout(() => clipSuffixSelection(e, 1), 30)
        },
        onKeyDown: (e) => {
          if (!e.code.includes('Arrow')) clipSuffixSelection(e, 1)
        },
        fromDisplay,
        toDisplay: (val) => (val ? new BigNumber(val).times(100).toString() : ''),
        onBeforeInput: (e) => {
          const nextValue = getNextValue(e)
          if (props.maximum && Number(fromDisplay(nextValue)) > props.maximum) {
            e.preventDefault()
          }
        },
      }
    }
    case 'password':
      return {
        title: t('Password'),
        inputType: 'password',
      }
    case 'ein':
      return {
        placeholder: '12-3456789',
        title: t('EIN'),
        cleave: {
          numericOnly: true,
          blocks: [2, 7],
          delimiters: ['-'],
          delimiterLazyShow: true,
        },
      }
    case 'custom':
      return {
        cleave: props.cleave,
        inputMode: props.inputMode,
      }
    default:
      return unreachable(props)
  }
}
