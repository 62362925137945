import IconBase from 'compass-local/legacy/IconBase'
import { IconProps } from '../types'

export default function BellWithMoney({
  width,
  height = 29,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 26 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.52539 20.9841C5.52539 25.1024 8.87955 28.4566 12.9979 28.4566C17.122 28.4566 20.4762 25.1024 20.4762 20.9841C20.4762 19.0999 19.747 17.3032 18.4987 15.9441H7.5029C6.25456 17.3032 5.52539 19.0999 5.52539 20.9841ZM13.0001 22.1529C11.7628 22.1529 10.7562 21.1463 10.7562 19.9084C10.7562 18.8741 11.4632 18.0098 12.4168 17.7511V17.3928C12.4168 17.0703 12.6777 16.8094 13.0001 16.8094C13.3225 16.8094 13.5834 17.0703 13.5834 17.3928V17.7511C14.5369 18.0098 15.244 18.8741 15.244 19.9084C15.244 20.2308 14.9831 20.4917 14.6606 20.4917C14.3382 20.4917 14.0773 20.2308 14.0773 19.9084C14.0773 19.3142 13.5942 18.8312 13.0001 18.8312C12.4059 18.8312 11.9229 19.3142 11.9229 19.9084C11.9229 20.5026 12.4059 20.9862 13.0001 20.9862C14.2374 20.9862 15.244 21.9928 15.244 23.2301C15.244 24.2643 14.5369 25.1287 13.5834 25.3874V25.7457C13.5834 26.0681 13.3225 26.329 13.0001 26.329C12.6777 26.329 12.4168 26.0681 12.4168 25.7457V25.3873C11.4632 25.1286 10.7562 24.2643 10.7562 23.2301C10.7562 22.9077 11.0171 22.6468 11.3395 22.6468C11.662 22.6468 11.9229 22.9077 11.9229 23.2301C11.9229 23.8242 12.4059 24.3073 13.0001 24.3073C13.5942 24.3073 14.0773 23.8242 14.0773 23.2301C14.0773 22.6359 13.5942 22.1529 13.0001 22.1529Z"
          fill="currentColor"
        />
        <path
          d="M15.7046 2.84235C15.6346 2.24154 15.3663 1.68737 14.9288 1.24987C14.4038 0.736531 13.7213 0.456543 12.998 0.456543C11.6096 0.456543 10.4605 1.5007 10.2913 2.84235C6.2838 3.8457 3.19214 7.01903 2.25879 11.0148H23.7429C22.8096 7.01903 19.7121 3.8457 15.7046 2.84235Z"
          fill="currentColor"
        />
        <path
          d="M1.08244 12.563C0.848165 12.7973 0.700195 13.1179 0.700195 13.4817C0.700195 14.1969 1.28591 14.7764 2.00112 14.7764H24.0054C24.363 14.7764 24.6836 14.6346 24.9179 14.4003C25.1522 14.1661 25.3001 13.8392 25.3001 13.4817C25.3001 12.7603 24.7206 12.1808 24.0054 12.1808H23.3642H2.63615H2.00112C1.64352 12.1808 1.31675 12.3288 1.08244 12.563Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
