/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ContractContractType = (typeof ContractContractType)[keyof typeof ContractContractType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractContractType = {
  progress: 'progress',
  milestone: 'milestone',
  free_form: 'free_form',
} as const
