import { ReactNode, useState } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { TimeoutError } from 'actions/error'
import { SentryClient } from 'lib/sentry'
import { MSError2, F } from 'msutils'
import { MSForm } from 'utils/form'
import { Colorset } from 'compass-local/utils/colorset'
import { ClosableLayout } from 'utils/ClosableLayout'
import {
  collectErrors,
  get400ReportMessage,
  getUnmatchedErrors,
  hasMatchedFieldError,
  isAxios4XX,
  parse4XX,
  StaticValidationError,
} from './utils'

export type Mutator<T> = UseMutationResult<T, Error, void, unknown>
export function Mutation<T>(
  name: ReactNode,
  props: {
    mutator: Mutator<T>
    disabled?: boolean
    disabledMessage?: string
    colors?: Colorset
    onSuccess?: (newValue: T) => void
  },
) {
  return {
    name,
    colors: props.colors,
    onClick: async () => {
      const res = await props.mutator.mutateAsync()
      props.onSuccess?.(res)
      return res
    },
    isLoading: props.mutator.isLoading,
    disabled: props.disabled,
    disabledMessage: props.disabledMessage,
  }
}

export function useFormState<T extends F.Field<any, any, any> & { _field_type: 'group' }>(
  schema: T,
  props?: F.PartialConcreteConfig<T> & {
    initValue?: F.InitValue<T>
  },
) {
  const state = F.useInitSchema(schema, {
    initValue: props?.initValue,
    config: props,
  })
  const [ackableErrors, setAckableErrors] = useState<any>({})

  const useUnvalidatedAction = <R>(
    action: () => Promise<R>,
    handlers?: { onSuccess?: (res: R) => void },
  ): Mutator<R> => {
    const { setInactiveWithoutWarning } = MSForm.useFormContext()
    const closableLayoutCtx = ClosableLayout.useOptionalContext()

    return useMutation({
      mutationFn: async () => {
        const res = await action()
        handlers?.onSuccess?.(res)
        setInactiveWithoutWarning()
        closableLayoutCtx?.setInactiveWithoutWarning()
        return res
      },
      onError: (e: Error) => {
        if (e instanceof TimeoutError) {
          SentryClient.report(e, 'warning')
        } else if (isAxios4XX(e)) {
          const message = get400ReportMessage(e)
          const reportE = new MSError2.Error2(`Error ${e.response?.status}: ${message}`, {
            wrappedError: e,
          })
          SentryClient.report(reportE, 'warning')
        } else {
          SentryClient.report(e)
        }
      },
    })
  }

  const useValidatedAction = <R>(
    action: (value: NonNullable<ReturnType<T['validate']>>) => Promise<R>,
    settings?: {
      onSuccess?: (newValue: R) => void
      disableSetInactiveOnSuccess?: boolean
      disableErrorPropagation?: boolean
      disableLog4XX?: boolean
    },
  ): Mutator<R> => {
    const formCtx = MSForm.useOptionalFormContext()
    const closableLayoutCtx = ClosableLayout.useOptionalContext()
    const trigger = async () => {
      if (state._controller.validation.isValid) {
        try {
          const res = await action(state._controller.validation.validValue)
          settings?.onSuccess?.(res)
          if (!settings?.disableSetInactiveOnSuccess) {
            if (closableLayoutCtx) {
              closableLayoutCtx.setInactiveWithoutWarning()
            } else {
              formCtx?.setInactiveWithoutWarning()
            }
          }
          return res
        } catch (e: any) {
          if (!settings?.disableErrorPropagation) {
            state._controller.tap()
          }
          if (settings?.disableErrorPropagation) {
            throw new Error('')
          } else if (e instanceof TimeoutError) {
            SentryClient.report(e, 'warning')
          } else if (isAxios4XX(e)) {
            const message = get400ReportMessage(e)
            const reportE = new MSError2.Error2(`Error ${e.response?.status}: ${message}`, {
              name: 'Server-side validation error (6)',
              wrappedError: e,
            })
            if (!settings?.disableLog4XX) {
              SentryClient.report(reportE, 'warning')
            }
            if (hasMatchedFieldError(schema, e)) {
              e.message = 'Invalid input'
            } else {
              e.message = getUnmatchedErrors(schema, e)?.at(0) ?? 'Invalid input'
            }
            const parsedErrors = parse4XX(schema, e)
            setAckableErrors(parsedErrors)
          } else if (e instanceof StaticValidationError) {
            const reportE = new MSError2.Error2(e.collectedErrors)
            reportE.name = 'Static validation error'
            SentryClient.report(reportE, 'warning')
          } else {
            SentryClient.report(e)
          }
          throw new MSError2.AlreadyHandled(e.message)
        }
      } else {
        if (!settings?.disableErrorPropagation) {
          state._controller.tap()
        }
        throw new StaticValidationError(
          state._controller.validation.error ?? '',
          collectErrors(schema, state),
        )
      }
    }
    return useMutation({
      mutationFn: trigger,
      // Note: this error already gets sent up to the browser for window-level error handling (which causes double-reporting for anything reported here)
      // eslint-disable-next-line
      onError: (_: Error) => {},
    })
  }

  const stateWithErrors: F.Input<T> = F.attachErrors(schema, state, ackableErrors)
  return { state: stateWithErrors, useValidatedAction, useUnvalidatedAction }
}
