/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminQBPullRequestSkipReason =
  (typeof AdminQBPullRequestSkipReason)[keyof typeof AdminQBPullRequestSkipReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminQBPullRequestSkipReason = {
  unknown: 'unknown',
  incorrect_type: 'incorrect_type',
  blocked_source_id: 'blocked_source_id',
  do_not_pull: 'do_not_pull',
  inbound_sync_filter: 'inbound_sync_filter',
  no_difference: 'no_difference',
  client_side_filter: 'client_side_filter',
  missing_project: 'missing_project',
  missing_parent_object: 'missing_parent_object',
  null: null,
} as const
