import { ReactNode } from 'react'
import Link from 'next/link'

type Props = Partial<{
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  href: string
  tabIndex: number
  className: string
  children: ReactNode
}>

export default function Base({ onClick, href, tabIndex, className, children }: Props) {
  if (href) {
    return (
      <Link href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
        {children}
      </Link>
    )
  }

  return (
    <div className={className} onClick={onClick} tabIndex={tabIndex}>
      {children}
    </div>
  )
}
