/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillTypeFreeFormPaymentState =
  (typeof BillTypeFreeFormPaymentState)[keyof typeof BillTypeFreeFormPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTypeFreeFormPaymentState = {
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
} as const
