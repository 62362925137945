/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceIntentPrefillStatus =
  (typeof InvoiceIntentPrefillStatus)[keyof typeof InvoiceIntentPrefillStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceIntentPrefillStatus = {
  new: 'new',
  pending: 'pending',
  complete: 'complete',
} as const
