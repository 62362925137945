/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GCSubcontractInlineListRowSubcontractorComplianceStatus =
  (typeof GCSubcontractInlineListRowSubcontractorComplianceStatus)[keyof typeof GCSubcontractInlineListRowSubcontractorComplianceStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GCSubcontractInlineListRowSubcontractorComplianceStatus = {
  compliant: 'compliant',
  not_compliant: 'not_compliant',
  not_required: 'not_required',
} as const
