/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeYieldPaymentState =
  (typeof BeamBalanceTransactionTypeYieldPaymentState)[keyof typeof BeamBalanceTransactionTypeYieldPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeYieldPaymentState = {
  new: 'new',
  pending: 'pending',
  complete: 'complete',
  failed: 'failed',
} as const
