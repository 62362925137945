import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { Cb, Q } from 'cb'
import useUrlParam from 'utils/useUrlParam'
import { DateFilterSpec } from 'compass-local/FilterInput/utils'
import { useBusinessContext } from 'root/user'

export const moduleId = 'bills'

export function useUrlParams() {
  const t = useUrlParam('t', z.enum(['all', 'unpaid', 'scheduled', 'paid']).default('all'))
  const q = useUrlParam('q', z.string().default(''))
  const s = useUrlParam(
    's',
    z
      .enum([
        'due_date',
        '-due_date',
        'payee',
        '-payee',
        'payment_amount',
        '-payment_amount',
        'project_name',
        '-project_name',
      ])
      .nullable()
      .default(null),
  )
  const f = useUrlParam(
    'f',
    z
      .object({
        status: z
          .enum([
            'overdue',
            'paid',
            'partially_paid',
            'pending',
            'processing',
            'ready_for_payment',
            'rejected',
            'scheduled',
            'void',
          ])
          .array()
          .default([]),
        dueDate: DateFilterSpec,
      })
      .default({}),
  )
  const p = useUrlParam('p', z.number().default(1))

  return useMemo(
    () => ({ tabState: t, searchState: q, sortState: s, filterState: f, pageState: p }),
    [t, q, s, f, p],
  )
}

function useListInvoiceIntentsUntilNoneAreLoading() {
  const [noneAreLoading, setNoneAreLoading] = useState(false)
  const intents = Cb.useListInvoiceIntents({
    params: { type: 'bill' },
    refetchInterval: noneAreLoading ? undefined : 2000,
    select: Q.filter((x) => x.invoice_id === null),
  })

  useEffect(() => {
    if (intents.data) setNoneAreLoading(!intents.data.some((x) => x.prefill_status !== 'complete'))
  }, [noneAreLoading, intents.data])

  return intents
}

export function useQueries() {
  const { business } = useBusinessContext()

  return Q.group({
    approvalRules: Cb.useListInvoiceApprovalRules(),
    billIntents: useListInvoiceIntentsUntilNoneAreLoading(),
    billsSummary: Cb.useRetrieveBillsPageSummary(business.id),
  })
}
