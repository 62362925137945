import { Cell, CellField } from './base'
import { Branded, EmptyString, FieldError } from './utils'

type AccountNumberString = Branded<string, 'account-number'>
type AccountNumberField<V> = CellField<string, V>

type AccountNumberProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validateAccountNumber(s: string) {
  if (Number.isNaN(s)) {
    throw new Error('Account number must be numeric')
  }
  if (s.length < 5 || s.length > 17) {
    throw new Error('Account number must be between 5 and 17 characters')
  }
  return s
}

export function AccountNumber<TReq extends boolean = false>(
  props?: AccountNumberProps<TReq>,
): AccountNumberField<TReq extends true ? AccountNumberString : AccountNumberString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError('Required')
      } else {
        return validateAccountNumber(x)
      }
    } else if (!x) {
      return ''
    } else {
      return validateAccountNumber(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? '',
    validate,
    errorKey: props?.errorKey,
  })
}
