import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Calculator({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33333 5.05556H10.6667M10.6667 13.9444V11.2778M7.99999 13.9444H8.00888M5.33333 13.9444H5.34222M5.33333 11.2778H5.34222M7.99999 11.2778H8.00888M10.6667 8.61111H10.6755M7.99999 8.61111H8.00888M5.33333 8.61111H5.34222M3.55555 17.5H12.4444C13.4263 17.5 14.2222 16.7041 14.2222 15.7222V3.27778C14.2222 2.29594 13.4263 1.5 12.4444 1.5H3.55555C2.57371 1.5 1.77777 2.29594 1.77777 3.27778V15.7222C1.77777 16.7041 2.57371 17.5 3.55555 17.5Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
