import { createContext, ReactNode, Ref, useContext, useMemo, useState } from 'react'
import useScreenSize from 'compass/theme/useScreenSize'

export type FormSize = 'small' | 'medium' | 'large'
export type FormUI = 'modal' | 'drawer'

export type FormConfig = {
  size: FormSize
  warnOnClose: boolean
}

export type TContext = {
  handle: Ref<Partial<FormConfig>>
  ui: FormUI
  warnOrSetInactive: () => void
  setInactiveWithoutWarning: () => void
  splitRatio: number
  setSplitRatio: (newValue: number) => void
}

const Context = createContext<TContext | undefined>(undefined)
export function useFormContext() {
  const ctx = useContext(Context)
  if (ctx === undefined) throw new Error('FormContext must be used within a FormProvider')
  return ctx
}

export function useOptionalFormContext() {
  return useContext(Context)
}

type ProviderProps = Omit<TContext, 'splitRatio' | 'setSplitRatio'> & { children: ReactNode }

export function FormProvider(props: ProviderProps) {
  const sz = useScreenSize()
  const [splitRatio, setSplitRatio] = useState(sz === 'md' ? 0.97 : 0.6)
  const ctx = useMemo(
    () => ({ ...props, splitRatio, setSplitRatio }),
    [props, splitRatio, setSplitRatio],
  )
  return <Context.Provider value={ctx}>{props.children}</Context.Provider>
}
