/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExpensePaymentType =
  (typeof BeamBalanceTransactionTypeExpensePaymentType)[keyof typeof BeamBalanceTransactionTypeExpensePaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExpensePaymentType = {
  expense_payment: 'expense_payment',
} as const
