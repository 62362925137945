import IconBase from 'compass-local/legacy/IconBase'
import { StrokedIconProps } from '../types'

export default function Filter({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.26035 1.26035C1.09365 1.42705 1 1.65314 1 1.88889V4.18756C1.00005 4.42328 1.09373 4.64934 1.26044 4.816L6.96178 10.5173C7.12849 10.684 7.22217 10.91 7.22222 11.1458V17L10.7778 13.4444V11.1458C10.7778 10.91 10.8715 10.684 11.0382 10.5173L16.7396 4.816C16.9063 4.64934 16.9999 4.42328 17 4.18756V1.88889C17 1.65314 16.9064 1.42705 16.7397 1.26035C16.573 1.09365 16.3469 1 16.1111 1H1.88889C1.65314 1 1.42705 1.09365 1.26035 1.26035Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
