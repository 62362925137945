import { MSObject } from '../object'
import {
  AnyFactory,
  AnyInputState,
  BaseInputStateController,
  Factory,
  InputState,
  InputStateAndController,
  InputStateFromFactory,
} from './types'
import { TWritablesForGroup, TStorablesForGroup, TReadablesForGroup } from './factories/group'
import * as Interaction from './interaction'

export function collapseLayer<TWritable, TStorable, TReadable, TValid, C extends object>(
  inputStateAndController: InputStateAndController<TWritable, TStorable, TReadable, TValid, C>,
): InputState<TWritable, TStorable, TReadable, TValid, C> {
  return { ...inputStateAndController.controller, ...inputStateAndController.state }
}

export function getFields<
  TWritable,
  TStorable,
  TReadable,
  TValid,
  C extends BaseInputStateController<TWritable, any>,
>(x: InputState<TWritable, TStorable, TReadable, TValid, C>) {
  return {
    value: x.value,
    update: x.update,
    focus: x.focus,
    blur: x.blur,
    error: Interaction.shouldShowError(x.status) ? x.error : null,
  }
}

type AppendingToObject<Obj extends object, Suffix extends string> = {
  [K in keyof Obj as K extends string ? `${K}${Suffix}` : never]: Obj[K]
}

export function unpack<F extends { [key: string]: AnyFactory }, FValid>(
  x: InputStateFromFactory<
    Factory<
      Partial<TWritablesForGroup<F>>,
      TStorablesForGroup<F>,
      TReadablesForGroup<F>,
      FValid,
      {}
    >
  >,
): AppendingToObject<TReadablesForGroup<F>, 'Input'> {
  return Object.fromEntries(Object.entries(x.value).map(([k, v]) => [`${k}Input`, v])) as any
}

export function allValid<T extends AnyInputState[]>(...states: T) {
  return states.every((x) => x.isValid)
}

export function tapAll<T extends AnyInputState[]>(...states: T) {
  return states.forEach((x) => x.tap())
}

export function checkpointAll<T extends AnyInputState>(state: T): void {
  if (state.__type === 'group') {
    MSObject.map(state.value, checkpointAll)
  } else if (state.__type === 'list') {
    state.value.forEach(checkpointAll)
  } else {
    state.set({ ...state, initialValue: state.value, status: 'clean' })
  }
}

export function collectErrors<T extends AnyInputState>(state: T): string {
  try {
    if (state.__type === 'group') {
      return JSON.stringify(MSObject.map(state.value, (x: any) => x.error))
    } else if (state.__type === 'list') {
      return JSON.stringify(state.value.map((x: any) => x.error))
    } else {
      return state.error ?? ''
    }
  } catch (e: any) {
    return `Error while collecting errors (${e.message})`
  }
}
