import { ReactNode } from 'react'
import { Cb } from 'cb'
import { useAnalyticsContext } from 'root/global'
import { useEffectOnce } from 'utils/useEffectOnce'
import { useAppContext } from './AppContext'

type RestrictedToBusinessRolesProps = {
  roles: Cb.EmployeeRole[]
  children: ReactNode
}

export function RestrictedToBusinessRoles({ roles, children }: RestrictedToBusinessRolesProps) {
  const context = useAppContext()

  return context.type === 'business' && roles.includes(context.employee.role) ? (
    <>{children}</>
  ) : null
}

type RestrictedToGuestProps = {
  children: ReactNode
}

export function RestrictedToGuest({ children }: RestrictedToGuestProps) {
  const context = useAppContext()

  return context.type === 'client' ? <>{children}</> : null
}

export function UserIdentifier() {
  const { identify } = useAnalyticsContext()
  const ctx = useAppContext()
  const { user, authType } = ctx
  const business = ctx.type === 'business' ? ctx.business : null

  const getHubspotToken = Cb.useGetHubspotVisitorTokenHook()

  useEffectOnce(() => {
    getHubspotToken().then((data) => {
      window.hsConversationsSettings = {
        ...(user.email && { identificationEmail: user.email }),
        ...(data.token && { identificationToken: data.token }),
      }
    })

    identify({
      id: user.id,
      authType,
      name: user.full_name,
      email: user.email,
      phoneNumber: user.phone_number,
      business,
    })
  })

  return <></>
}
