import { cn } from 'msutils/classnames'
import { BackgroundColors } from 'compass-local/utils/colors'

type Props = {
  height?: `h-${string}`
  color?: keyof typeof BackgroundColors
}

export default function Divider({ height = 'h-[1px]', color = 'warm' }: Props) {
  return <div className={cn('shrink-0', height, BackgroundColors[color])} />
}
