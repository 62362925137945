import { Trans, t } from 'content'
import Typography from 'compass/data/Typography'
import Card from 'compass/layout/Card'
import Spinner from 'compass-local/Spinner'
import Divider from 'compass-local/Divider'
import { Bank } from 'compass-local/legacy/icons'
import { useBusinessContext } from 'root/user'
import MicrodepositVerificationView from 'components/misc/MicrodepositVerificationView'
import { ErrorPage } from 'utils/error'
import { Cb, Q } from 'cb'
import withInjectedQueries, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'
import Link2 from 'compass-local/Link2'
import { Module } from 'modules/routes'
import { MSError } from 'msutils/error'
import { Action } from 'utils/actions'
import { MSLayout } from 'utils/layout'
import SupportLink from '../SupportLink'
import useLinkOwnerBank from './useLinkOwnerBank'
import useConfirmBankSetup from './useConfirmBankSetup'

function useQueries() {
  return Q.group({
    onboardingFlow: Cb.useRetrieveSingletonOnboardingFlow({ refetchInterval: 5 * 1000 }),
    microdepositRequirement: Cb.useListMicrodepositRequirements({ select: Q.opt }),
    businessProfileOpt: Cb.useListBusinessProfiles({ select: Q.opt }),
    employeeProfileOpt: Cb.useListEmployeeProfiles({ select: Q.opt }),
  })
}

type Props = {
  onSuccess?: () => void
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
}

function LinkOwnerBankForm({ _queryset, onSuccess }: Props) {
  const { microdepositRequirement, onboardingFlow, businessProfileOpt, employeeProfileOpt } =
    _queryset

  const { user, business } = useBusinessContext()

  const linkOwnerBank = useLinkOwnerBank({ businessId: business.id })
  const confirmBankSetup = useConfirmBankSetup()

  if (!onboardingFlow.bank_linking_available) {
    return (
      <div className="vflex gap-2 max-w-[400px]">
        {businessProfileOpt && !employeeProfileOpt ? (
          <>
            <Typography variant="subtitle">{t('Bank linking is unavailable')}</Typography>
            <Typography className="text-th-coolgrey-1">
              <Trans
                tKey="Please set up your <X1>employee profile</X1> and your <X2>business profile</X2> before linking your bank."
                X1={<Link2 href={Module('/settings/profile').href} />}
                X2={<Link2 href={Module('/settings/profile').href} />}
              />
            </Typography>
          </>
        ) : (
          <>
            <div className="flex gap-2">
              <Typography variant="subtitle">{t('Business verification pending')}</Typography>
              <Spinner className="text-th-coolgrey-1" w="w-5" h="h-5" thickness={140} />
            </div>

            <Typography className="text-th-coolgrey-1">
              <SupportLink tKey="Your business and employee information is being verified and may take a few more minutes. If you're still seeing this after five minutes, please <X>contact support</X>." />
            </Typography>
          </>
        )}
      </div>
    )
  } else if (microdepositRequirement?.succeeded === false) {
    return <MicrodepositVerificationView requirement={microdepositRequirement} />
  } else if (linkOwnerBank.status === 'success' && linkOwnerBank.data !== null) {
    const bankLinkingData = linkOwnerBank.data

    return (
      <Card variant="cool" className="vflex gap-4 max-w-[400px]">
        <Typography variant="title">{t('Stripe ACH Direct Debit notice')}</Typography>
        <Divider />
        <div className="vflex gap-2">
          <Typography variant="bodybold">{t('Name: {{ X }}', { X: user.full_name })}</Typography>
          <Typography variant="bodybold">
            {t('Account number: XXXX{{ X }}', { X: linkOwnerBank.data.account.accountLastFour })}
          </Typography>
          <Typography variant="bodybold">
            {t('Routing number: {{ X }}', { X: linkOwnerBank.data.account.routingNumber })}
          </Typography>
          <Typography>
            <Trans
              tKey="By clicking <X>Accept</X>, you authorize Beam to debit the bank account specified above for any amount owed for charges arising from your use of Beam's services and/or purchase of products from Beam, pursuant to Beam's website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Beam with 30 (thirty) days notice."
              X={<Typography variant="bodybold" />}
            />
          </Typography>
        </div>
        <Divider />
        <div className="flex gap-2 items-center justify-end">
          <div className="w-min">
            <Action.Mount
              {...Action.button(t('Cancel'), {
                theme: 'text',
                onClick: linkOwnerBank.reset,
                qualify: () => (!confirmBankSetup.isLoading ? '' : true),
              })}
            />
          </div>
          <div className="w-min">
            <Action.Mount
              {...Action.mutation(t('Accept'), {
                // eslint-disable-next-line
                mutate: () =>
                  new Promise<void>((res) => {
                    confirmBankSetup.trigger(bankLinkingData, { onSuccess: () => res() })
                  }),
              })}
            />
          </div>
        </div>
      </Card>
    )
  } else if (
    linkOwnerBank.status === 'success' ||
    linkOwnerBank.status === 'idle' ||
    linkOwnerBank.status === 'loading'
  ) {
    return (
      <MSLayout.EmptyState
        icon={<Bank height={48} className="text-th-coolgrey-1" />}
        title={t('Securely link a bank account')}
        subtitle={t('Add your bank account information to send or receive payments.')}
        actions2={[
          Action.button(t('Link an account'), {
            icon: 'link',
            onClick: () => linkOwnerBank.trigger(undefined, { onSuccess }),
            isLoading: linkOwnerBank.status === 'loading',
          }),
        ]}
      />
    )
  } else if (linkOwnerBank.status === 'error' || confirmBankSetup.status === 'error') {
    return <ErrorPage error={new MSError({ type: 'link-owner-bank' })} />
  } else {
    // TODO: presumably the client of this form should stop showing the form at this point
    return null
  }
}

export default withInjectedQueries(useQueries, LinkOwnerBankForm)
