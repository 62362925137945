import { KeyboardEvent, useState } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import { X, MagnifyingGlass } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import { Setter } from 'utils/types/Setter'
import { t } from 'content'

type Props = {
  value: string
  update: Setter<string>
  disabled?: boolean
  autofocus?: boolean
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

export default function SearchInput({ value, update, autofocus, disabled, onKeyDown }: Props) {
  const sz = useScreenSize()

  const [isFocused, setIsFocused] = useState(false)
  const isActive = value || isFocused

  return (
    <>
      <Typography className={cn('transition-all relative', (isActive || sz === 'sm') && 'grow')}>
        <input
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => update(e.target.value)}
          onKeyDown={onKeyDown}
          disabled={disabled}
          autoFocus={autofocus}
          className={cn(
            'min-w-[100px] px-9 py-3 rounded-full h-full w-full',
            isActive ? 'focus:border-th-warmgrey-dark border-2 pr-10' : 'border mx-[1px]',
            disabled
              ? 'cursor-not-allowed bg-th-bg-slate hover:bg-th-bg-slate active:bg-th-bg-slate text-th-text-disabled placeholder-th-text-disabled'
              : 'border-th-warmgrey-1 placeholder-th-text-hint',
          )}
          placeholder={t('Search...')}
        />
        {isActive && (
          <div
            className="absolute inset-y-0 right-0 w-10 px-6 cursor-pointer text-th-warmgrey-dark flex items-center justify-center"
            onClick={() => update('')}
          >
            <X height={12} thickness={2.2} />
          </div>
        )}
        <div className="absolute inset-y-0 left-0 w-10 px-6 text-th-warmgrey-dark flex items-center justify-center">
          <MagnifyingGlass height={14} thickness={2.2} />
        </div>
      </Typography>
      <div className={cn('transition-all', isActive ? 'shrink' : 'grow')} />
    </>
  )
}
