/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CategorizeTransactionOutputCategory =
  (typeof CategorizeTransactionOutputCategory)[keyof typeof CategorizeTransactionOutputCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategorizeTransactionOutputCategory = {
  credit_card_bill_payment: 'credit_card_bill_payment',
  transfer: 'transfer',
  invoice_payment: 'invoice_payment',
  expense_payment: 'expense_payment',
} as const
