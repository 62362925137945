/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BusinessProfileStructure =
  (typeof BusinessProfileStructure)[keyof typeof BusinessProfileStructure]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessProfileStructure = {
  multi_member_llc: 'multi_member_llc',
  private_corporation: 'private_corporation',
  private_partnership: 'private_partnership',
  public_corporation: 'public_corporation',
  public_partnership: 'public_partnership',
  single_member_llc: 'single_member_llc',
  sole_proprietorship: 'sole_proprietorship',
  unincorporated_association: 'unincorporated_association',
} as const
