import { ReactNode } from 'react'

export type Option<T> = {
  value: T
  disabled?: boolean
}

export type SelectRef = {
  focus: () => void
  blur: () => void
  scrollTo: () => void
}

export const propsOverride = {
  width: '100%',
  padding: 0,
}

export type OptionProp<TValue> = {
  title?: ReactNode
  value: TValue
  badge?: ReactNode
  label?: ReactNode
  metric?: { k: string; v: ReactNode }
  disabled?: boolean
}
